import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

const colors = {
  bgColor: '#1a1a2e',
  textColor: '#ffffff',
  primaryColor: '#0f3460',
  secondaryColor: '#16213e',
  accentColor: '#e94560',
};

const breakpoints = {
  small: '576px',
  medium: '768px',
  large: '992px',
};

export const globalStyles = css`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }

  body {
    font-family: 'Poppins', sans-serif;
    background-color: ${colors.bgColor};
    color: ${colors.textColor};
    margin: 0;
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  #root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const Container = styled.div`
  background-color: ${colors.secondaryColor};
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 90%;
  max-width: 400px;
  margin: 2rem auto;

  @media (min-width: ${breakpoints.medium}) {
    padding: 2rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.medium}) {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }
`;

export const SubTitle = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 1rem;

  @media (min-width: ${breakpoints.medium}) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  margin-bottom: 1rem;
`;

export const StyledLink = styled.a`
  color: ${colors.textColor};
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.accentColor};
  }
`;

export const Button = styled.button`
  background-color: ${colors.primaryColor};
  color: ${colors.textColor};
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;

  &:hover {
    background-color: ${colors.accentColor};
  }

  @media (min-width: ${breakpoints.medium}) {
    width: auto;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid ${colors.primaryColor};
  border-radius: 5px;
  background-color: ${colors.bgColor};
  color: ${colors.textColor};
  font-size: 1rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.p`
  color: ${colors.accentColor};
  margin-bottom: 1rem;
  font-size: 0.875rem;

  @media (min-width: ${breakpoints.medium}) {
    font-size: 1rem;
  }
`;

export const Text = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.medium}) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const theme = {
  bgColor: '#1a1a2e',
  textColor: '#ffffff',
  primaryColor: '#0f3460',
  secondaryColor: '#16213e',
  accentColor: '#e94560',
};

//Smaller Screens

export const SlideContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
`;

export const SlideContent = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: ${(props) => props.theme.secondaryColor};
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

export const SlideTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${(props) => props.theme.textColor};
  text-align: center;
`;

export const NavigationBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
`;

const ChartSection = styled.div`
  margin-bottom: 40px;
`;

const ChartTitle = styled.h3`
  color: #444;
  text-align: center;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 700px;
  height: auto;
  min-height: 440px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

  @media (max-width: 768px) {
    min-height: 450px;
  }

  @media (max-width: 480px) {
    min-height: 350px;
  }
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 360px;

  @media (max-width: 768px) {
    min-height: 450px;
  }

  @media (max-width: 480px) {
    min-height: 450px;
  }
`;

export { ChartSection, ChartTitle, ChartContainer, ChartWrapper };

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StatCard = styled.div`
  background-color: ${theme.secondaryColor};
  color: ${theme.textColor};
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StatTitle = styled.h4`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${theme.accentColor};
`;

const StatValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

export { StatGrid, StatCard, StatTitle, StatValue };

export { breakpoints };
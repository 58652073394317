import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";

const useFetchAllUsersData = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, "users");
        const userSnapshot = await getDocs(usersRef);
        const usersData = await Promise.all(
          userSnapshot.docs.map(async (userDoc) => {
            const userData = userDoc.data();
            const roundsRef = collection(db, "rounds");
            const roundsQuery = query(
              roundsRef,
              where("userId", "==", userDoc.id)
            );
            const roundsSnapshot = await getDocs(roundsQuery);
            const rounds = roundsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            return {
              id: userDoc.id,
              ...userData,
              rounds,
            };
          })
        );

        setUsers(usersData);
      } catch (err) {
        console.error("Error fetching users data:", err);
        setError(err.message || "An error occurred while fetching user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading, error };
};

export default useFetchAllUsersData;

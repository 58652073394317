import React from "react";
import styled from "@emotion/styled";
import { theme, breakpoints } from "../styles"; // Adjust the import path as needed

const ChartContainer = styled.div`
  background-color: ${theme.secondaryColor};
  color: ${theme.textColor};
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 700px;
  margin: 2rem auto;
  overflow-x: auto;

  @media (min-width: ${breakpoints.medium}) {
    padding: 2rem;
  }
`;

const Title = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-align: center;

  @media (min-width: ${breakpoints.medium}) {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
`;

const Th = styled.th`
  background-color: ${theme.primaryColor};
  color: ${theme.textColor};
  padding: 0.8rem;
  text-align: left;
  font-weight: 600;
  border-top: 1px solid ${theme.accentColor};
  border-bottom: 1px solid ${theme.accentColor};

  &:first-of-type {
    border-top-left-radius: 5px;
    border-left: 1px solid ${theme.accentColor};
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-right: 1px solid ${theme.accentColor};
  }
`;

const Td = styled.td`
  padding: 0.8rem;
  border-bottom: 1px solid ${theme.primaryColor};

  &:first-of-type {
    border-left: 1px solid ${theme.primaryColor};
  }

  &:last-of-type {
    border-right: 1px solid ${theme.primaryColor};
  }
`;

const Tr = styled.tr`
  &:last-of-type td {
    border-bottom: none;

    &:first-of-type {
      border-bottom-left-radius: 5px;
    }

    &:last-of-type {
      border-bottom-right-radius: 5px;
    }
  }

  &:nth-of-type(even) {
    background-color: ${theme.bgColor};
  }
`;

const MobileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MobileCard = styled.div`
  background-color: ${theme.bgColor};
  border-radius: 5px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const MobileCardItem = styled.p`
  margin: 0.5rem 0;
  display: flex;
  justify-content: space-between;

  strong {
    color: ${theme.accentColor};
  }
`;

const DesktopView = styled.div`
  display: none;

  @media (min-width: ${breakpoints.medium}) {
    display: block;
  }
`;

const MobileView = styled.div`
  display: block;

  @media (min-width: ${breakpoints.medium}) {
    display: none;
  }
`;

const OptimalScoringCombinations = ({ combinations }) => {
  if (!combinations || combinations.length === 0) {
    return (
      <ChartContainer>
        No optimal scoring combinations data available.
      </ChartContainer>
    );
  }

  const formatDistance = (min, max, avg) => {
    if (min === max) {
      return `${avg.toFixed(0)} yards`;
    } else {
      return `${avg.toFixed(0)} yards (${min}-${max} range)`;
    }
  };

  const MobileViewContent = () => (
    <MobileCardContainer>
      {combinations.map((combo, index) => (
        <MobileCard key={index}>
          <MobileCardItem>
            <strong>Rank:</strong> {index + 1}
          </MobileCardItem>
          <MobileCardItem>
            <strong>Tee Shot:</strong>{" "}
            {`${combo.teeClub} (${formatDistance(
              combo.teeDistanceMin,
              combo.teeDistanceMax,
              combo.averageTeeDistance
            )})`}
          </MobileCardItem>
          <MobileCardItem>
            <strong>Approach Shot:</strong>{" "}
            {`${combo.approachClub} (${formatDistance(
              combo.approachDistanceMin,
              combo.approachDistanceMax,
              combo.averageApproachDistance
            )})`}
          </MobileCardItem>
          <MobileCardItem>
            <strong>Avg. Score to Par:</strong>{" "}
            {combo.averageScoreToPar.toFixed(2)}
          </MobileCardItem>
          <MobileCardItem>
            <strong>Frequency:</strong> {combo.count}
          </MobileCardItem>
        </MobileCard>
      ))}
    </MobileCardContainer>
  );

  const DesktopViewContent = () => (
    <Table>
      <thead>
        <tr>
          <Th>Rank</Th>
          <Th>Tee Shot</Th>
          <Th>Approach Shot</Th>
          <Th>Avg. Score to Par</Th>
          <Th>Frequency</Th>
        </tr>
      </thead>
      <tbody>
        {combinations.map((combo, index) => (
          <Tr key={index}>
            <Td>{index + 1}</Td>
            <Td>{`${combo.teeClub} (${formatDistance(
              combo.teeDistanceMin,
              combo.teeDistanceMax,
              combo.averageTeeDistance
            )})`}</Td>
            <Td>{`${combo.approachClub} (${formatDistance(
              combo.approachDistanceMin,
              combo.approachDistanceMax,
              combo.averageApproachDistance
            )})`}</Td>
            <Td>{combo.averageScoreToPar.toFixed(2)}</Td>
            <Td>{combo.count}</Td>
          </Tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <ChartContainer>
      <Title>Optimal Scoring Combinations</Title>
      <DesktopView>
        <DesktopViewContent />
      </DesktopView>
      <MobileView>
        <MobileViewContent />
      </MobileView>
    </ChartContainer>
  );
};

export default OptimalScoringCombinations;

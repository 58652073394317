import React, { useState, useMemo } from "react";
import RadialHeatmap from "../components/RadialHeatmap";
import styled from "@emotion/styled";
import { theme, breakpoints } from "../styles";
import StrokesGainedUtil from "../utils/StrokesGainedUtil";
import { Bar, Scatter, Pie, Radar, Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend
);

const StatsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StatsHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;

const ChartSection = styled.div`
  margin-bottom: 30px;
`;

const ChartTitle = styled.h3`
  color: #444;
  text-align: center;
  margin-bottom: 15px;
`;

const ChartContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
`;

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
`;

const StyledStatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StyledStatCard = styled.div`
  background-color: ${theme.secondaryColor};
  color: ${theme.textColor};
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StyledStatTitle = styled.h4`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${theme.accentColor};
`;

const StyledStatValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const ClubSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  justify-content: center;
`;

const ClubIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor};
  color: ${(props) => props.theme.textColor};
  border: 2px solid
    ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
    border-color: ${(props) => props.theme.accentColor};
  }
`;

const availableClubs = [
  "Dr",
  "2w",
  "3w",
  "4w",
  "5w",
  "7w",
  "2h",
  "3h",
  "4h",
  "5h",
  "6h",
  "1i",
  "2i",
  "3i",
  "4i",
  "5i",
  "6i",
  "7i",
  "8i",
  "9i",
  "Pw",
  "Gw",
  "Sw",
  "Lw",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "Pt",
];

// Club Performance Chart
const calculateClubPerformance = (rounds) => {
  if (!rounds || rounds.length === 0) return {};

  const clubStats = {};

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      holeData.shots.forEach((shot) => {
        if (shot.category === "Approach") {
          if (!clubStats[shot.club]) {
            clubStats[shot.club] = {
              totalProximity: 0,
              count: 0,
              greenHits: 0,
              misses: 0,
            };
          }
          clubStats[shot.club].count++;
          if (shot.result === "Green") {
            clubStats[shot.club].totalProximity +=
              parseFloat(shot.proximityToHole) || 0;
            clubStats[shot.club].greenHits++;
          } else {
            clubStats[shot.club].misses++;
          }
        }
      });
    });
  });

  Object.keys(clubStats).forEach((club) => {
    clubStats[club].avgProximity =
      clubStats[club].greenHits > 0
        ? clubStats[club].totalProximity / clubStats[club].greenHits
        : null;
    clubStats[club].missPercentage =
      (clubStats[club].misses / clubStats[club].count) * 100;
  });

  return clubStats;
};

const sortClubsByPerformance = (clubData, metric, availableClubs) => {
  return Object.entries(clubData)
    .sort((a, b) => b[1][metric] - a[1][metric])
    .sort((a, b) => {
      const indexA = availableClubs.indexOf(a[0]);
      const indexB = availableClubs.indexOf(b[0]);
      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      return indexA - indexB;
    })
    .map(([club, data]) => ({ club, ...data }));
};

const ApproachStats = ({ rounds }) => {
  const [selectedClub, setSelectedClub] = useState("All");
  const approachPerformance = useMemo(
    () => StrokesGainedUtil.calculateApproachStrokesGained(rounds),
    [rounds]
  );

  const isOverallView = rounds.length > 1;

  const clubPerformance = useMemo(
    () => calculateClubPerformance(rounds),
    [rounds]
  );
  const strokesGainedByClub = useMemo(
    () => StrokesGainedUtil.getStrokesGainedByClub(rounds, "approach"),
    [rounds]
  );

  const gir = useMemo(() => calculateGIR(rounds), [rounds]);
  const approachAccuracy = useMemo(
    () => calculateApproachAccuracy(rounds),
    [rounds]
  );
  const girByDistance = useMemo(() => calculateGIRByDistance(rounds), [rounds]);
  const proximityByDistance = useMemo(
    () => calculateProximityByDistance(rounds),
    [rounds]
  );
  const girByClub = useMemo(() => calculateGIRByClub(rounds), [rounds]);
  const sortedClubPerformance = useMemo(
    () =>
      sortClubsByPerformance(clubPerformance, "avgProximity", availableClubs),
    [clubPerformance]
  );

  const sortedGirByClub = useMemo(
    () => calculateAndSortGIRByClub(rounds),
    [rounds]
  );

  const approachShotData = useMemo(() => {
    const shotCounts = {};
    rounds
      .flatMap((round) =>
        Object.values(round.shots || {}).flatMap((holeData) =>
          holeData.shots.filter(
            (shot) =>
              shot.category === "Approach" &&
              parseFloat(shot.distance) > 50 &&
              (selectedClub === "All" || shot.club === selectedClub)
          )
        )
      )
      .forEach((shot) => {
        const key = `${shot.greenPositionX},${shot.greenPositionY}`;
        shotCounts[key] = (shotCounts[key] || 0) + 1;
      });

    return Object.entries(shotCounts).map(([key, count]) => {
      const [greenPositionX, greenPositionY] = key.split(",").map(Number);
      return { greenPositionX, greenPositionY, count };
    });
  }, [rounds, selectedClub]);

  const clubPerformanceData = useMemo(() => {
    return {
      labels:
        selectedClub === "All"
          ? sortedClubPerformance.map((club) => club.club)
          : [selectedClub],
      datasets: [
        {
          label: "Average Proximity (feet)",
          data:
            selectedClub === "All"
              ? sortedClubPerformance.map((club) => club.avgProximity || 0)
              : [clubPerformance[selectedClub]?.avgProximity || 0],
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        },
        {
          label: "Miss Percentage",
          data:
            selectedClub === "All"
              ? sortedClubPerformance.map((club) => club.missPercentage || 0)
              : [clubPerformance[selectedClub]?.missPercentage || 0],
          backgroundColor: "rgba(255, 99, 132, 0.6)",
        },
      ],
    };
  }, [selectedClub, sortedClubPerformance, clubPerformance]);

  const clubPerformanceOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Club Performance",
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Value",
        },
      },
    },
  };

  // Proximity by Distance Scatter Plot
  const proximityScatterData = {
    datasets: [
      {
        label: "Approach Shots",
        data: proximityByDistance.map((shot) => ({
          x: shot.distance,
          y: shot.proximity,
        })),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const proximityScatterOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Proximity vs. Distance",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Distance (yards)",
        },
      },
      y: {
        title: {
          display: true,
          text: "Proximity to Hole (feet)",
        },
      },
    },
  };

  // Overall GIR Pie Chart
  const girData = {
    labels: ["GIR", "Missed Green"],
    datasets: [
      {
        data: [gir.hit, gir.total - gir.hit],
        backgroundColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)"],
      },
    ],
  };

  const girOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Overall Greens in Regulation (GIR)",
      },
    },
  };

  // GIR by Distance Chart
  const girByDistanceData = {
    labels: girByDistance.map((d) => d.range),
    datasets: [
      {
        label: "GIR Percentage",
        data: girByDistance.map((d) => d.percentage),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const girByDistanceOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "GIR Percentage by Distance",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "GIR Percentage",
        },
      },
    },
  };

  // GIR by Club Chart
  const girByClubData = {
    labels:
      selectedClub === "All"
        ? sortedGirByClub.map((c) => c.club)
        : [selectedClub],
    datasets: [
      {
        label: "GIR Percentage",
        data:
          selectedClub === "All"
            ? sortedGirByClub.map((c) => c.percentage)
            : [girByClub.find((c) => c.club === selectedClub)?.percentage || 0],
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  if (girByClub.length === 0) {
    return <p>No GIR data available for any clubs.</p>;
  }

  const girByClubOptions = {
    responsive: true,
    scales: {
      r: {
        beginAtZero: true,
        max: 100,
        ticks: {
          stepSize: 20,
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: "GIR Percentage by Club",
      },
      legend: {
        display: false,
      },
    },
  };

  const girByClubChart = () => {
    if (sortedGirByClub.length <= 2) {
      // Use a bar chart for 1-2 clubs
      return (
        <Bar
          data={{
            labels: sortedGirByClub.map((c) => c.club),
            datasets: [
              {
                label: "GIR Percentage",
                data: sortedGirByClub.map((c) => c.percentage),
                backgroundColor: "rgba(255, 99, 132, 0.6)",
              },
            ],
          }}
          options={{
            responsive: true,
            plugins: {
              title: {
                display: true,
                text: "GIR Percentage by Club",
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                max: 100,
                title: {
                  display: true,
                  text: "GIR Percentage",
                },
              },
            },
          }}
        />
      );
    } else {
      // Use the radar chart for 3 or more clubs
      return <Radar data={girByClubData} options={girByClubOptions} />;
    }
  };

  return (
    <StatsContainer>
      <StatsHeader>Approach Shot Statistics</StatsHeader>

      <ChartSection>
        <ChartTitle>Club Performance</ChartTitle>
        <ClubSelection>
          <ClubIcon
            selected={selectedClub === "All"}
            onClick={() => setSelectedClub("All")}
          >
            All
          </ClubIcon>
          {sortedClubPerformance.map((clubData) => (
            <ClubIcon
              key={clubData.club}
              selected={selectedClub === clubData.club}
              onClick={() => setSelectedClub(clubData.club)}
            >
              {clubData.club}
            </ClubIcon>
          ))}
        </ClubSelection>
        <ChartContainer>
          <ChartWrapper>
            <Bar data={clubPerformanceData} options={clubPerformanceOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Proximity vs. Distance</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Scatter
              data={proximityScatterData}
              options={proximityScatterOptions}
            />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Approach Shot Heatmap</ChartTitle>
        <ClubSelection>
          <ClubIcon
            selected={selectedClub === "All"}
            onClick={() => setSelectedClub("All")}
          >
            All
          </ClubIcon>
          {sortedClubPerformance.map((clubData) => (
            <ClubIcon
              key={clubData.club}
              selected={selectedClub === clubData.club}
              onClick={() => setSelectedClub(clubData.club)}
            >
              {clubData.club}
            </ClubIcon>
          ))}
        </ClubSelection>
        <ChartContainer>
          <ChartWrapper>
            <RadialHeatmap
              data={approachShotData}
              title={`Approach Shot Distribution (${selectedClub})`}
              xLabel="Green Left-Right"
              yLabel="Green Short-Long"
            />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Overall Greens in Regulation (GIR)</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Pie data={girData} options={girOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>GIR Percentage by Distance</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Bar data={girByDistanceData} options={girByDistanceOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>GIR Percentage by Club</ChartTitle>
        {selectedClub !== "All" && (
          <p style={{ textAlign: "center", marginBottom: "10px" }}>
            Showing GIR percentage for {selectedClub}
          </p>
        )}
        <ChartContainer>
          <ChartWrapper>{girByClubChart()}</ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <StyledStatGrid>
        {selectedClub !== "All" && clubPerformance[selectedClub] && (
          <>
            <StyledStatCard>
              <StyledStatTitle>{selectedClub} Average Proximity</StyledStatTitle>
              <StyledStatValue>
                {clubPerformance[selectedClub].avgProximity !== null
                  ? `${clubPerformance[selectedClub].avgProximity.toFixed(
                    1
                  )} feet`
                  : "- -"}
              </StyledStatValue>
            </StyledStatCard>
            <StyledStatCard>
              <StyledStatTitle>{selectedClub} Green Hit %</StyledStatTitle>
              <StyledStatValue>
                {(
                  (clubPerformance[selectedClub].greenHits /
                    clubPerformance[selectedClub].count) *
                  100
                ).toFixed(1)}
                %
              </StyledStatValue>
            </StyledStatCard>
            <StyledStatCard>
              <StyledStatTitle>{selectedClub} Miss %</StyledStatTitle>
              <StyledStatValue>
                {clubPerformance[selectedClub].missPercentage.toFixed(1)}%
              </StyledStatValue>
            </StyledStatCard>
            <StyledStatCard>
              <StyledStatTitle>{selectedClub} Usage Count</StyledStatTitle>
              <StyledStatValue>{clubPerformance[selectedClub].count}</StyledStatValue>
            </StyledStatCard>
            <StyledStatCard>
              <StyledStatTitle>{selectedClub} Strokes Gained/Lost</StyledStatTitle>
              <StyledStatValue>
                {strokesGainedByClub[selectedClub]
                  ? strokesGainedByClub[selectedClub].avgStrokesGained.toFixed(
                    2
                  )
                  : "N/A"}
                strokes
              </StyledStatValue>
            </StyledStatCard>
          </>
        )}
        <StyledStatCard>
          <StyledStatTitle>
            {isOverallView ? "Overall" : "Round"} GIR Percentage
          </StyledStatTitle>
          <StyledStatValue>{((gir.hit / gir.total) * 100).toFixed(1)}%</StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>
            {isOverallView ? "Overall" : "Round"} Approach Shot Accuracy
          </StyledStatTitle>
          <StyledStatValue>
            {(
              (approachAccuracy.accurate / approachAccuracy.total) *
              100
            ).toFixed(1)}
            %
          </StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>
            {isOverallView ? "Overall" : "Round"} Scoring Impact
          </StyledStatTitle>
          <StyledStatValue>
            {approachPerformance.averageStrokesGained.toFixed(2)} strokes
            gained/lost
          </StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>Best GIR Distance Range</StyledStatTitle>
          <StyledStatValue>{getBestGIRDistanceRange(girByDistance)}</StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>Best GIR Club</StyledStatTitle>
          <StyledStatValue>{getBestGIRClub(sortedGirByClub)}</StyledStatValue>
        </StyledStatCard>
      </StyledStatGrid>
    </StatsContainer>
  );
};

// Helper functions

const calculateProximityByDistance = (rounds) => {
  const shots = [];

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      holeData.shots.forEach((shot) => {
        if (shot.category === "Approach" && shot.result === "Green") {
          shots.push({
            distance: parseFloat(shot.distance),
            proximity: parseFloat(shot.proximityToHole) || 0,
          });
        }
      });
    });
  });

  return shots;
};

const calculateGIR = (rounds) => {
  let hit = 0;
  let total = 0;

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      const approachShot = holeData.shots.find(
        (shot) => shot.category === "Approach"
      );
      if (approachShot) {
        total++;
        if (approachShot.result === "Green") {
          hit++;
        }
      }
    });
  });

  return { hit, total };
};

const calculateApproachAccuracy = (rounds) => {
  let accurate = 0;
  let total = 0;

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      holeData.shots.forEach((shot) => {
        if (shot.category === "Approach") {
          total++;
          if (
            shot.result === "Green" ||
            (shot.result !== "Water" &&
              shot.result !== "OB" &&
              parseFloat(shot.proximityToHole) <= 30)
          ) {
            accurate++;
          }
        }
      });
    });
  });

  return { accurate, total };
};

const calculateApproachStrokesGained = (rounds) => {
  let totalStrokesGained = 0;
  let totalApproachShots = 0;

  const getExpectedStrokes = (distance, lie) => {
    let expected = 3.0;
    if (distance <= 50) expected = 2.7;
    else if (distance <= 100) expected = 2.8;
    else if (distance <= 150) expected = 2.9;
    else if (distance <= 200) expected = 3.0;
    else expected = 3.1;

    if (lie === "rough" || lie === "bunker") expected += 0.2;
    return expected;
  };

  const getExpectedStrokesFromGreen = (proximity) => {
    if (proximity <= 5) return 1.8;
    if (proximity <= 10) return 1.9;
    if (proximity <= 20) return 2.0;
    if (proximity <= 30) return 2.1;
    return 2.2;
  };

  rounds.forEach((round, roundIndex) => {
    console.log(`Analyzing round ${roundIndex + 1}`);
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      holeData.shots.forEach((shot, shotIndex) => {
        if (shot.category === "Approach") {
          totalApproachShots++;
          const distance = parseFloat(shot.distance);
          const startingLie =
            shotIndex > 0
              ? holeData.shots[shotIndex - 1].result.toLowerCase()
              : "fairway";
          const expectedBefore = getExpectedStrokes(distance, startingLie);

          let expectedAfter;
          if (shot.result.toLowerCase() === "green") {
            expectedAfter = getExpectedStrokesFromGreen(
              parseFloat(shot.proximityToHole)
            );
          } else {
            const nextShot = holeData.shots[shotIndex + 1];
            expectedAfter = nextShot
              ? getExpectedStrokes(
                parseFloat(nextShot.distance),
                shot.result.toLowerCase()
              )
              : 3.0;
          }

          const strokesGained = expectedBefore - expectedAfter - 1;
          totalStrokesGained += strokesGained;

          console.log(`Hole ${holeNumber}, Approach shot:`);
          console.log(`  Distance: ${distance} yards, Result: ${shot.result}`);
          console.log(
            `  Expected before: ${expectedBefore.toFixed(
              2
            )}, Expected after: ${expectedAfter.toFixed(2)}`
          );
          console.log(`  Strokes gained: ${strokesGained.toFixed(2)}`);
        }
      });
    });
  });

  const averageStrokesGained =
    totalApproachShots > 0 ? totalStrokesGained / totalApproachShots : 0;
  console.log(`Total Approach Shots: ${totalApproachShots}`);
  console.log(`Total Strokes Gained: ${totalStrokesGained.toFixed(2)}`);
  console.log(
    `Average Strokes Gained per Approach Shot: ${averageStrokesGained.toFixed(
      3
    )}`
  );

  return averageStrokesGained;
};
const calculateGIRByDistance = (rounds) => {
  const distanceBuckets = {
    "0-50": { hit: 0, total: 0 },
    "51-100": { hit: 0, total: 0 },
    "101-150": { hit: 0, total: 0 },
    "151-200": { hit: 0, total: 0 },
    "201+": { hit: 0, total: 0 },
  };

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      const approachShot = holeData.shots.find(
        (shot) => shot.category === "Approach"
      );
      if (approachShot) {
        const distance = parseInt(approachShot.distance);
        let bucket;
        if (distance <= 50) bucket = "0-50";
        else if (distance <= 100) bucket = "51-100";
        else if (distance <= 150) bucket = "101-150";
        else if (distance <= 200) bucket = "151-200";
        else bucket = "201+";

        distanceBuckets[bucket].total++;
        if (approachShot.result === "Green") {
          distanceBuckets[bucket].hit++;
        }
      }
    });
  });

  return Object.entries(distanceBuckets).map(([range, stats]) => ({
    range,
    percentage: stats.total > 0 ? (stats.hit / stats.total) * 100 : 0,
  }));
};

const calculateGIRByClub = (rounds) => {
  const clubStats = {};

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      const approachShot = holeData.shots.find(
        (shot) => shot.category === "Approach"
      );
      if (approachShot) {
        if (!clubStats[approachShot.club]) {
          clubStats[approachShot.club] = { hit: 0, total: 0 };
        }
        clubStats[approachShot.club].total++;
        if (approachShot.result === "Green") {
          clubStats[approachShot.club].hit++;
        }
      }
    });
  });

  return Object.entries(clubStats).map(([club, stats]) => ({
    club,
    percentage: stats.total > 0 ? (stats.hit / stats.total) * 100 : 0,
  }));
};

const calculateAndSortGIRByClub = (rounds) => {
  const girByClub = calculateGIRByClub(rounds);
  return sortClubsByPerformance(
    girByClub.reduce(
      (acc, { club, percentage }) => ({ ...acc, [club]: { percentage } }),
      {}
    ),
    "percentage",
    availableClubs
  );
};

const getBestGIRDistanceRange = (girByDistance) => {
  return girByDistance.reduce((best, current) =>
    current.percentage > best.percentage ? current : best
  ).range;
};

const getBestGIRClub = (sortedGirByClub) => {
  const clubsWithGIR = sortedGirByClub.filter((club) => club.percentage > 0);
  return clubsWithGIR.length > 0 ? clubsWithGIR[0].club : "N/A";
};

export default ApproachStats;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RoundSummary from "../components/RoundSummary";
import { db } from "../services/firebase";
import DashboardButton from "../components/DashboardButton";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import styled from '@emotion/styled';
import {
  Container,
  Title,
  Button,
  ErrorMessage,
  Text,
  SlideContainer,
  SlideContent,
} from "../styles";

const CenteredContainer = styled(SlideContainer)`
  justify-content: flex-start;
  padding-top: 2rem;
`;

const CenteredContent = styled(SlideContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  max-width: 600px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
`;

const StyledDashboardButton = styled(DashboardButton)`
  margin-bottom: 1rem;
`;

const RoundSummaryPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [roundData, setRoundData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRoundData = async () => {
      console.log("Location state:", location.state);
      if (location.state?.roundData) {
        console.log("Round data from state:", location.state.roundData);
        setRoundData(location.state.roundData);
        setLoading(false);
      } else {
        console.log(
          "No round data in state, attempting to fetch most recent round"
        );
        try {
          if (!currentUser) {
            throw new Error("User not authenticated");
          }
          const roundsRef = collection(db, "rounds");
          const q = query(
            roundsRef,
            where("userId", "==", currentUser.uid),
            orderBy("timestamp", "desc"),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            const mostRecentRound = querySnapshot.docs[0].data();
            console.log("Fetched most recent round:", mostRecentRound);
            setRoundData(mostRecentRound);
          } else {
            console.log("No rounds found for user");
            setRoundData(null);
          }
        } catch (err) {
          console.error("Error fetching most recent round:", err);
          setError(`Failed to fetch round data: ${err.message}`);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchRoundData();
  }, [location.state, currentUser]);

  const handleStartRound = () => {
    navigate('/round-entry');
  };

  const renderContent = () => {
    if (loading) {
      return <Text>Loading...</Text>;
    }

    if (error) {
      return <ErrorMessage>Error: {error}</ErrorMessage>;
    }

    if (!roundData) {
      return (
        <>
          <Title>Round Summary</Title>
          <Text>Complete your first round to view a Summary</Text>
          <ButtonContainer>
            <Button onClick={handleStartRound}>Start a Round</Button>
          </ButtonContainer>
        </>
      );
    }

    return (
      <>
        <Title>Round Summary</Title>
        <RoundSummary roundData={roundData} />
      </>
    );
  };

  return (
    <CenteredContainer>
      <CenteredContent>
        <StyledDashboardButton />
        {renderContent()}
      </CenteredContent>
    </CenteredContainer>
  );
};

export default RoundSummaryPage;
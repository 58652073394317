import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Global, ThemeProvider } from "@emotion/react";
import { AnimatePresence } from "framer-motion";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import PageTransition from "./components/PageTransition";
import Home from "./pages/Home";
import AdminDashboard from "./pages/AdminDashboard";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Dashboard from "./pages/Dashboard";
import UserProfile from "./pages/UserProfile";
import RoundEntry from "./pages/RoundEntry";
import RoundSummaryPage from "./pages/RoundSummaryPage";
import MetricsDashboard from "./pages/MetricsDashboard";
import ScorecardUpload from "./pages/ScorecardUploadPage";
import { globalStyles } from "./styles";
import { theme } from "./styles";

function AnimatedRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <PageTransition>
              <Home />
            </PageTransition>
          }
        />
        <Route
          path="/login"
          element={
            <PageTransition>
              <Login />
            </PageTransition>
          }
        />
        <Route
          path="/signup"
          element={
            <PageTransition>
              <Signup />
            </PageTransition>
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedRoute adminOnly={true}>
              <PageTransition>
                <AdminDashboard />
              </PageTransition>
            </ProtectedRoute>
          }
        />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <PageTransition>
                <Dashboard />
              </PageTransition>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <PageTransition>
                <UserProfile />
              </PageTransition>
            </ProtectedRoute>
          }
        />
        <Route
          path="/round-entry"
          element={
            <ProtectedRoute>
              <PageTransition>
                <RoundEntry />
              </PageTransition>
            </ProtectedRoute>
          }
        />
        <Route
          path="/round-summary"
          element={
            <ProtectedRoute>
              <PageTransition>
                <RoundSummaryPage />
              </PageTransition>
            </ProtectedRoute>
          }
        />
        <Route
          path="/metrics-dashboard"
          element={
            <ProtectedRoute>
              <PageTransition>
                <MetricsDashboard />
              </PageTransition>
            </ProtectedRoute>
          }
        />
        <Route
          path="/upload-new-course"
          element={
            <ProtectedRoute adminOnly={true}>
              <PageTransition>
                <ScorecardUpload />
              </PageTransition>
            </ProtectedRoute>
          }
        />
      </Routes>
    </AnimatePresence>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Global styles={globalStyles} />
        <Router>
          <AnimatedRoutes />
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

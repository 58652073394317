import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import styled from "@emotion/styled";

const Container = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  max-width: 700px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: bold;
  color: black;
  margin: 0 0 10px;
  text-align: center;
`;

const TotalScore = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #0f3460;
  text-align: center;
  margin-bottom: 5px;
`;

const Subtitle = styled.p`
  font-size: 14px;
  color: #888;
  margin: 0 0 20px;
  text-align: center;
`;

const ChartContainer = styled.div`
  height: 250px;
  margin-top: 20px;
`;

const StrokesGainedComponent = ({ data }) => {
  const total = Object.values(data).reduce((sum, value) => sum + value, 0);

  const sortedData = Object.entries(data).sort(
    ([, a], [, b]) => Math.abs(b) - Math.abs(a)
  );
  const [largestCategory, secondLargestCategory] = sortedData
    .slice(0, 2)
    .map(([key]) => key);

  const chartData = [
    { category: "Driving", value: data.driving },
    { category: "Approach", value: data.approach },
    { category: "Short", value: data.short },
    { category: "Putting", value: data.putting },
  ];

  const maxAbsValue = Math.max(...Object.values(data).map(Math.abs));

  const getColor = (category, value) => {
    if (category.toLowerCase() === largestCategory.toLowerCase())
      return value > 0 ? "#34D399" : "#e94560"; // Green for positive, Red for negative
    if (category.toLowerCase() === secondLargestCategory.toLowerCase())
      return value > 0 ? "#34D399" : "#e94560"; // Different shades for the second largest
    return "#cccccc";
  };

  return (
    <Container>
      <Title>Your Strokes Gained (SG) Breakdown</Title>
      <TotalScore>{total.toFixed(1)}</TotalScore>
      <Subtitle>SG / Round</Subtitle>
      <Subtitle>
        You {total >= 0 ? "gain" : "lose"} {Math.abs(total).toFixed(1)} strokes
        per round compared to a 0 H.I.
      </Subtitle>
      <ChartContainer>
        <ResponsiveBar
          data={chartData}
          keys={["value"]}
          indexBy="category"
          margin={{ top: 30, right: 10, bottom: 50, left: 10 }}
          padding={0.3}
          layout="vertical"
          valueScale={{ type: "linear", min: -maxAbsValue, max: maxAbsValue }}
          indexScale={{ type: "band", round: true }}
          colors={({ indexValue, data }) => getColor(indexValue, data.value)}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={{
            tickSize: 0,
            tickPadding: 12,
          }}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={null}
          enableGridY={false}
          enableLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          tooltip={() => null}
          markers={[
            {
              axis: "y",
              value: 0,
              lineStyle: { stroke: "grey", strokeWidth: 0 },
            },
          ]}
          layers={[
            "grid",
            "bars",
            "markers",
            "annotations",
            ({ bars }) => {
              return bars.map((bar) => (
                <g key={bar.key}>
                  <text
                    x={bar.x + bar.width / 2}
                    y={bar.y - 10}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fill: "#666", fontSize: 12, fontWeight: "bold" }}
                  >
                    {bar.data.value.toFixed(2)}
                  </text>
                </g>
              ));
            },
          ]}
        />
      </ChartContainer>
    </Container>
  );
};

export default StrokesGainedComponent;

import React from "react";
import { Bar, Scatter, Pie } from "react-chartjs-2";
import styled from "@emotion/styled";
import { ChartSection, ChartTitle, ChartContainer, ChartWrapper, StatCard, StatGrid, StatTitle, StatValue } from '../styles';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const StatsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StatsHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;


const PuttingStats = ({ rounds }) => {
  console.log("Rounds in PuttingStats:", rounds);

  if (!rounds || rounds.length === 0) {
    return <div>No Putting data recorded, play your first round to gain insights</div>;
  }

  const puttingData = puttingPercentages(rounds);
  const missDirections = calculateMissDirections(rounds);
  const averageMissDistance = calculateAverageMissDistance(rounds);
  const avgPuttsPerHole = calculateAvgPuttsPerHole(rounds);
  const puttCounts = calculatePuttCounts(rounds);

  const mostEffectiveRange = puttingData.reduce((prev, current) =>
    current.percentage > prev.percentage ? current : prev
  );

  const leastEffectiveRange = puttingData.reduce(
    (prev, current) => {
      if (current.attempts === 0) return prev; // Skip ranges with no attempts
      if (prev.attempts === 0) return current; // If prev has no attempts, use current
      return current.percentage < prev.percentage ? current : prev;
    },
    { attempts: 0, percentage: 100, range: "N/A" }
  );

  console.log("Processed putting data:", puttingData);
  console.log("Miss directions:", missDirections);
  console.log("Average miss distance:", averageMissDistance);
  console.log("Average putts per hole:", avgPuttsPerHole);
  console.log("Putt counts:", puttCounts);
  console.log("Most effective range:", mostEffectiveRange);
  console.log("Least effective range:", leastEffectiveRange);

  const data = {
    labels: puttingData.map((d) => d.range),
    datasets: [
      {
        type: "bar",
        label: "Putting Percentage",
        data: puttingData.map((d) => d.percentage),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        yAxisID: "y",
      },
      {
        type: "line",
        label: "Number of Attempts",
        data: puttingData.map((d) => d.attempts),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y1",
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Putting Performance by Distance",
        font: {
          size: 18,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label +=
                context.dataset.label === "Putting Percentage"
                  ? context.parsed.y.toFixed(1) + "%"
                  : context.parsed.y;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Distance (ft)",
        },
      },
      y: {
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Putting Percentage",
        },
      },
      y1: {
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Number of Attempts",
        },
      },
    },
  };

  const missDirectionData = {
    datasets: [
      {
        label: "Long",
        data: [{ x: 0, y: 1 }],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        pointRadius: Math.sqrt(missDirections.long) * 5,
      },
      {
        label: "Short",
        data: [{ x: 0, y: -1 }],
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        pointRadius: Math.sqrt(missDirections.short) * 5,
      },
      {
        label: "Left",
        data: [{ x: -1, y: 0 }],
        backgroundColor: "rgba(255, 206, 86, 0.6)",
        pointRadius: Math.sqrt(missDirections.left) * 5,
      },
      {
        label: "Right",
        data: [{ x: 1, y: 0 }],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        pointRadius: Math.sqrt(missDirections.right) * 5,
      },
    ],
  };

  const missDirectionOptions = {
    responsive: true,
    maintainAspectRatio: false,


    plugins: {
      legend: {
        position: "top",

      },
      title: {
        display: true,
        text: "First Putt Miss Direction",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.dataset.label}: ${missDirections[
              context.dataset.label.toLowerCase()
            ].toFixed(1)}%`;
          },
        },
      },
    },
    scales: {
      x: {
        min: -1.5,
        max: 1.5,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: -1.5,
        max: 1.5,
        ticks: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const puttCountData = {
    labels: ["One-putts", "Two-putts", "Three-putts or more"],
    datasets: [
      {
        data: [
          puttCounts.onePutts,
          puttCounts.twoPutts,
          puttCounts.threePuttsPlus,
        ],
        backgroundColor: [
          "rgba(75, 192, 192, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(255, 99, 132, 0.6)",
        ],
        borderColor: [
          "rgba(75, 192, 192, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 99, 132, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const puttCountOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Putt Count Distribution",
      },
    },
  };

  return (
    <StatsContainer>
      <StatsHeader>Putting Statistics</StatsHeader>

      <ChartSection>
        <ChartTitle>Putting Percentages by Distance</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Bar data={data} options={options} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>First Putt Miss Direction</ChartTitle>
        <ChartContainer>
          <ChartWrapper style={{ position: "relative" }}>
            <Scatter data={missDirectionData} options={missDirectionOptions} />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
                fontSize: "14px",
                color: "#666",
              }}
            >

            </div>
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Putt Count Distribution</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Pie data={puttCountData} options={puttCountOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <StatGrid>
        <StatCard>
          <StatTitle>Average Miss Distance</StatTitle>
          <StatValue>{averageMissDistance.toFixed(2)} feet</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Average Putts per Hole</StatTitle>
          <StatValue>{avgPuttsPerHole.toFixed(2)}</StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Most Effective Putting Range</StatTitle>
          <StatValue>
            {mostEffectiveRange.range}
            <br />({mostEffectiveRange.percentage.toFixed(1)}% success rate)
          </StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Least Effective Putting Range</StatTitle>
          <StatValue>
            {leastEffectiveRange.attempts > 0 ? (
              <>
                {leastEffectiveRange.range}
                <br />({leastEffectiveRange.percentage.toFixed(1)}% success
                rate)
              </>
            ) : (
              "Not enough data"
            )}
          </StatValue>
        </StatCard>
      </StatGrid>
    </StatsContainer>
  );
};

const puttingPercentages = (rounds) => {
  const ranges = [
    { min: 0, max: 3 },
    { min: 3, max: 6 },
    { min: 6, max: 10 },
    { min: 10, max: 15 },
    { min: 15, max: 20 },
    { min: 20, max: Infinity },
  ];

  const stats = ranges.map((range) => ({
    range,
    attempts: 0,
    holed: 0,
  }));

  rounds.forEach((round) => {
    Object.values(round.shots || {}).forEach((holeData) => {
      const holeShots = holeData.shots;
      for (let i = 0; i < holeShots.length; i++) {
        const shot = holeShots[i];
        if (shot.category === "Putt") {
          let distance;
          // Find the last non-putt shot to get the proximity
          for (let j = i - 1; j >= 0; j--) {
            if (
              holeShots[j].category !== "Putt" &&
              holeShots[j].proximityToHole
            ) {
              distance = parseFloat(holeShots[j].proximityToHole);
              break;
            }
          }

          if (distance !== undefined) {
            const rangeIndex = ranges.findIndex(
              (r) => distance >= r.min && distance < r.max
            );

            if (rangeIndex !== -1) {
              stats[rangeIndex].attempts++;
              if (shot.result === "In the Hole") {
                stats[rangeIndex].holed++;
              }
            }
          }
        }
      }
    });
  });

  return stats.map((s) => ({
    range: `${s.range.min}-${s.range.max === Infinity ? "+" : s.range.max
      } feet`,
    percentage: (s.holed / s.attempts) * 100 || 0,
    attempts: s.attempts,
    holed: s.holed,
  }));
};

const calculateMissDirections = (rounds) => {
  let missDirections = { long: 0, short: 0, left: 0, right: 0, total: 0 };

  rounds.forEach((round) => {
    Object.values(round.shots || {}).forEach((holeData) => {
      const holeShots = holeData.shots;
      const firstPuttIndex = holeShots.findIndex(
        (shot) => shot.category === "Putt"
      );
      if (firstPuttIndex !== -1) {
        const firstPutt = holeShots[firstPuttIndex];
        if (firstPutt.result !== "In the Hole") {
          missDirections.total++;
          switch (firstPutt.result.toLowerCase()) {
            case "long":
              missDirections.long++;
              break;
            case "short":
              missDirections.short++;
              break;
            case "left":
              missDirections.left++;
              break;
            case "right":
              missDirections.right++;
              break;
          }
        }
      }
    });
  });

  // Convert to percentages
  for (let direction in missDirections) {
    if (direction !== "total") {
      missDirections[direction] =
        (missDirections[direction] / missDirections.total) * 100 || 0;
    }
  }

  return missDirections;
};

const calculateAverageMissDistance = (rounds) => {
  let totalMissDistance = 0;
  let missCount = 0;

  rounds.forEach((round) => {
    Object.values(round.shots || {}).forEach((holeData) => {
      const holeShots = holeData.shots;
      let lastNonPuttProximity = null;

      for (let i = 0; i < holeShots.length; i++) {
        const shot = holeShots[i];

        if (shot.category !== "Putt") {
          if (shot.proximityToHole) {
            lastNonPuttProximity = parseFloat(shot.proximityToHole);
          }
        } else if (shot.category === "Putt") {
          if (lastNonPuttProximity !== null && shot.result !== "In the Hole") {
            totalMissDistance += lastNonPuttProximity;
            missCount++;
          }
          // Reset lastNonPuttProximity after each putt
          lastNonPuttProximity = null;
        }
      }
    });
  });

  return missCount > 0 ? totalMissDistance / missCount : 0;
};

const calculateAvgPuttsPerHole = (rounds) => {
  let totalPutts = 0;
  let totalHoles = 0;

  rounds.forEach((round) => {
    Object.values(round.shots || {}).forEach((holeData) => {
      const holeShots = holeData.shots;
      const putts = holeShots.filter((shot) => shot.category === "Putt").length;
      totalPutts += putts;
      totalHoles++;
    });
  });

  return totalHoles > 0 ? totalPutts / totalHoles : 0;
};

const calculatePuttCounts = (rounds) => {
  let onePutts = 0;
  let twoPutts = 0;
  let threePuttsPlus = 0;
  let totalHoles = 0;

  rounds.forEach((round) => {
    Object.values(round.shots || {}).forEach((holeData) => {
      const holeShots = holeData.shots;
      const putts = holeShots.filter((shot) => shot.category === "Putt").length;
      totalHoles++;

      if (putts === 1) onePutts++;
      else if (putts === 2) twoPutts++;
      else if (putts >= 3) threePuttsPlus++;
    });
  });

  return {
    onePutts: (onePutts / totalHoles) * 100,
    twoPutts: (twoPutts / totalHoles) * 100,
    threePuttsPlus: (threePuttsPlus / totalHoles) * 100,
  };
};
export default PuttingStats;

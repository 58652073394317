import React, { useState, useEffect } from 'react';
import { useAuth } from "../context/AuthContext";
import { db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import styled from '@emotion/styled';

const ClubDistancesContainer = styled.div`
  background-color: ${props => props.theme.bgColor};
  color: ${props => props.theme.textColor};
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SmartDistancesTitle = styled.h3`
  font-size: 1.1rem;
  color: ${props => props.theme.textColor}99;
  text-align: center;
  margin: 1rem 0;
`;

const ClubList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ClubItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${props => props.theme.primaryColor}33;
`;

const ClubName = styled.span`
  font-weight: bold;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  padding: 0.5rem 1rem;
  border-radius: 4px;
  min-width: 60px;
  text-align: center;
`;

const DistanceBar = styled.div`
  flex-grow: 1;
  background-color: ${props => props.theme.accentColor}33;
  height: 30px;
  margin: 0 1rem;
  border-radius: 15px;
  overflow: hidden;
`;

const DistanceFill = styled.div`
  height: 100%;
  width: ${props => props.percentage}%;
  background: linear-gradient(to right, 
    ${props => props.theme.accentColor}99,
    ${props => props.theme.accentColor}
  );
`;

const Distance = styled.span`
  font-weight: bold;
`;

// Define the club order
const clubOrder = [
    'Dr', '2w', '3w', '4w', '5w', '7w', '2h', '3h', '4h', '5h', '6h',
    '1i', '2i', '3i', '4i', '5i', '6i', '7i', '8i', '9i',
    'Pw', 'Gw', 'Sw', 'Lw', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60'
];

const ClubDistances = ({ roundData = null }) => {
    const { currentUser } = useAuth();
    const [clubDistances, setClubDistances] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchClubDistances = async () => {
            if (!currentUser) return;

            try {
                if (roundData) {
                    // Process round-specific data
                    let distances = {};
                    Object.values(roundData.shots).forEach(hole => {
                        hole.shots.forEach(shot => {
                            if (shot.club && shot.distance) {
                                if (!distances[shot.club]) {
                                    distances[shot.club] = [];
                                }
                                distances[shot.club].push(parseInt(shot.distance));
                            }
                        });
                    });
                    // Calculate averages
                    Object.keys(distances).forEach(club => {
                        distances[club] = Math.round(
                            distances[club].reduce((sum, distance) => sum + distance, 0) / distances[club].length
                        );
                    });
                    setClubDistances(distances);
                } else {
                    // Fetch all-time data
                    const clubDistancesDocRef = doc(db, "clubDistances", currentUser.uid);
                    const docSnap = await getDoc(clubDistancesDocRef);

                    if (docSnap.exists()) {
                        const data = docSnap.data();
                        let distances = {};
                        for (const [club, clubData] of Object.entries(data)) {
                            if (club !== 'lastUpdated' && typeof clubData === 'object') {
                                if (clubData.averageDistance && !isNaN(clubData.averageDistance)) {
                                    distances[club] = clubData.averageDistance;
                                } else if (clubData.distances && Array.isArray(clubData.distances)) {
                                    const validDistances = clubData.distances
                                        .map(d => d.distance)
                                        .filter(d => !isNaN(d));
                                    if (validDistances.length > 0) {
                                        distances[club] = Math.round(
                                            validDistances.reduce((sum, distance) => sum + distance, 0) / validDistances.length
                                        );
                                    }
                                }
                            }
                        }
                        setClubDistances(distances);
                    }
                }
            } catch (error) {
                console.error("Error fetching club distances:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchClubDistances();
    }, [currentUser, roundData]);

    const maxDistance = Math.max(...Object.values(clubDistances));

    if (loading) {
        return <div>Loading club distances...</div>;
    }

    // Sort clubs based on the predefined order
    const sortedClubs = Object.entries(clubDistances).sort((a, b) => {
        const indexA = clubOrder.indexOf(a[0]);
        const indexB = clubOrder.indexOf(b[0]);
        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
    });

    return (
        <ClubDistancesContainer>
            <Title>Club Distances</Title>
            <SmartDistancesTitle>
                {roundData ? "Round-Specific Distances (yds)" : "Your Smart Distances (yds)"}
            </SmartDistancesTitle>
            <ClubList>
                {sortedClubs.map(([club, distance]) => (
                    <ClubItem key={club}>
                        <ClubName>{club}</ClubName>
                        <DistanceBar>
                            <DistanceFill percentage={(distance / maxDistance) * 100} />
                        </DistanceBar>
                        <Distance>{distance}</Distance>
                    </ClubItem>
                ))}
            </ClubList>
        </ClubDistancesContainer>
    );
};

export default ClubDistances;
import React, { useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';

const theme = {
    bgColor: "#1a1a2e",
    textColor: "#ffffff",
    primaryColor: "#0f3460",
    secondaryColor: "#f4d37e",
    accentColor: "#e94560",
    greenColor: "#34D399",
};

const PerformanceTrendsChart = ({ trendsData }) => {
    const [activeLines, setActiveLines] = useState(trendsData.map(d => d.id));

    const toggleLine = (lineId) => {
        setActiveLines(prev =>
            prev.includes(lineId)
                ? prev.filter(id => id !== lineId)
                : [...prev, lineId]
        );
    };

    const getColor = (index) => {
        const colors = [theme.primaryColor, theme.secondaryColor, theme.accentColor, theme.greenColor];
        return colors[index % colors.length];
    };

    const styledData = trendsData.map((line, index) => ({
        ...line,
        color: getColor(index)
    }));

    return (
        <div style={{ height: 600, width: '100%' }}>
            <h3 style={{ textAlign: 'center', marginBottom: '20px', color: theme.primaryColor }}>Performance Trends</h3>
            <div style={{ height: 400, width: '100%' }}>
                <ResponsiveLine
                    data={styledData.filter(line => activeLines.includes(line.id))}
                    margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                    yFormat=" >-.2f"
                    curve="monotoneX"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Round',
                        legendOffset: 36,
                        legendPosition: 'middle'
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Score',
                        legendOffset: -40,
                        legendPosition: 'middle'
                    }}
                    enableGridX={false}
                    colors={{ datum: 'color' }}
                    lineWidth={3}
                    pointSize={5}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={5}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    enableSlices="x"
                    sliceTooltip={({ slice }) => (
                        <div
                            style={{
                                background: theme.textColor,
                                padding: '9px 12px',
                                border: `1px solid ${theme.textColor}`,
                                borderRadius: '3px',
                                color: theme.primaryColor
                            }}
                        >
                            <strong>Round {slice.points[0].data.x}</strong>
                            {slice.points.map(point => (
                                <div key={point.id} style={{ color: point.serieColor }}>
                                    {point.serieId}: {parseFloat(point.data.y).toFixed(2)}
                                </div>
                            ))}
                        </div>
                    )}
                    motionConfig="stiff"
                    defs={[
                        linearGradientDef('gradientA', [
                            { offset: 0, color: 'inherit', opacity: 0.6 },
                            { offset: 100, color: 'inherit', opacity: 0 },
                        ], {
                            gradientTransform: 'rotate(90 0.5 0.5)'
                        }),
                    ]}
                    fill={[{ match: '*', id: 'gradientA' }]}
                    enableArea={true}
                    areaOpacity={1}
                    areaBlendMode="multiply"
                />
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '20px' }}>
                {styledData.map((line) => (
                    <div
                        key={line.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '5px 10px',
                            cursor: 'pointer',
                            opacity: activeLines.includes(line.id) ? 1 : 0.6,
                            transition: 'opacity 0.2s ease'
                        }}
                        onClick={() => toggleLine(line.id)}
                    >
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                marginRight: '5px',
                                backgroundColor: line.color
                            }}
                        />
                        <span style={{ fontSize: '14px', color: theme.primaryColor }}>{line.id}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PerformanceTrendsChart;
import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { auth, db } from "../services/firebase";
import { signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Container,
  Title,
  Button,
  List,
  ListItem,
  StyledLink,
} from "../styles";

const DashboardContainer = styled(Container)`
  max-width: 600px;
`;

const WelcomeText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
`;

const NavList = styled(List)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const NavItem = styled(ListItem)`
  margin-bottom: 0;
`;

const NavLink = styled(StyledLink)`
  display: block;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.textColor};
  padding: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
    color: ${(props) => props.theme.textColor};
  }
`;

const LogoutButton = styled(Button)`
  background-color: ${(props) => props.theme.accentColor};

  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
  }
`;

const ActiveRoundNotification = styled.div`
  background-color: ${(props) => props.theme.accentColor};
  color: ${(props) => props.theme.textColor};
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const NotificationText = styled.p`
  margin: 0 0 1rem 0;
  font-weight: bold;
`;

const NotificationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

const NotificationButton = styled(Button)`
  flex: 1;
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
`;

const ContinueButton = styled(NotificationButton)`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.textColor};
  
  &:hover {
    background-color: ${(props) => props.theme.accentColor};
  }
`;

const AbandonButton = styled(NotificationButton)`
  background-color: ${(props) => props.theme.errorColor || '#ff6b6b'};
  color: ${(props) => props.theme.textColor};
  
  &:hover {
    background-color: ${(props) => props.theme.accentColor};
  }
`;

const Dashboard = () => {
  const { currentUser, userClaims } = useAuth();
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const [hasActiveRound, setHasActiveRound] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserName(userData.name || "");
          }
        } catch (err) {
          console.error("Error fetching user profile:", err);
        }
      }
    };

    fetchUserProfile();
    checkForActiveRound();
  }, [currentUser]);

  const checkForActiveRound = () => {
    const activeRound = localStorage.getItem("activeRound");
    setHasActiveRound(!!activeRound);
  };

  const handleContinueRound = () => {
    navigate("/round-entry");
  };

  const handleAbandonRound = () => {
    if (window.confirm("Are you sure you want to abandon the active round?")) {
      localStorage.removeItem("activeRound");
      setHasActiveRound(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <DashboardContainer>
      <Title>Dashboard</Title>
      <WelcomeText>Welcome, {userName || currentUser?.email}</WelcomeText>

      {hasActiveRound && (
        <ActiveRoundNotification>
          <NotificationText>You have an active round in progress.</NotificationText>
          <NotificationButtons>
            <ContinueButton onClick={handleContinueRound}>
              Continue Round
            </ContinueButton>
            <AbandonButton onClick={handleAbandonRound}>
              Abandon Round
            </AbandonButton>
          </NotificationButtons>
        </ActiveRoundNotification>
      )}

      <nav>
        <NavList>
          <NavItem>
            <NavLink as={Link} to="/profile">
              Edit Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/round-entry">
              {hasActiveRound ? "Resume Round Entry" : "Enter A New Round"}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/round-summary">
              View Last Round Summary
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/metrics-dashboard">
              View Metrics Dashboard
            </NavLink>
          </NavItem>
          {userClaims?.admin && (
            <NavItem>
              <NavLink as={Link} to="/upload-new-course">
                Upload A Course Scorecard
              </NavLink>
            </NavItem>
          )}
          {userClaims?.admin && (
            <NavItem>
              <NavLink as={Link} to="/admin-dashboard">
                Admin Dashboard
              </NavLink>
            </NavItem>
          )}
        </NavList>
      </nav>
      <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
    </DashboardContainer>
  );
};

export default Dashboard;
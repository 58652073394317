import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const RadialHeatmap = ({ data, title, xLabel, yLabel }) => {
    const svgRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateDimensions = () => {
            const containerWidth = svgRef.current.parentElement.clientWidth;
            const size = Math.min(containerWidth, 400); // Cap at 400px
            setDimensions({ width: size, height: size });
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    useEffect(() => {
        if (data && data.length > 0 && dimensions.width > 0) {
            const { width, height } = dimensions;
            const margin = { top: 40, right: 40, bottom: 40, left: 40 };
            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;

            // Clear any existing SVG content
            d3.select(svgRef.current).selectAll("*").remove();

            const svg = d3.select(svgRef.current)
                .attr('width', width)
                .attr('height', height)
                .append('g')
                .attr('transform', `translate(${margin.left + innerWidth / 2},${margin.top + innerHeight / 2})`);

            // Create scales
            const xScale = d3.scaleLinear()
                .domain([-1, 1])
                .range([-innerWidth / 2, innerWidth / 2]);

            const yScale = d3.scaleLinear()
                .domain([-1, 1])
                .range([innerHeight / 2, -innerHeight / 2]);

            // Create a color scale
            const colorScale = d3.scaleSequential(d3.interpolateYlOrRd)
                .domain([0, d3.max(data, d => d.count) || 1]);

            // Create a contour generator
            const contours = d3.contourDensity()
                .x(d => xScale(d.greenPositionX))
                .y(d => yScale(d.greenPositionY))
                .size([innerWidth, innerHeight])
                .bandwidth(20)
                (data);

            // Draw the contours
            svg.selectAll('path')
                .data(contours)
                .enter().append('path')
                .attr('d', d3.geoPath())
                .attr('fill', d => colorScale(d.value));

            // Draw the green outline
            svg.append('circle')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', Math.min(innerWidth, innerHeight) / 2)
                .attr('fill', 'none')
                .attr('stroke', 'lightgreen')
                .attr('stroke-width', 2);

            // Add labels
            const fontSize = Math.max(10, Math.min(14, width / 25)); // Responsive font size

            svg.append('text')
                .attr('x', 0)
                .attr('y', -innerHeight / 2 - 10)
                .attr('text-anchor', 'middle')
                .style('font-size', `${fontSize}px`)
                .text(title);

            svg.append('text')
                .attr('x', innerWidth / 2 + 10)
                .attr('y', 0)
                .attr('text-anchor', 'start')
                .attr('dominant-baseline', 'middle')
                .style('font-size', `${fontSize}px`)
                .text(xLabel);

            svg.append('text')
                .attr('x', 0)
                .attr('y', -innerHeight / 2 - 30)
                .attr('text-anchor', 'middle')
                .style('font-size', `${fontSize}px`)
                .text(yLabel);
        }
    }, [data, title, xLabel, yLabel, dimensions]);

    return (
        <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
            <svg ref={svgRef} style={{ width: '100%', height: 'auto', aspectRatio: '1 / 1' }}></svg>
        </div>
    );
};

export default RadialHeatmap;
import React, { useState, useMemo, useEffect } from 'react';
import styled from '@emotion/styled';
import {
    Container,
    Title,
    SubTitle,
    Button,
    Input,
    Text,
    theme
} from '../styles';

const ScoreCardContainer = styled(Container)`
  max-width: 1000px;
  width: 95%;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

const Th = styled.th`
  background-color: ${theme.primaryColor};
  color: ${theme.textColor};
  padding: 0.5rem;
  text-align: left;
  font-size: 0.9rem;
`;

const Td = styled.td`
  background-color: ${theme.secondaryColor};
  color: ${theme.textColor};
  padding: 0.5rem;
  border-bottom: 1px solid ${theme.primaryColor};
`;

const SmallInput = styled(Input)`
  width: 60px;
  padding: 0.3rem;
  font-size: 0.9rem;
`;

const CourseInfoContainer = styled.div`
  margin-bottom: 1rem;
`;

const CourseParInfo = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${theme.primaryColor};
  color: ${theme.textColor};
  border-radius: 5px;
`;

const EditableScorecard = ({ courseData, onSave }) => {
    const [editedData, setEditedData] = useState(courseData);

    const calculateTotalPar = (teeColor) => {
        return editedData.holes.reduce((total, hole) => total + (parseInt(hole.pars[teeColor]) || 0), 0);
    };

    useEffect(() => {
        // Update total par for each tee when holes are edited
        const updatedTees = editedData.tees.map(tee => ({
            ...tee,
            totalPar: calculateTotalPar(tee.color)
        }));
        setEditedData(prevData => ({ ...prevData, tees: updatedTees }));
    }, [editedData.holes]);

    const handleInputChange = (field, value) => {
        setEditedData(prevData => ({
            ...prevData,
            [field]: value
        }));
    };

    const handleTeeChange = (index, field, value) => {
        setEditedData(prevData => ({
            ...prevData,
            tees: prevData.tees.map((tee, i) =>
                i === index ? { ...tee, [field]: value } : tee
            )
        }));
    };

    const handleHoleChange = (index, field, subField, value) => {
        setEditedData(prevData => ({
            ...prevData,
            holes: prevData.holes.map((hole, i) =>
                i === index ? {
                    ...hole,
                    [field]: typeof hole[field] === 'object'
                        ? { ...hole[field], [subField]: value }
                        : value
                } : hole
            )
        }));
    };

    // Determine which tee is likely the women's tee (shortest or red)
    const womensTee = useMemo(() => {
        const redTee = editedData.tees.find(tee => tee.color.toLowerCase() === 'red');
        if (redTee) return redTee;

        return editedData.tees.reduce((shortest, current) => {
            const shortestYardage = editedData.holes.reduce((sum, hole) => sum + hole.yardages[shortest.color], 0);
            const currentYardage = editedData.holes.reduce((sum, hole) => sum + hole.yardages[current.color], 0);
            return currentYardage < shortestYardage ? current : shortest;
        });
    }, [editedData]);

    return (
        <ScoreCardContainer>
            <Title>Edit Scorecard</Title>

            <CourseInfoContainer>
                <SubTitle>Course Information</SubTitle>
                <Text>Course Name:</Text>
                <Input
                    value={editedData.name}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                    placeholder="Course Name"
                />
                <CourseParInfo>
                    <SubTitle>Course Par</SubTitle>
                    {editedData.tees.map((tee, index) => (
                        <Text key={index}>
                            {tee.color} Tees: Par {calculateTotalPar(tee.color)}
                        </Text>
                    ))}
                </CourseParInfo>
            </CourseInfoContainer>

            <SubTitle>Tees</SubTitle>
            <Table>
                <thead>
                    <tr>
                        <Th>Color</Th>
                        <Th>Name</Th>
                        <Th>Slope</Th>
                        <Th>Rating</Th>
                    </tr>
                </thead>
                <tbody>
                    {editedData.tees.map((tee, index) => (
                        <tr key={index}>
                            <Td>
                                <SmallInput
                                    value={tee.color}
                                    onChange={(e) => handleTeeChange(index, 'color', e.target.value)}
                                />
                            </Td>
                            <Td>
                                <SmallInput
                                    value={tee.name}
                                    onChange={(e) => handleTeeChange(index, 'name', e.target.value)}
                                />
                            </Td>
                            <Td>
                                <SmallInput
                                    value={tee.slope}
                                    onChange={(e) => handleTeeChange(index, 'slope', e.target.value)}
                                    type="number"
                                />
                            </Td>
                            <Td>
                                <SmallInput
                                    value={tee.rating}
                                    onChange={(e) => handleTeeChange(index, 'rating', e.target.value)}
                                    type="number"
                                />
                            </Td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <SubTitle>Holes</SubTitle>
            <Table>
                <thead>
                    <tr>
                        <Th>Hole</Th>
                        <Th>S.I. (Men)</Th>
                        {editedData.tees.map(tee => (
                            <React.Fragment key={tee.color}>
                                <Th>{tee.color} Par</Th>
                                <Th>{tee.color} Yards</Th>
                                {tee.color === womensTee.color && <Th>S.I. (Women)</Th>}
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {editedData.holes.map((hole, index) => (
                        <tr key={index}>
                            <Td>{hole.number}</Td>
                            <Td>
                                <SmallInput
                                    value={hole.strokeIndexMen}
                                    onChange={(e) => handleHoleChange(index, 'strokeIndexMen', null, e.target.value)}
                                    type="number"
                                />
                            </Td>
                            {editedData.tees.map(tee => (
                                <React.Fragment key={tee.color}>
                                    <Td>
                                        <SmallInput
                                            value={hole.pars[tee.color]}
                                            onChange={(e) => handleHoleChange(index, 'pars', tee.color, e.target.value)}
                                            type="number"
                                        />
                                    </Td>
                                    <Td>
                                        <SmallInput
                                            value={hole.yardages[tee.color]}
                                            onChange={(e) => handleHoleChange(index, 'yardages', tee.color, e.target.value)}
                                            type="number"
                                        />
                                    </Td>
                                    {tee.color === womensTee.color && (
                                        <Td>
                                            <SmallInput
                                                value={hole.strokeIndexWomen}
                                                onChange={(e) => handleHoleChange(index, 'strokeIndexWomen', null, e.target.value)}
                                                type="number"
                                            />
                                        </Td>
                                    )}
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Button onClick={() => onSave(editedData)}>Save Changes</Button>
        </ScoreCardContainer>
    );
};

export default EditableScorecard;
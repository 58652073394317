import React, { useState, useMemo, useRef, useEffect } from "react";
import { Bar, Pie, Scatter, Chart, PolarArea } from "react-chartjs-2";
import { ChartSection, ChartTitle, ChartContainer, ChartWrapper, StatCard, StatGrid, StatTitle, StatValue } from "../styles";
import {
  Chart as ChartJS,
  LinearScale,
  Tooltip,
  Legend,
  RadialLinearScale,
  ArcElement,
} from "chart.js";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import annotationPlugin from "chartjs-plugin-annotation";
import styled from "@emotion/styled";

ChartJS.register(
  LinearScale,
  Tooltip,
  Legend,
  MatrixController,
  MatrixElement,
  RadialLinearScale,
  ArcElement,
  annotationPlugin
);

const NoDataMessage = styled.div`
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
`;

const StatsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StatsHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;



const ClubSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
  justify-content: center;
`;

const ClubIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor};
  color: ${(props) => props.theme.textColor};
  border: 2px solid
    ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
    border-color: ${(props) => props.theme.accentColor};
  }
`;
const ShortGameStats = ({ rounds }) => {
  const [selectedClub, setSelectedClub] = useState("All");
  const chartRef = useRef(null);

  // Process data
  const clubPerformance = useMemo(() => calculateClubPerformance(rounds), [rounds]);
  const upAndDowns = useMemo(() => calculateUpAndDowns(rounds), [rounds]);
  const sandSaves = useMemo(() => calculateSandSaves(rounds), [rounds]);
  const shortGameProximity = useMemo(() => calculateShortGameProximity(rounds), [rounds]);
  const shortGameScoring = useMemo(() => calculateShortGameScoring(rounds), [rounds]);

  const proximityByDistance = useMemo(
    () => calculateShortGameProximityByDistance(shortGameProximity),
    [shortGameProximity]
  );

  const shortGameProximityHeatmapData = useMemo(() => calculateShortGameProximityHeatmap(rounds), [rounds]);

  // Check if we have any short game data
  const hasShortGameData = useMemo(() => {
    return shortGameProximity.length > 0 || Object.keys(clubPerformance).length > 0;
  }, [shortGameProximity, clubPerformance]);

  // Clean up chart on unmount
  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  // Logging for debugging
  useEffect(() => {
    console.log("Processed short game shots:", shortGameProximity);
    console.log("Club performance:", clubPerformance);
    console.log("Up and downs:", upAndDowns);
    console.log("Sand saves:", sandSaves);
  }, [shortGameProximity, clubPerformance, upAndDowns, sandSaves]);

  if (!rounds || rounds.length === 0) {
    return (
      <NoDataMessage>
        No rounds data available. Play your first round to gain insights!
      </NoDataMessage>
    );
  }

  if (!hasShortGameData) {
    return (
      <NoDataMessage>
        No short game data recorded in your rounds.
        Add some short game shots to your rounds to see statistics here!
      </NoDataMessage>
    );
  }



  // Club Performance Chart
  const clubPerformanceData = {
    labels:
      selectedClub === "All" ? Object.keys(clubPerformance) : [selectedClub],
    datasets: [
      {
        label: "Average Proximity (feet)",
        data:
          selectedClub === "All"
            ? Object.values(clubPerformance).map((club) => club.avgProximity)
            : [clubPerformance[selectedClub]?.avgProximity],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const clubPerformanceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Average Proximity by Club (Short Game)",
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Average Proximity (feet)",
        },
      },
    },
  };

  // Up and Down Pie Chart
  const upAndDownData = {
    labels: ["Successful", "Unsuccessful"],
    datasets: [
      {
        data: [upAndDowns.successful, upAndDowns.total - upAndDowns.successful],
        backgroundColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)"],
      },
    ],
  };

  const upAndDownOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Up and Down Success Rate",
      },
    },
  };

  // Sand Save Pie Chart
  const sandSaveData = {
    labels: ["Successful", "Unsuccessful"],
    datasets: [
      {
        data: [sandSaves.successful, sandSaves.total - sandSaves.successful],
        backgroundColor: [
          "rgba(255, 206, 86, 0.6)",
          "rgba(153, 102, 255, 0.6)",
        ],
      },
    ],
  };

  const sandSaveOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Sand Save Success Rate",
      },
    },
  };

  // Short Game Proximity Scatter Plot
  const proximityScatterData = {
    datasets: [
      {
        label: "Short Game Shots",
        data: shortGameProximity.map((shot) => ({
          x: shot.distance,
          y: shot.proximity,
        })),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const proximityScatterOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: "Short Game Proximity vs. Distance",
      },
    },
    scales: {
      x: {
        min: 0,
        title: {
          display: true,
          text: "Distance (yards)",
        },
      },
      y: {
        min: 0,
        title: {
          display: true,
          text: "Proximity to Hole (feet)",
        },
      },
    },
  };

  const proximityByDistanceData = {
    labels: proximityByDistance.map((bucket) => bucket.label),
    datasets: [
      {
        label: "Average Proximity",
        data: proximityByDistance.map((bucket) => bucket.avgProximity),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        yAxisID: "y",
      },
      {
        label: "Hole-Out Percentage",
        data: proximityByDistance.map((bucket) => bucket.holeOutPercentage),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        yAxisID: "y1",
      },
    ],
  };

  const proximityByDistanceOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Average Proximity (feet)",
        },
        position: "left",
      },
      y1: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Hole-Out Percentage",
        },
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
        max: 100,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Short Game Proximity and Hole-Out Percentage by Distance",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.parsed.y || 0;
            const count = proximityByDistance[context.dataIndex].shotCount;
            if (label === "Average Proximity") {
              return `${label}: ${value.toFixed(2)} ft (${count} shots)`;
            } else {
              return `${label}: ${value.toFixed(2)}% (${proximityByDistance[context.dataIndex].holedShots
                } / ${count})`;
            }
          },
        },
      },
    },
  };

  const shortGameProximityHeatmapOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title(context) {
            const { x, y } = context[0].raw;
            return `Distance: ${x.toFixed(1)} yards, Proximity: ${y.toFixed(
              1
            )} feet`;
          },
          label(context) {
            return `Count: ${context.raw.v}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: "linear",
        min: 0,
        max: 50,
        title: {
          display: true,
          text: "Distance from Hole (yards)",
        },
        ticks: {
          stepSize: 5,
          callback: (value) => value,
        },
      },
      y: {
        type: "linear",
        min: 0,
        max: 50,
        title: {
          display: true,
          text: "Proximity to Hole (feet)",
        },
        ticks: {
          stepSize: 5,
          callback: (value) => value,
        },
      },
    },
  };

  return (
    <StatsContainer>
      <StatsHeader>Short Game Statistics</StatsHeader>

      <ChartSection>
        <ChartTitle>Club Performance</ChartTitle>
        <ClubSelection>
          <ClubIcon
            selected={selectedClub === "All"}
            onClick={() => setSelectedClub("All")}
          >
            All
          </ClubIcon>
          {Object.keys(clubPerformance).map((club) => (
            <ClubIcon
              key={club}
              selected={selectedClub === club}
              onClick={() => setSelectedClub(club)}
            >
              {club}
            </ClubIcon>
          ))}
        </ClubSelection>
        <ChartContainer>
          <ChartWrapper>
            <Bar data={clubPerformanceData} options={clubPerformanceOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Up and Down Success Rate</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Pie data={upAndDownData} options={upAndDownOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Sand Save Success Rate</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Pie data={sandSaveData} options={sandSaveOptions} />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>
          Short Game Proximity and Hole-Out Percentage by Distance
        </ChartTitle>
        <ChartContainer>
          <Bar
            data={proximityByDistanceData}
            options={proximityByDistanceOptions}
          />
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Short Game Proximity Heatmap</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Chart
              ref={chartRef}
              type="matrix"
              data={shortGameProximityHeatmapData}
              options={shortGameProximityHeatmapOptions}
            />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Short Game Proximity vs. Distance</ChartTitle>
        <ChartContainer>
          <ChartWrapper>
            <Scatter
              data={proximityScatterData}
              options={proximityScatterOptions}
            />
          </ChartWrapper>
        </ChartContainer>
      </ChartSection>

      <StatGrid>
        <StatCard>
          <StatTitle>Up and Down Percentage</StatTitle>
          <StatValue>
            {((upAndDowns.successful / upAndDowns.total) * 100).toFixed(1)}%
          </StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Sand Save Percentage</StatTitle>
          <StatValue>
            {((sandSaves.successful / sandSaves.total) * 100).toFixed(1)}%
          </StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Average Short Game Proximity</StatTitle>
          <StatValue>
            {(
              shortGameProximity.reduce(
                (sum, shot) => sum + shot.proximity,
                0
              ) / shortGameProximity.length
            ).toFixed(1)}{" "}
            feet
          </StatValue>
        </StatCard>
        <StatCard>
          <StatTitle>Short Game Scoring Average</StatTitle>
          <StatValue>{shortGameScoring.averageScore.toFixed(2)}</StatValue>
        </StatCard>
      </StatGrid>
    </StatsContainer>
  );
};

// Helper functions

const calculateSandSaves = (rounds) => {
  if (!rounds || !Array.isArray(rounds) || rounds.length === 0) {
    console.log("No valid rounds data for sand saves calculation");
    return { successful: 0, total: 0 };
  }

  let successful = 0;
  let total = 0;

  rounds.forEach((round, roundIndex) => {
    if (round && round.shots) {
      Object.entries(round.shots).forEach(([holeNumber, holeData]) => {
        if (Array.isArray(holeData.shots)) {
          for (let i = 0; i < holeData.shots.length - 1; i++) {
            const shot = holeData.shots[i];
            const nextShot = holeData.shots[i + 1];
            if (shot.type === "Bunker Shot") {
              total++;
              if (nextShot && nextShot.result === "In the Hole") {
                successful++;
                console.log(
                  `Round ${roundIndex + 1
                  }, Hole ${holeNumber}: Successful sand save`
                );
              } else {
                console.log(
                  `Round ${roundIndex + 1
                  }, Hole ${holeNumber}: Unsuccessful sand save`
                );
              }
            }
          }
        }
      });
    }
  });

  console.log(`Total sand shots: ${total}, Successful saves: ${successful}`);
  return { successful, total };
};

// 1. calculateClubPerformance
const calculateClubPerformance = (rounds) => {
  if (!rounds || rounds.length === 0) return {};

  const clubStats = {};

  rounds.forEach((round) => {
    Object.values(round.shots || {}).forEach((holeData) => {
      if (Array.isArray(holeData.shots)) {
        holeData.shots.forEach((shot) => {
          // Update: Include "Bunker Shot" type
          if (shot.category === "Short-game" || shot.type === "Bunker Shot") {
            if (!clubStats[shot.club]) {
              clubStats[shot.club] = { totalProximity: 0, count: 0 };
            }
            clubStats[shot.club].totalProximity +=
              parseFloat(shot.proximityToHole) || 0;
            clubStats[shot.club].count++;
          }
        });
      }
    });
  });

  Object.keys(clubStats).forEach((club) => {
    clubStats[club].avgProximity =
      clubStats[club].count > 0
        ? clubStats[club].totalProximity / clubStats[club].count
        : 0;
  });

  return clubStats;
};

// 2. calculateUpAndDowns
const calculateUpAndDowns = (rounds) => {
  if (!rounds || !Array.isArray(rounds) || rounds.length === 0) {
    return { successful: 0, total: 0 };
  }

  let successful = 0;
  let total = 0;

  rounds.forEach((round) => {
    if (round && round.shots) {
      Object.values(round.shots).forEach((holeData) => {
        if (Array.isArray(holeData.shots)) {
          for (let i = 0; i < holeData.shots.length; i++) {
            const shot = holeData.shots[i];
            // Update: Include "Bunker Shot" type
            if (
              shot.category === "Short-game" ||
              shot.category === "Recovery" ||
              shot.type === "Bunker Shot"
            ) {
              total++;

              if (shot.result === "In the Hole") {
                successful++;
              } else if (i + 1 < holeData.shots.length) {
                const nextShot = holeData.shots[i + 1];
                if (
                  nextShot.category === "Putt" &&
                  nextShot.result === "In the Hole"
                ) {
                  successful++;
                }
              }

              break; // Only consider the first short game, recovery, or bunker shot per hole
            }
          }
        }
      });
    }
  });

  return { successful, total };
};

// 3. calculateShortGameProximity
const calculateShortGameProximity = (rounds) => {
  if (!rounds || !Array.isArray(rounds) || rounds.length === 0) {
    return [];
  }

  const shots = [];

  rounds.forEach((round) => {
    if (round && round.shots) {
      Object.entries(round.shots).forEach(([holeNumber, holeData]) => {
        if (Array.isArray(holeData.shots)) {
          holeData.shots.forEach((shot, index) => {
            // Update: Include "Bunker Shot" type
            if (
              shot.category === "Short-game" ||
              shot.category === "Recovery" ||
              shot.type === "Bunker Shot"
            ) {
              const distance = parseFloat(shot.distance) || 0;
              const proximity = parseFloat(shot.proximityToHole) || 0;
              let result = shot.result;

              if (shot.result === "In the Hole") {
                result = "In the Hole";
              }

              if (distance <= 50) {
                shots.push({
                  holeNumber: holeNumber,
                  distance: distance,
                  proximity: proximity,
                  result: result,
                });
              }
            }
          });
        }
      });
    }
  });

  console.log("Processed short game shots:", shots);
  return shots;
};

// 4. calculateShortGameScoring
const calculateShortGameScoring = (rounds) => {
  if (!rounds || !Array.isArray(rounds) || rounds.length === 0) {
    return { averageScore: 0, totalShots: 0 };
  }

  let totalScore = 0;
  let totalShots = 0;

  rounds.forEach((round) => {
    if (round && round.shots) {
      Object.values(round.shots).forEach((holeData) => {
        if (Array.isArray(holeData.shots)) {
          const shortGameShotIndex = holeData.shots.findIndex(
            (shot) =>
              (shot.category === "Short-game" ||
                shot.category === "Recovery" ||
                shot.type === "Bunker Shot") &&
              (parseFloat(shot.distance) || 0) <= 50
          );
          if (shortGameShotIndex !== -1) {
            const shotsAfter = holeData.shots.slice(shortGameShotIndex);
            totalScore += shotsAfter.length;
            totalShots++;
          }
        }
      });
    }
  });

  return {
    averageScore: totalShots > 0 ? totalScore / totalShots : 0,
    totalShots: totalShots,
  };
};

const calculateShortGameProximityByDistance = (shots) => {
  const distanceBuckets = [
    { minDistance: 0, maxDistance: 10, label: "0-10 yards" },
    { minDistance: 10, maxDistance: 20, label: "10-20 yards" },
    { minDistance: 20, maxDistance: 30, label: "20-30 yards" },
    { minDistance: 30, maxDistance: 40, label: "30-40 yards" },
    { minDistance: 40, maxDistance: 50, label: "40-50 yards" },
  ];

  const bucketData = distanceBuckets.map((bucket) => ({
    ...bucket,
    totalProximity: 0,
    shotCount: 0,
    holedShots: 0,
  }));

  shots.forEach((shot) => {
    const bucket = bucketData.find(
      (b) => shot.distance >= b.minDistance && shot.distance < b.maxDistance
    );
    if (bucket) {
      if (shot.proximity === 0 || shot.result === "In the Hole") {
        bucket.holedShots++;
      }
      bucket.totalProximity += shot.proximity;
      bucket.shotCount++;
    }
  });

  return bucketData.map((bucket) => ({
    ...bucket,
    avgProximity:
      bucket.shotCount > 0 ? bucket.totalProximity / bucket.shotCount : 0,
    holeOutPercentage:
      bucket.shotCount > 0 ? (bucket.holedShots / bucket.shotCount) * 100 : 0,
  }));
};
const calculateShortGameProximityHeatmap = (rounds) => {
  const shots = [];

  rounds.forEach(round => {
    Object.values(round.shots || {}).forEach(hole => {
      (hole.shots || []).forEach(shot => {
        if (
          (shot.category === "Short-game" || shot.type === "Bunker Shot") &&
          typeof shot.distance === 'string' &&
          typeof shot.proximityToHole === 'string'
        ) {
          const distance = parseFloat(shot.distance);
          const proximity = parseFloat(shot.proximityToHole);

          if (!isNaN(distance) && !isNaN(proximity) && distance <= 50) {
            shots.push({ distance, proximity });
          }
        }
      });
    });
  });

  console.log('Processed short game shots:', shots);

  const heatmapData = shots.map(shot => ({
    x: shot.distance,
    y: shot.proximity,
    v: 1,
  }));

  return {
    datasets: [
      {
        label: "Short Game Proximity",
        data: heatmapData,
        backgroundColor(context) {
          const value = context.dataset.data[context.dataIndex];
          if (!value || typeof value.v === 'undefined') return 'rgba(0, 0, 0, 0)';
          const alpha = Math.min(value.v / 5, 1);
          return `rgba(75, 192, 192, ${alpha})`;
        },
        width: ({ chart }) => (chart.chartArea || {}).width / 51,
        height: ({ chart }) => (chart.chartArea || {}).height / 51,
      },
    ],
  };
};

export default ShortGameStats;

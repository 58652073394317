import React, { useState, useMemo } from "react";
import styled from "@emotion/styled";
import PuttingStats from "../components/PuttingStats";
import useFetchRounds from "../hooks/useFetchRounds";
import ApproachStats from "../components/ApproachStats";
import ShortGameStats from "../components/ShortGameStats";
import DrivingStats from "../components/DrivingStats";
import PerformanceMetrics from "../components/PerformanceMetrics";
import DashboardButton from "../components/DashboardButton";
import { Container, Title, Button } from "../styles";
import ClubDistances from "../components/ClubDistances";


const DashboardContainer = styled(Container)`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
`;

const NavigationBar = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const NavButton = styled(Button)`
  background-color: ${(props) => (props.active ? props.theme.accentColor : props.theme.primaryColor)};
  color: ${props => props.theme.textColor};
  
  &:hover {
    background-color: ${props => props.theme.accentColor};
  }
`;

const RoundSelector = styled.select`
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: ${props => props.theme.secondaryColor};
  color: ${props => props.theme.textColor};
`;

const MetricsDashboard = () => {
  const [activeTab, setActiveTab] = useState("overall");
  const [selectedRound, setSelectedRound] = useState("overall");
  const { rounds, loading, error } = useFetchRounds();

  const selectedRounds = useMemo(() => {
    return selectedRound === "overall" ? rounds : [rounds[parseInt(selectedRound)]];
  }, [rounds, selectedRound]);

  if (loading) return <DashboardContainer>Loading dashboard...</DashboardContainer>;
  if (error) return <DashboardContainer>Error: {error}</DashboardContainer>;

  return (
    <DashboardContainer>
      <DashboardButton />
      <Title>Metrics Dashboard</Title>

      <RoundSelector
        value={selectedRound}
        onChange={(e) => setSelectedRound(e.target.value)}
      >
        <option value="overall">All Time</option>
        {rounds.map((round, index) => (
          <option key={index} value={index}>
            Round {index + 1} - {new Date(round.date).toLocaleDateString()}
          </option>
        ))}
      </RoundSelector>

      <NavigationBar>
        {["overall", "driving", "approach", "short-game", "putting", "club-stats"].map((tab) => (
          <NavButton
            key={tab}
            onClick={() => setActiveTab(tab)}
            active={activeTab === tab}
          >
            {tab.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </NavButton>
        ))}
      </NavigationBar>

      {activeTab === "overall" && <PerformanceMetrics rounds={selectedRounds} />}
      {activeTab === "driving" && <DrivingStats rounds={selectedRounds} />}
      {activeTab === "putting" && <PuttingStats rounds={selectedRounds} />}
      {activeTab === "approach" && <ApproachStats rounds={selectedRounds} />}
      {activeTab === "short-game" && <ShortGameStats rounds={selectedRounds} />}
      {activeTab === "club-stats" && <ClubDistances rounds={selectedRounds} isAllTime={selectedRound === "overall"} />}
    </DashboardContainer>
  );
};

export default MetricsDashboard;
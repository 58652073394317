// utils/ScoreCalculator.js
import { db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";

export const fetchPlayerHandicap = async (userId) => {
    if (!userId) {
        console.warn("No userId provided for fetchPlayerHandicap");
        return 0;
    }
    try {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
            return userDoc.data().handicap || 0;
        } else {
            console.warn("User document not found");
            return 0;
        }
    } catch (error) {
        console.error("Error fetching player handicap:", error);
        return 0;
    }
};

export const calculateAllScores = async (roundData, userId) => {
    try {
        const playerHandicap = userId ? await fetchPlayerHandicap(userId) : 0;

        // Ensure roundData has the necessary properties
        if (!roundData || !roundData.tee || !roundData.shots) {
            throw new Error("Invalid roundData structure");
        }

        const teeData = roundData.tees ? roundData.tees.find(tee => tee.color === roundData.tee) : null;
        const slopeRating = teeData ? teeData.slope : 113;
        const courseRating = teeData ? teeData.rating : 72;

        // Calculate gross score
        const grossScore = Object.values(roundData.shots).reduce((total, hole) => total + hole.shots.length, 0);

        // Calculate course handicap
        const coursePar = roundData.holes ? roundData.holes.reduce((total, hole) => total + (hole.pars[roundData.tee] || 0), 0) : 72;
        const courseHandicap = Math.round((playerHandicap * (slopeRating / 113)) + (courseRating - coursePar));

        // Calculate net score
        const netScore = grossScore - courseHandicap;

        // Calculate score differential
        const scoreDifferential = Number(((113 / slopeRating) * (grossScore - courseRating)).toFixed(1));

        return {
            playerHandicap,
            courseHandicap,
            grossScore,
            netScore,
            scoreDifferential
        };
    } catch (error) {
        console.error("Error in calculateAllScores:", error);
        throw error;
    }
};
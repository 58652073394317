import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import { calculateAllScores } from "../utils/ScoreCalculator";
import { useAuth } from "../context/AuthContext";
import DashboardButton from "../components/DashboardButton";

const SummaryContainer = styled.div`
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: auto;

  @media (min-width: 768px) {
    padding: 20px;
    max-width: 900px;
  }
`;

const SectionTitle = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.2rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;

const TableCell = styled.td`
  padding: 4px;
  text-align: center;
  border: 1px solid #ddd;
  color: #333;
  font-size: 12px;

  @media (min-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const TableHeader = styled.th`
  background-color: #f0f0f0;
  padding: 4px;
  text-align: center;
  border: 1px solid #ddd;
  font-weight: bold;
  color: #333;
  font-size: 12px;

  @media (min-width: 768px) {
    padding: 8px;
    font-size: 14px;
  }
`;

const ScorecardTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (min-width: 768px) {
    margin-bottom: 30px;
  }
`;

const TableCellYard = styled(TableCell)`
  font-weight: bold;
`;

const ScoreCell = styled(TableCell)`
  background-color: ${(props) => {
    if (props.score <= props.par - 2) return "#FFD700";
    if (props.score === props.par - 1) return "#FF4136";
    if (props.score === props.par) return "transparent";
    if (props.score === props.par + 1) return "#0074D9";
    if (props.score >= props.par + 2) return "#B10DC9";
    return "transparent";
  }};
  color: ${(props) => {
    if (props.score === props.par) return "black";
    if (props.score <= props.par - 2) return "black";
    return "white";
  }};
  font-weight: bold;
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 30px;
  }
`;

const StatBox = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(50% - 10px);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  @media (min-width: 768px) {
    width: calc(25% - 20px);
    padding: 15px;
    margin: 10px;
  }
`;

const StatValue = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const StatLabel = styled.div`
  font-size: 12px;
  color: #666;

  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const CourseName = styled.h3`
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
`;

const TeeInfo = styled.p`
  color: #666;
  text-align: center;
  margin-bottom: 15px;
  font-size: 0.9rem;

  @media (min-width: 768px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
`;

const TotalColumn = styled(TableCell)`
  font-weight: bold;
  background-color: "white";

  @media (max-width: 767px) {
    display: none;
  }
`;

const SlopeInfo = styled.p`
  color: #666;
  text-align: center;
  margin-bottom: 15px;
  font-size: 0.9rem;

  @media (min-width: 768px) {
    font-size: 1rem;
    margin-bottom: 20px;
  }
`;

const RoundSummary = ({ roundData }) => {
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [calculatedScores, setCalculatedScores] = useState({});
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchDataAndCalculateScores = async () => {
      try {
        // Fetch course data
        console.log("Fetching course data for ID:", roundData.courseId);
        const courseDoc = await getDoc(doc(db, "courses", roundData.courseId));
        if (courseDoc.exists()) {
          console.log("Course data fetched successfully:", courseDoc.data());
          setCourseData(courseDoc.data());
        } else {
          throw new Error("Course data not found");
        }

        // Calculate scores only if we have a user
        if (currentUser) {
          if (
            roundData.grossScore &&
            roundData.netScore &&
            roundData.scoreDifferential
          ) {
            setCalculatedScores({
              grossScore: roundData.grossScore,
              netScore: roundData.netScore,
              scoreDifferential: roundData.scoreDifferential,
            });
          } else {
            const scores = await calculateAllScores(roundData, currentUser.uid);
            setCalculatedScores(scores);
          }
        } else {
          console.warn("User not available, skipping score calculation");
        }
      } catch (err) {
        console.error("Error in data fetching or calculation:", err);
        setError(
          err.message || "An error occurred while preparing the round summary"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndCalculateScores();
  }, [roundData, currentUser]);

  if (loading) return <div>Loading course data...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!courseData) return <div>No course data available.</div>;

  const holeNumbers = Object.keys(roundData.shots).sort(
    (a, b) => Number(a) - Number(b)
  );

  const getPar = (hole) => {
    const holeData = courseData.holes[Number(hole) - 1];
    return holeData?.pars[roundData.tee] || 0;
  };

  const getYardage = (hole) => {
    const holeData = courseData.holes[Number(hole) - 1];
    return holeData?.yardages[roundData.tee] || 0;
  };

  const getStrokeIndex = (hole) => {
    const holeData = courseData.holes[Number(hole) - 1];
    return holeData?.strokeIndexMen || 0;
  };

  const getFIR = (hole) => {
    const par = getPar(hole);
    if (par === 3) return null; // No FIR for par 3s
    return roundData.shots[hole].shots.some(
      (shot) =>
        shot.category === "Tee-shot" &&
        (shot.result === "Fairway" ||
          shot.result === "Green" ||
          shot.result === "In the Hole")
    );
  };

  const getGIR = (hole) => {
    const par = getPar(hole);
    return roundData.shots[hole].shots.some(
      (shot) =>
        (shot.result === "Green" && shot.shotNumber <= par - 2) ||
        (shot.result === "In the Hole" && shot.shotNumber <= par)
    );
  };

  const getPutts = (hole) => {
    return roundData.shots[hole].shots.filter((shot) => shot.type === "Putt")
      .length;
  };

  const calculateTotals = (start, end) => {
    const totalPar = holeNumbers
      .slice(start, end)
      .reduce((sum, hole) => sum + getPar(hole), 0);

    const totalYardage = holeNumbers
      .slice(start, end)
      .reduce((sum, hole) => sum + getYardage(hole), 0);

    const totalScore = holeNumbers
      .slice(start, end)
      .reduce((sum, hole) => sum + roundData.shots[hole].shots.length, 0);

    const totalFIR = holeNumbers
      .slice(start, end)
      .filter((hole) => getFIR(hole) === true).length;

    const totalGIR = holeNumbers
      .slice(start, end)
      .filter((hole) => getGIR(hole)).length;

    const totalPutts = holeNumbers
      .slice(start, end)
      .reduce((sum, hole) => sum + getPutts(hole), 0);

    return {
      totalPar,
      totalYardage,
      totalScore,
      totalFIR,
      totalGIR,
      totalPutts,
    };
  };

  const renderScorecardSection = (start, end) => {
    const {
      totalPar,
      totalYardage,
      totalScore,
      totalFIR,
      totalGIR,
      totalPutts,
    } = calculateTotals(start, end);

    return (
      <ScorecardTable>
        <thead>
          <tr>
            <TableHeader>Hole</TableHeader>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableHeader key={hole}>{hole}</TableHeader>
            ))}
            <TotalColumn as="th">Total</TotalColumn>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>Par</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableCell key={hole}>{getPar(hole)}</TableCell>
            ))}
            <TotalColumn>{totalPar}</TotalColumn>
          </tr>
          <tr>
            <TableCell>Yardage</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableCellYard key={hole}>{getYardage(hole)}</TableCellYard>
            ))}
            <TotalColumn>{totalYardage}</TotalColumn>
          </tr>
          <tr>
            <TableCell>SI</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableCell style={{ color: "red" }} key={hole}>
                {getStrokeIndex(hole)}
              </TableCell>
            ))}
            <TotalColumn>-</TotalColumn>
          </tr>
          <tr>
            <TableCell>Score</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <ScoreCell
                key={hole}
                score={roundData.shots[hole].shots.length}
                par={getPar(hole)}
              >
                {roundData.shots[hole].shots.length}
              </ScoreCell>
            ))}
            <TotalColumn>{totalScore}</TotalColumn>
          </tr>
          <tr>
            <TableCell>FIR</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableCell key={hole}>
                {getFIR(hole) === null ? "-" : getFIR(hole) ? "✓" : "✗"}
              </TableCell>
            ))}
            <TotalColumn>{totalFIR}</TotalColumn>
          </tr>
          <tr>
            <TableCell>GIR</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableCell key={hole}>{getGIR(hole) ? "✓" : "✗"}</TableCell>
            ))}
            <TotalColumn>{totalGIR}</TotalColumn>
          </tr>
          <tr>
            <TableCell>Putts</TableCell>
            {holeNumbers.slice(start, end).map((hole) => (
              <TableCell key={hole}>{getPutts(hole)}</TableCell>
            ))}
            <TotalColumn>{totalPutts}</TotalColumn>
          </tr>
        </tbody>
      </ScorecardTable>
    );
  };

  const { totalScore: frontNineTotal } = calculateTotals(0, 9);
  const { totalScore: backNineTotal } = calculateTotals(9, 18);

  const getTeeData = () => {
    return courseData.tees.find((tee) => tee.color === roundData.tee) || {};
  };

  const teeData = getTeeData();

  const totalScore = holeNumbers.reduce(
    (sum, hole) => sum + roundData.shots[hole].shots.length,
    0
  );
  const totalPutts = holeNumbers.reduce(
    (sum, hole) =>
      sum +
      roundData.shots[hole].shots.filter((shot) => shot.type === "Putt").length,
    0
  );

  const fairwaysHit = holeNumbers.filter((hole) => {
    const par = getPar(hole);
    if (par === 3) return false; // Exclude par 3's
    return getFIR(hole);
  }).length;

  const totalNonPar3s = holeNumbers.filter((hole) => getPar(hole) !== 3).length;

  const greensInRegulation = holeNumbers.filter((hole) => getGIR(hole)).length;

  return (
    <SummaryContainer>
      <CourseName>{roundData.courseName}</CourseName>
      <TeeInfo>Tee Played: {roundData.tee}</TeeInfo>
      <SlopeInfo>
        Slope Rating: {teeData.slope || "N/A"} | Course Rating:{" "}
        {teeData.rating || "N/A"}
      </SlopeInfo>

      <SectionTitle>Front Nine</SectionTitle>
      {renderScorecardSection(0, 9)}

      <SectionTitle>Back Nine</SectionTitle>
      {renderScorecardSection(9, 18)}

      <StatsContainer>
        <StatBox>
          <StatValue>
            {calculatedScores.grossScore || roundData.grossScore || "N/A"}
          </StatValue>
          <StatLabel>Gross Score</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>
            {calculatedScores.netScore || roundData.netScore || "N/A"}
          </StatValue>
          <StatLabel>Net Score</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>
            {(() => {
              const scoreDiff = calculatedScores.scoreDifferential || roundData.scoreDifferential;
              if (typeof scoreDiff === 'number') {
                return scoreDiff.toFixed(1);
              } else if (scoreDiff) {
                return scoreDiff.toString();
              } else {
                return "N/A";
              }
            })()}
          </StatValue>
          <StatLabel>Score Differential</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>{frontNineTotal}</StatValue>
          <StatLabel>Front 9</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>{backNineTotal}</StatValue>
          <StatLabel>Back 9</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>{totalPutts}</StatValue>
          <StatLabel>Total Putts</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>{`${fairwaysHit}/${totalNonPar3s}`}</StatValue>
          <StatLabel>Fairways Hit</StatLabel>
        </StatBox>
        <StatBox>
          <StatValue>{greensInRegulation}</StatValue>
          <StatLabel>Greens in Regulation</StatLabel>
        </StatBox>
      </StatsContainer>
    </SummaryContainer>
  );
};

export default RoundSummary;

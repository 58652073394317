import React, { useMemo } from "react";
import styled from "@emotion/styled";
import PerformanceTrendsChart from "./PerformanceTrendsChart";
import { theme, breakpoints, ChartContainer, ChartSection, ChartTitle } from "../styles";

import ImportanceOfScoringCircles from "./ImportanceOfScoringCircles";
import OptimalScoringCombinations from "./OptimalScoringCombinations";
import ScoringDistributionChart from './ScoringDistributionChart';
import StrokesGainedComponent from "./StrokesGainedComponent";

import StrokesGainedUtil from "../utils/StrokesGainedUtil";
import OverallPerformanceScore from "./OverallPerformanceScore";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
} from "chart.js";

// Register Chart.js components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement
);

// Styled components
const StatsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StatsHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;



const StyledStatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 2rem;
`;

const StyledStatCard = styled.div`
  background-color: ${theme.secondaryColor};
  color: ${theme.textColor};
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const StyledStatTitle = styled.h4`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: ${theme.accentColor};
`;

const StyledStatValue = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
`;

const StyledRecommendationsSection = styled.div`
  background-color: ${theme.secondaryColor};
  color: ${theme.textColor};
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
`;

const StyledRecommendationTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: ${theme.accentColor};

  @media (min-width: ${breakpoints.medium}) {
    font-size: 1.5rem;
  }
`;

const StyledRecommendationList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const StyledRecommendationItem = styled.li`
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: ${theme.primaryColor};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${theme.accentColor};
  }
`;

const PerformanceMetrics = ({ rounds }) => {
  const performanceData = useMemo(() => {
    if (!rounds || rounds.length === 0) return null;

    console.log("Calculating performance data for rounds:", rounds);

    const strokesGainedPerformance = StrokesGainedUtil.calculateOverallPerformance(rounds);
    console.log("Strokes Gained Performance:", strokesGainedPerformance);

    // Calculate total strokes gained per round
    const totalStrokesGained = Object.values(strokesGainedPerformance.aspectBreakdown)
      .reduce((sum, aspect) => sum + (aspect.averageStrokesGained || 0), 0);

    const fairwaysHit = calculateFairwaysHit(rounds);
    const greensInRegulation = calculateGreensInRegulation(rounds);
    const averagePutts = calculateAveragePutts(rounds);
    const scoringDistribution = calculateScoringDistribution(rounds);
    const trends = calculateTrends(rounds);
    const rawImportanceOfScoring = calculateImportanceOfScoring(strokesGainedPerformance.aspectBreakdown);

    // Format importanceOfScoring for the component
    const importanceOfScoring = {
      driving: rawImportanceOfScoring["Tee-shot"] || 0,
      approach: rawImportanceOfScoring["Approach"] || 0,
      shortGame: rawImportanceOfScoring["Short-game"] || 0,
      putting: rawImportanceOfScoring["Putt"] || 0
    };

    console.log("Calculated Importance of Scoring:", importanceOfScoring);

    return {
      strokesGained: strokesGainedPerformance,
      totalStrokesGained,
      fairwaysHitPercentage: fairwaysHit.percentage,
      greensInRegulation,
      averagePuttsPerRound: averagePutts,
      scoringDistribution,
      trends,
      importanceOfScoring,
    };
  }, [rounds]);

  console.log("Performance Data:", performanceData);

  if (!performanceData) {
    return <div>No Performance Data available, play your first round to gain insights</div>;
  }

  const {
    strokesGained,
    fairwaysHitPercentage,
    greensInRegulation,
    averagePuttsPerRound,
    scoringDistribution,
    trends,
    importanceOfScoring
  } = performanceData;

  const overallScore = calculateOverallScore(strokesGained);
  const weakestLink = identifyWeakestLink(strokesGained.aspectBreakdown);
  const improvementRecommendations = generateImprovementRecommendations(strokesGained.aspectBreakdown, weakestLink);

  const strokesGainedData = {
    driving: strokesGained.aspectBreakdown["Tee-shot"]?.strokesGainedPerRound || 0,
    approach: strokesGained.aspectBreakdown["Approach"]?.strokesGainedPerRound || 0,
    short: strokesGained.aspectBreakdown["Short-game"]?.strokesGainedPerRound || 0,
    putting: strokesGained.aspectBreakdown["Putt"]?.strokesGainedPerRound || 0,
  };

  console.log("Strokes Gained Data (per round):", strokesGainedData);
  console.log("Importance of Scoring:", importanceOfScoring);

  const scoringDistributionData = [
    { id: "Eagles", label: "Eagles", value: scoringDistribution.eagles },
    { id: "Birdies", label: "Birdies", value: scoringDistribution.birdies },
    { id: "Pars", label: "Pars", value: scoringDistribution.pars },
    { id: "Bogeys", label: "Bogeys", value: scoringDistribution.bogeys },
    { id: "Double Bogeys", label: "Double Bogeys", value: scoringDistribution.doubleBogeys },
    { id: "Others", label: "Others", value: scoringDistribution.others },
  ];

  const trendsData = [
    {
      id: "Score",
      data: trends.map((t, i) => ({ x: i + 1, y: t.score })),
    },
    {
      id: "Fairways Hit %",
      data: trends.map((t, i) => ({ x: i + 1, y: t.fairwaysHitPercentage })),
    },
    {
      id: "Greens in Regulation %",
      data: trends.map((t, i) => ({ x: i + 1, y: t.greensInRegulationPercentage })),
    },
    {
      id: "Average Putts",
      data: trends.map((t, i) => ({ x: i + 1, y: t.averagePutts })),
    },
  ];

  return (
    <StatsContainer>
      <StatsHeader>Overall Performance Metrics</StatsHeader>

      <ChartSection>
        <ChartContainer>
          <OverallPerformanceScore overallScore={overallScore} />
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartTitle>Importance of Scoring</ChartTitle>
        <ChartContainer>
          <ImportanceOfScoringCircles importanceOfScoring={importanceOfScoring} />
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <StrokesGainedComponent data={strokesGainedData} />
      </ChartSection>

      <ChartSection>
        <ChartTitle>Scoring Distribution</ChartTitle>
        <ChartContainer>
          <ScoringDistributionChart scoringDistribution={scoringDistribution} />
        </ChartContainer>
      </ChartSection>

      <ChartSection>
        <ChartContainer>
          <PerformanceTrendsChart trendsData={trendsData} />
        </ChartContainer>
      </ChartSection>

      <StyledStatGrid>
        <StyledStatCard>
          <StyledStatTitle>Fairways Hit</StyledStatTitle>
          <StyledStatValue>{fairwaysHitPercentage.toFixed(1)}%</StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>Greens in Regulation</StyledStatTitle>
          <StyledStatValue>{greensInRegulation.percentage.toFixed(1)}%</StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>Average Putts per Round</StyledStatTitle>
          <StyledStatValue>{averagePuttsPerRound.toFixed(1)}</StyledStatValue>
        </StyledStatCard>
        <StyledStatCard>
          <StyledStatTitle>Total Strokes Gained per Round</StyledStatTitle>
          <StyledStatValue>{performanceData.totalStrokesGained.toFixed(2)}</StyledStatValue>
        </StyledStatCard>
      </StyledStatGrid>

      <StyledRecommendationsSection>
        <StyledRecommendationTitle>Improvement Recommendations</StyledRecommendationTitle>
        <StyledRecommendationList>
          {improvementRecommendations.map((rec, index) => (
            <StyledRecommendationItem key={index}>{rec}</StyledRecommendationItem>
          ))}
        </StyledRecommendationList>
      </StyledRecommendationsSection>
    </StatsContainer>
  );
};
// Helper functions
const calculateOverallScore = (strokesGained) => {
  const convertToScore = (sg) => Math.min(100, Math.max(0, (sg + 3) * 20));

  const aspectScores = {
    driving: convertToScore(strokesGained.aspectBreakdown["Tee-shot"].averageStrokesGained),
    approach: convertToScore(strokesGained.aspectBreakdown["Approach"].averageStrokesGained),
    shortGame: convertToScore(strokesGained.aspectBreakdown["Short-game"].averageStrokesGained),
    putting: convertToScore(strokesGained.aspectBreakdown["Putt"].averageStrokesGained),
  };

  const total = Object.values(aspectScores).reduce((sum, score) => sum + score, 0) / 4;

  return {
    ...aspectScores,
    total: Math.round(total)

  };
};


const calculateFairwaysHit = (rounds) => {
  let totalFairways = 0;
  let fairwaysHit = 0;

  rounds.forEach(round => {
    Object.values(round.shots).forEach(hole => {
      if (hole.par > 3) {
        totalFairways++;
        const teeShot = hole.shots.find(shot => shot.category === "Tee-shot");
        if (teeShot && teeShot.result === "Fairway") {
          fairwaysHit++;
        }
      }
    });
  });

  return {
    hit: fairwaysHit,
    total: totalFairways,
    percentage: (fairwaysHit / totalFairways) * 100
  };
};

const calculateImportanceOfScoring = (aspectBreakdown) => {
  const aspects = ["Tee-shot", "Approach", "Short-game", "Putt"];
  const totalAbsStrokesGained = aspects.reduce(
    (sum, aspect) => sum + Math.abs(aspectBreakdown[aspect]?.averageStrokesGained || 0),
    0
  );

  const importance = {};
  aspects.forEach(aspect => {
    const strokesGained = Math.abs(aspectBreakdown[aspect]?.averageStrokesGained || 0);
    importance[aspect] = totalAbsStrokesGained > 0
      ? (strokesGained / totalAbsStrokesGained) * 100
      : 25; // If totalAbsStrokesGained is 0, distribute evenly
  });

  return importance;
};


const calculateGreensInRegulation = (rounds) => {
  let hit = 0;
  let total = 0;

  rounds.forEach((round) => {
    Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
      const approachShot = holeData.shots.find(
        (shot) => shot.category === "Approach"
      );
      if (approachShot) {
        total++;
        if (approachShot.result === "Green") {
          hit++;
        }
      }
    });
  });

  return {
    hit,
    total,
    percentage: total > 0 ? (hit / total) * 100 : 0
  };
};

const calculateAveragePutts = (rounds) => {
  let totalPutts = 0;
  let totalHoles = 0;

  rounds.forEach(round => {
    Object.values(round.shots).forEach(hole => {
      totalHoles++;
      const putts = hole.shots.filter(shot => shot.category === "Putt");
      totalPutts += putts.length;
    });
  });

  return totalPutts / totalHoles;
};

const calculateScoringDistribution = (rounds) => {
  const distribution = {
    eagles: 0,
    birdies: 0,
    pars: 0,
    bogeys: 0,
    doubleBogeys: 0,
    others: 0
  };

  rounds.forEach(round => {
    Object.values(round.shots).forEach(hole => {
      const score = hole.shots.length - hole.par;
      if (score <= -2) distribution.eagles++;
      else if (score === -1) distribution.birdies++;
      else if (score === 0) distribution.pars++;
      else if (score === 1) distribution.bogeys++;
      else if (score === 2) distribution.doubleBogeys++;
      else distribution.others++;
    });
  });

  return distribution;
};

const calculateTrends = (rounds) => {
  return rounds.map((round, index) => {
    const totalScore = Object.values(round.shots).reduce((sum, hole) => sum + hole.shots.length, 0);
    const fairwaysHit = calculateFairwaysHit([round]);
    const greensInRegulation = calculateGreensInRegulation([round]);
    const averagePutts = calculateAveragePutts([round]);

    return {
      round: index + 1,
      score: totalScore,
      fairwaysHitPercentage: fairwaysHit.percentage,
      greensInRegulationPercentage: greensInRegulation.percentage,
      averagePutts: averagePutts
    };
  });
};

const identifyWeakestLink = (aspectBreakdown) => {
  return Object.entries(aspectBreakdown).reduce((weakest, [aspect, data]) =>
    data.averageStrokesGained < weakest.averageStrokesGained ? { aspect, ...data } : weakest
    , { aspect: '', averageStrokesGained: Infinity }).aspect;
};

const generateImprovementRecommendations = (aspectBreakdown, weakestLink) => {
  const recommendations = [
    `Focus on improving your ${weakestLink} to gain the most strokes.`
  ];

  Object.entries(aspectBreakdown).forEach(([aspect, data]) => {
    if (data.averageStrokesGained < 0) {
      recommendations.push(`Work on your ${aspect} to improve from ${data.averageStrokesGained.toFixed(2)} strokes lost per round.`);
    }
  });

  return recommendations;
};



export default PerformanceMetrics;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button } from "../styles";
import { ArrowLeft } from 'lucide-react';

const StyledButton = styled(Button)`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    padding: 0;
    background-color: ${props => props.theme.accentColor};

    &:hover {
      background-color: ${props => props.theme.primaryColor};
    }

    span {
      display: none;
    }
  }
`;

const ArrowIcon = styled(ArrowLeft)`
  display: none;

  @media (max-width: 768px) {
    display: block;
    width: 24px;
    height: 24px;
  }
`;

const DashboardButton = () => {
    const navigate = useNavigate();

    return (
        <StyledButton onClick={() => navigate('/dashboard')}>
            <ArrowIcon />
            <span>Back to Dashboard</span>
        </StyledButton>
    );
};

export default DashboardButton;
import React from 'react';
import styled from '@emotion/styled';

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ScoreTitle = styled.h3`
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
`;

const GaugeContainer = styled.div`
  position: relative;
  width: 200px;
  height: 100px;
  overflow: hidden;
  margin-top: 20px;

  @media (max-width: 600px) {
    width: 150px;
    height: 75px;
  }
`;

const GaugeBackground = styled.div`
  width: 200px;
  height: 200px;
  border: 20px solid #f0f0f0;
  border-bottom-color: transparent;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: 600px) {
    width: 150px;
    height: 150px;
    border-width: 15px;
  }
`;

const GaugeFill = styled.div`
  width: 200px;
  height: 200px;
  border: 20px solid ${props => props.color};
  border-bottom-color: transparent;
  border-radius: 50%;
  transform: rotate(${props => props.rotation}deg);
  position: absolute;
  bottom: 0;
  left: 0;
  transition: transform 1s ease-out;
  

  @media (max-width: 600px) {
    
    width: 150px;
    height: 150px;
    border-width: 15px;
  }
`;

const ScoreValue = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: #333;

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const AspectScores = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 20px;
  border-top: 1px solid #f0f0f0;
  padding-top: 20px;

  @media (max-width: 780px) {
    flex-direction: column;
  }
`;

const AspectScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AspectLabel = styled.span`
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 5px;

  @media (max-width: 780px) {
    font-size: 1rem;
  }
`;

const AspectValue = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: ${props => props.color};

  @media (max-width: 780px) {
    font-size: 1.5rem;
  }
`;

const OverallPerformanceScore = ({ overallScore }) => {
    const { total, driving, approach, shortGame, putting } = overallScore;

    const getColor = (score) => {
        if (score >= 80) return '#34D399';
        if (score >= 60) return '#FBBF24';
        if (score >= 40) return '#F87171';
        return '#EF4444';
    };

    const rotation = (total / 100) * 180;
    const color = getColor(total);

    return (
        <ScoreContainer>
            <ScoreTitle>Overall Performance Score</ScoreTitle>
            <GaugeContainer>
                <GaugeBackground />
                <GaugeFill color={color} rotation={rotation} />
                <ScoreValue>{total.toFixed(1)}</ScoreValue>
            </GaugeContainer>
            <AspectScores>
                <AspectScore>
                    <AspectLabel>Driving</AspectLabel>
                    <AspectValue color={getColor(driving)}>{driving.toFixed(1)}</AspectValue>
                </AspectScore>
                <AspectScore>
                    <AspectLabel>Approach</AspectLabel>
                    <AspectValue color={getColor(approach)}>{approach.toFixed(1)}</AspectValue>
                </AspectScore>
                <AspectScore>
                    <AspectLabel>Short Game</AspectLabel>
                    <AspectValue color={getColor(shortGame)}>{shortGame.toFixed(1)}</AspectValue>
                </AspectScore>
                <AspectScore>
                    <AspectLabel>Putting</AspectLabel>
                    <AspectValue color={getColor(putting)}>{putting.toFixed(1)}</AspectValue>
                </AspectScore>
            </AspectScores>
        </ScoreContainer>
    );
};

export default OverallPerformanceScore;
import { doc, setDoc, getDoc, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../services/firebase';

export const processClubDistances = async (userId, roundId, clubDistances) => {
    if (!userId || !roundId) {
        console.error("User ID and Round ID are required");
        throw new Error("User ID and Round ID are required");
    }

    if (!clubDistances || Object.keys(clubDistances).length === 0) {
        console.error("No club distances data provided");
        throw new Error("Club distances data is required");
    }

    try {
        console.log("Processing club distances for user:", userId, "round:", roundId);

        // Update all-time averages
        const clubDistancesDocRef = doc(db, "clubDistances", userId);
        const existingDoc = await getDoc(clubDistancesDocRef);
        const existingData = existingDoc.exists() ? existingDoc.data() : {};

        let updatedClubData = {};

        for (const [club, distances] of Object.entries(clubDistances)) {
            const existingClubData = existingData[club] || { totalDistance: 0, shotCount: 0 };
            const newTotalDistance = existingClubData.totalDistance + distances.reduce((sum, d) => sum + d.distance, 0);
            const newShotCount = existingClubData.shotCount + distances.length;

            updatedClubData[club] = {
                totalDistance: newTotalDistance,
                shotCount: newShotCount,
                averageDistance: Math.round(newTotalDistance / newShotCount)
            };
        }

        await setDoc(clubDistancesDocRef, {
            ...updatedClubData,
            lastUpdated: serverTimestamp()
        }, { merge: true });

        // Store round-specific data
        const roundDistancesRef = collection(db, "users", userId, "roundDistances");
        await addDoc(roundDistancesRef, {
            roundId: roundId,
            clubDistances: clubDistances,
            date: serverTimestamp()
        });

        console.log("Club distances processed successfully");
    } catch (error) {
        console.error("Error processing club distances:", error);
        throw error;
    }
};
const StrokesGainedUtil = {
  calculateOverallPerformance: (rounds) => {
    const aspects = ["Tee-shot", "Approach", "Short-game", "Putt"];
    const performance = {
      totalStrokesGained: 0,
      aspectBreakdown: {},
      totalRounds: rounds.length,
    };

    aspects.forEach((aspect) => {
      const aspectPerformance =
        StrokesGainedUtil[`calculate${aspect.replace("-", "")}StrokesGained`](
          rounds
        );

      console.log(`${aspect} total strokes gained:`, aspectPerformance.totalStrokesGained);
      console.log(`${aspect} total shots:`, aspectPerformance.totalShots);

      performance.aspectBreakdown[aspect] = {
        ...aspectPerformance,
        strokesGainedPerRound: aspectPerformance.totalStrokesGained / performance.totalRounds
      };

      performance.totalStrokesGained += aspectPerformance.totalStrokesGained;
    });

    performance.strokesGainedPerRound = performance.totalStrokesGained / performance.totalRounds;

    console.log("Final performance object:", performance);

    return performance;
  },

  calculateTeeshotStrokesGained: (rounds) => {
    let totalStrokesGained = 0;
    let totalShots = 0;

    rounds.forEach((round, roundIndex) => {
      Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
        const teeShot = holeData.shots.find(
          (shot) => shot.category === "Tee-shot"
        );
        if (teeShot) {
          totalShots++;
          const distance = parseFloat(teeShot.distance);
          const expectedBefore = StrokesGainedUtil.getExpectedStrokesForTeeShot(
            distance,
            holeData.par
          );
          const expectedAfter =
            StrokesGainedUtil.getExpectedStrokesAfterTeeShot(
              teeShot,
              holeData.shots[1]
            );

          const strokesGained = expectedBefore - expectedAfter - 1;
          totalStrokesGained += strokesGained;

          console.log(`Round ${roundIndex + 1}, Hole ${holeNumber}, Tee shot:`);
          console.log(
            `  Distance: ${distance} yards, Result: ${teeShot.result}`
          );
          console.log(
            `  Expected before: ${expectedBefore.toFixed(
              2
            )}, Expected after: ${expectedAfter.toFixed(2)}`
          );
          console.log(`  Strokes gained: ${strokesGained.toFixed(2)}`);
        }
      });
    });

    return {
      totalStrokesGained,
      totalShots,
      averageStrokesGained:
        totalShots > 0 ? totalStrokesGained / totalShots : 0,
    };
  },

  calculateApproachStrokesGained: (rounds) => {
    let totalStrokesGained = 0;
    let totalShots = 0;

    rounds.forEach((round, roundIndex) => {
      Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
        holeData.shots.forEach((shot, shotIndex) => {
          if (shot.category === "Approach") {
            totalShots++;
            const distance = parseFloat(shot.distance);
            const startingLie =
              shotIndex > 0
                ? holeData.shots[shotIndex - 1].result.toLowerCase()
                : "fairway";
            const expectedBefore =
              StrokesGainedUtil.getExpectedStrokesForApproach(
                distance,
                startingLie
              );

            let expectedAfter;
            if (shot.result.toLowerCase() === "green") {
              expectedAfter = StrokesGainedUtil.getExpectedStrokesFromGreen(
                parseFloat(shot.proximityToHole)
              );
            } else {
              const nextShot = holeData.shots[shotIndex + 1];
              expectedAfter = nextShot
                ? StrokesGainedUtil.getExpectedStrokesForApproach(
                  parseFloat(nextShot.distance),
                  shot.result.toLowerCase()
                )
                : 3.0;
            }

            const strokesGained = expectedBefore - expectedAfter - 1;
            totalStrokesGained += strokesGained;

            console.log(
              `Round ${roundIndex + 1}, Hole ${holeNumber}, Approach shot:`
            );
            console.log(
              `  Distance: ${distance} yards, Result: ${shot.result}`
            );
            console.log(
              `  Expected before: ${expectedBefore.toFixed(
                2
              )}, Expected after: ${expectedAfter.toFixed(2)}`
            );
            console.log(`  Strokes gained: ${strokesGained.toFixed(2)}`);
          }
        });
      });
    });

    return {
      totalStrokesGained,
      totalShots,
      averageStrokesGained:
        totalShots > 0 ? totalStrokesGained / totalShots : 0,
    };
  },

  calculateShortgameStrokesGained: (rounds) => {
    let totalStrokesGained = 0;
    let totalShots = 0;

    rounds.forEach((round, roundIndex) => {
      Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
        holeData.shots.forEach((shot, shotIndex) => {
          if (shot.category === "Short-game") {
            totalShots++;
            const distance = parseFloat(shot.distance) || 0;
            const expectedBefore = StrokesGainedUtil.getExpectedStrokesForShortGame(distance);

            let expectedAfter;
            if (shot.result.toLowerCase() === "green") {
              expectedAfter = StrokesGainedUtil.getExpectedStrokesFromGreen(
                parseFloat(shot.proximityToHole) || 0
              );
            } else {
              const nextShot = holeData.shots[shotIndex + 1];
              expectedAfter = nextShot
                ? StrokesGainedUtil.getExpectedStrokesForShortGame(
                  parseFloat(nextShot.distance) || 0
                )
                : 2.0;
            }

            const strokesGained = expectedBefore - expectedAfter - 1;

            if (!isNaN(strokesGained)) {
              totalStrokesGained += strokesGained;

              console.log(
                `Round ${roundIndex + 1}, Hole ${holeNumber}, Short game shot:`
              );
              console.log(
                `  Distance: ${distance} yards, Result: ${shot.result}`
              );
              console.log(
                `  Expected before: ${expectedBefore.toFixed(
                  2
                )}, Expected after: ${expectedAfter.toFixed(2)}`
              );
              console.log(`  Strokes gained: ${strokesGained.toFixed(2)}`);
            } else {
              console.warn(`Invalid data for Round ${roundIndex + 1}, Hole ${holeNumber}, Short game shot`);
            }
          }
        });
      });
    });

    return {
      totalStrokesGained,
      totalShots,
      averageStrokesGained:
        totalShots > 0 ? totalStrokesGained / totalShots : 0,
    };
  },

  getStrokesGainedForRound: (round, aspect) => {
    let strokesGained = 0;

    Object.values(round.shots || {}).forEach((hole) => {
      if (!hole.shots || !Array.isArray(hole.shots)) {
        console.warn("Invalid hole data:", hole);
        return;
      }

      hole.shots.forEach((shot, index) => {
        if (
          shot.category &&
          shot.category.toLowerCase() === aspect.toLowerCase()
        ) {
          const nextShot = hole.shots[index + 1];
          const strokesGainedForShot = StrokesGainedUtil.getStrokesGained(
            shot,
            nextShot,
            { par: hole.par }
          );
          if (!isNaN(strokesGainedForShot)) {
            strokesGained += strokesGainedForShot;
          }
        }
      });
    });

    return strokesGained;
  },

  getStrokesGainedByClub: (rounds, aspect) => {
    const clubStats = {};

    const getExpectedStrokes = (shot, holeDetails) => {
      switch (aspect.toLowerCase()) {
        case "tee-shot":
          return StrokesGainedUtil.getExpectedStrokesForTeeShot(
            shot,
            holeDetails
          );
        case "approach":
          return StrokesGainedUtil.getExpectedStrokesForApproach(shot);
        case "short-game":
          return StrokesGainedUtil.getExpectedStrokesForShortGame(shot);
        case "putt":
          return StrokesGainedUtil.getExpectedStrokesForPutt(shot);
        default:
          console.warn(
            `Unexpected aspect: ${aspect}. Returning default value.`
          );
          return 2.0;
      }
    };

    rounds.forEach((round) => {
      Object.values(round.shots || {}).forEach((hole) => {
        if (!hole.shots || !Array.isArray(hole.shots)) return;

        hole.shots.forEach((shot, index) => {
          if (
            shot.category &&
            shot.category.toLowerCase() === aspect.toLowerCase()
          ) {
            const nextShot = hole.shots[index + 1];
            const startExpectedStrokes = getExpectedStrokes(shot, {
              par: hole.par,
            });
            const endExpectedStrokes = nextShot
              ? getExpectedStrokes(nextShot, { par: hole.par })
              : 0;

            const strokesGained = startExpectedStrokes - endExpectedStrokes - 1;

            if (!isNaN(strokesGained)) {
              if (!clubStats[shot.club]) {
                clubStats[shot.club] = { totalStrokes: 0, count: 0 };
              }
              clubStats[shot.club].totalStrokes += strokesGained;
              clubStats[shot.club].count++;
            }
          }
        });
      });
    });

    // Calculate average strokes gained for each club
    Object.keys(clubStats).forEach((club) => {
      clubStats[club].avgStrokesGained =
        clubStats[club].totalStrokes / clubStats[club].count;
    });

    return clubStats;
  },

  calculatePuttStrokesGained: (rounds) => {
    let totalStrokesGained = 0;
    let totalPutts = 0;

    rounds.forEach((round, roundIndex) => {
      let roundStrokesGained = 0;
      let roundPutts = 0;

      Object.entries(round.shots || {}).forEach(([holeNumber, holeData]) => {
        let greenReached = false;
        let proximityToHole;
        let puttCount = 0;

        for (const shot of holeData.shots) {
          if (!greenReached && (shot.result === "Green" || shot.category === "Putt")) {
            greenReached = true;
            proximityToHole = parseFloat(shot.proximityToHole) || 20; // Default to 20 feet if no distance given
          }

          if (greenReached && shot.category === "Putt") {
            puttCount++;
          }
        }

        if (greenReached) {
          roundPutts += puttCount;
          const expectedStrokes = StrokesGainedUtil.getExpectedStrokesForPutt(proximityToHole);
          const strokesGained = expectedStrokes - puttCount;
          roundStrokesGained += strokesGained;

          console.log(`Round ${roundIndex + 1}, Hole ${holeNumber}:`);
          console.log(`  Green reached at: ${proximityToHole.toFixed(1)} feet`);
          console.log(`  Number of putts: ${puttCount}`);
          console.log(`  Expected strokes: ${expectedStrokes.toFixed(2)}`);
          console.log(`  Strokes gained: ${strokesGained.toFixed(2)}`);
        }
      });

      totalStrokesGained += roundStrokesGained;
      totalPutts += roundPutts;

      console.log(`Round ${roundIndex + 1} total strokes gained: ${roundStrokesGained.toFixed(2)}`);
      console.log(`Round ${roundIndex + 1} total putts: ${roundPutts}`);
    });

    console.log(`Putt total strokes gained: ${totalStrokesGained.toFixed(2)}`);
    console.log(`Total putts: ${totalPutts}`);

    return {
      totalStrokesGained,
      totalPutts,
      averageStrokesGained: totalPutts > 0 ? totalStrokesGained / totalPutts : 0,
    };
  },

  // Helper functions
  getExpectedStrokesForTeeShot: (distance, par) => {
    const teeData = [
      { dist: 100, strokes: 2.92 },
      { dist: 120, strokes: 2.99 },
      { dist: 140, strokes: 2.97 },
      { dist: 160, strokes: 2.99 },
      { dist: 180, strokes: 3.05 },
      { dist: 200, strokes: 3.12 },
      { dist: 220, strokes: 3.17 },
      { dist: 240, strokes: 3.25 },
      { dist: 260, strokes: 3.45 },
      { dist: 280, strokes: 3.65 },
      { dist: 300, strokes: 3.71 },
      { dist: 320, strokes: 3.79 },
      { dist: 340, strokes: 3.86 },
      { dist: 360, strokes: 3.92 },
      { dist: 380, strokes: 3.96 },
      { dist: 400, strokes: 3.99 },
      { dist: 420, strokes: 4.02 },
      { dist: 440, strokes: 4.08 },
      { dist: 460, strokes: 4.17 },
      { dist: 480, strokes: 4.28 },
      { dist: 500, strokes: 4.41 },
      { dist: 520, strokes: 4.54 },
      { dist: 540, strokes: 4.65 },
      { dist: 560, strokes: 4.74 },
      { dist: 580, strokes: 4.79 },
      { dist: 600, strokes: 4.82 },
    ];

    return StrokesGainedUtil.interpolateStrokes(teeData, distance);
  },

  getExpectedStrokesAfterTeeShot: (teeShot, nextShot) => {
    if (teeShot.result.toLowerCase() === "fairway") {
      return StrokesGainedUtil.getExpectedStrokesForApproach(parseFloat(nextShot.distance), "fairway");
    }
    if (teeShot.result.toLowerCase() === "green") {
      return StrokesGainedUtil.getExpectedStrokesFromGreen(parseFloat(teeShot.proximityToHole));
    }
    if (nextShot) {
      return StrokesGainedUtil.getExpectedStrokesForApproach(
        parseFloat(nextShot.distance),
        teeShot.result.toLowerCase()
      );
    }
    return 3.0; // Default if no next shot
  },

  getExpectedStrokesForApproach: (distance, lie) => {
    const approachData = {
      fairway: [
        { dist: 20, strokes: 2.40 },
        { dist: 40, strokes: 2.60 },
        { dist: 60, strokes: 2.70 },
        { dist: 80, strokes: 2.75 },
        { dist: 100, strokes: 2.80 },
        { dist: 120, strokes: 2.85 },
        { dist: 140, strokes: 2.91 },
        { dist: 160, strokes: 2.98 },
        { dist: 180, strokes: 3.08 },
        { dist: 200, strokes: 3.19 },
        { dist: 220, strokes: 3.32 },
        { dist: 240, strokes: 3.45 },
        { dist: 260, strokes: 3.58 },
      ],
      rough: [
        { dist: 20, strokes: 2.59 },
        { dist: 40, strokes: 2.78 },
        { dist: 60, strokes: 2.91 },
        { dist: 80, strokes: 2.96 },
        { dist: 100, strokes: 3.02 },
        { dist: 120, strokes: 3.08 },
        { dist: 140, strokes: 3.15 },
        { dist: 160, strokes: 3.23 },
        { dist: 180, strokes: 3.31 },
        { dist: 200, strokes: 3.42 },
        { dist: 220, strokes: 3.53 },
        { dist: 240, strokes: 3.64 },
        { dist: 260, strokes: 3.74 },
      ],
      sand: [
        { dist: 20, strokes: 2.53 },
        { dist: 40, strokes: 2.82 },
        { dist: 60, strokes: 3.15 },
        { dist: 80, strokes: 3.24 },
        { dist: 100, strokes: 3.23 },
        { dist: 120, strokes: 3.21 },
        { dist: 140, strokes: 3.22 },
        { dist: 160, strokes: 3.28 },
        { dist: 180, strokes: 3.40 },
        { dist: 200, strokes: 3.55 },
        { dist: 220, strokes: 3.70 },
        { dist: 240, strokes: 3.84 },
        { dist: 260, strokes: 3.93 },
      ],
      recovery: [
        { dist: 100, strokes: 3.80 },
        { dist: 120, strokes: 3.78 },
        { dist: 140, strokes: 3.80 },
        { dist: 160, strokes: 3.81 },
        { dist: 180, strokes: 3.82 },
        { dist: 200, strokes: 3.87 },
        { dist: 220, strokes: 3.92 },
        { dist: 240, strokes: 3.97 },
        { dist: 260, strokes: 4.03 },
      ],
    };

    return StrokesGainedUtil.interpolateStrokes(approachData[lie] || approachData.fairway, distance);
  },

  getExpectedStrokesForShortGame: (distance) => {
    const shortGameData = [
      { dist: 10, strokes: 2.18 },
      { dist: 20, strokes: 2.40 },
      { dist: 30, strokes: 2.52 },
      { dist: 40, strokes: 2.60 },
      { dist: 50, strokes: 2.66 },
      { dist: 60, strokes: 2.70 },
      { dist: 70, strokes: 2.72 },
      { dist: 80, strokes: 2.75 },
      { dist: 90, strokes: 2.77 },
      { dist: 100, strokes: 2.80 },
    ];

    return StrokesGainedUtil.interpolateStrokes(shortGameData, distance);
  },

  getExpectedStrokesFromGreen: (proximity) => {
    return StrokesGainedUtil.getExpectedStrokesForPutt(proximity);
  },

  getExpectedStrokesForPutt: (distance) => {
    const puttData = [
      { dist: 1, strokes: 1.01 },
      { dist: 2, strokes: 1.05 },
      { dist: 3, strokes: 1.10 },
      { dist: 4, strokes: 1.15 },
      { dist: 5, strokes: 1.20 },
      { dist: 6, strokes: 1.25 },
      { dist: 8, strokes: 1.35 },
      { dist: 10, strokes: 1.45 },
      { dist: 15, strokes: 1.65 },
      { dist: 20, strokes: 1.85 },
      { dist: 25, strokes: 2.00 },
      { dist: 30, strokes: 2.15 },
      { dist: 40, strokes: 2.30 },
      { dist: 50, strokes: 2.45 },
      { dist: 60, strokes: 2.60 },
    ];

    // Handle very short putts
    if (distance < 1) {
      return 1 + (distance * 0.02); // Linear interpolation between 0 and 1 foot
    }

    return StrokesGainedUtil.interpolateStrokes(puttData, distance);
  },

  interpolateStrokes: (data, distance) => {
    if (isNaN(distance) || distance < 0) return data[0].strokes;
    if (distance <= data[0].dist) return data[0].strokes;
    if (distance >= data[data.length - 1].dist) return data[data.length - 1].strokes;

    for (let i = 0; i < data.length - 1; i++) {
      if (distance >= data[i].dist && distance < data[i + 1].dist) {
        const ratio = (distance - data[i].dist) / (data[i + 1].dist - data[i].dist);
        return data[i].strokes + ratio * (data[i + 1].strokes - data[i].strokes);
      }
    }

    return data[data.length - 1].strokes; // fallback
  },
};

export default StrokesGainedUtil;

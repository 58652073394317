import { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useAuth } from "../context/AuthContext";
import { db } from "../services/firebase";

const useFetchRounds = () => {
  const { currentUser } = useAuth();
  const [rounds, setRounds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRounds = async () => {
      if (!currentUser) {
        setError("User not authenticated");
        setLoading(false);
        return;
      }

      try {
        const roundsRef = collection(db, "rounds");
        const q = query(roundsRef, where("userId", "==", currentUser.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setRounds([]);
          setLoading(false);
          return;
        }

        const roundsData = [];
        querySnapshot.forEach((doc) => {
          roundsData.push({ id: doc.id, ...doc.data() });
        });

        setRounds(roundsData);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching rounds:", err);
        setError("Error fetching rounds: " + err.message);
        setLoading(false);
      }
    };

    fetchRounds();
  }, [currentUser]);

  return { rounds, loading, error };
};

export default useFetchRounds;

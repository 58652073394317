import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

export const getAllCourses = async () => {
  const coursesCol = collection(db, "courses");
  const courseSnapshot = await getDocs(coursesCol);
  return courseSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const getCourseById = async (courseId) => {
  const courseDoc = await getDoc(doc(db, "courses", courseId));
  if (courseDoc.exists()) {
    return { id: courseDoc.id, ...courseDoc.data() };
  } else {
    throw new Error("Course not found");
  }
};

import React, { useState } from 'react';

const TeeShotAccuracyGrid = ({ onAccuracySelect, shotResult }) => {
    const [markerPosition, setMarkerPosition] = useState(null);

    const handleGridClick = (e) => {
        const grid = e.currentTarget;
        const rect = grid.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        const posX = (x / rect.width) * 100;
        const posY = (y / rect.height) * 100;

        setMarkerPosition({ x: posX, y: posY });

        // Calculate accuracy score
        const accuracyScore = calculateAccuracyScore(posX);

        onAccuracySelect(accuracyScore);
    };

    const calculateAccuracyScore = (posX) => {
        if (shotResult === "Fairway") {
            const centerX = 50;
            const deviation = Math.abs(posX - centerX);
            // Cap the lowest score at 50
            return Math.max(50, 100 - Math.floor(deviation * 2.5));
        } else if (shotResult === "Rough") {
            return Math.max(0, 50 - Math.floor(Math.abs(posX - 50)));
        }
        return 0;
    };

    const gridStyle = {
        position: 'relative',
        width: '100%',
        height: '200px',
        backgroundColor: '#2ecc71', // Light green for the overall area
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'crosshair',
    };

    const fairwayStyle = {
        position: 'absolute',
        left: '0%',
        right: '0%',
        top: 0,
        bottom: 0,
        backgroundColor: '#27ae60', // Darker green for fairway
    };

    const roughStyle = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100%',
        background: 'linear-gradient(to right, #1e8449, #2ecc71, #1e8449)', // Dark green to light green to dark green for rough
    };

    const centerLineStyle = {
        position: 'absolute',
        left: '50%',
        top: 0,
        bottom: 0,
        width: '2px',
        backgroundColor: 'white',
    };

    const labelStyle = {
        position: 'absolute',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '12px',
        textShadow: '1px 1px 2px black',
    };

    const markerStyle = markerPosition ? {
        position: 'absolute',
        width: '10px',
        height: '10px',
        backgroundColor: 'red',
        borderRadius: '50%',
        transform: 'translate(-50%, -50%)',
        left: `${markerPosition.x}%`,
        top: `${markerPosition.y}%`,
    } : null;

    const yardageLineStyle = {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '1px',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    };

    const renderYardageLines = () => {
        const lines = [];
        const yardages = shotResult === "Fairway" ? [5, 10, 20] : [10, 20, 30];

        yardages.forEach((yards, index) => {
            const leftPosition = 50 - (yards / 30) * 50;
            const rightPosition = 50 + (yards / 30) * 50;

            lines.push(
                <div key={`left-${yards}`} style={{ ...yardageLineStyle, left: `${leftPosition}%` }} />,
                <div key={`right-${yards}`} style={{ ...yardageLineStyle, left: `${rightPosition}%` }} />,
                <div key={`label-left-${yards}`} style={{ ...labelStyle, left: `${leftPosition}%`, bottom: '5px', transform: 'translateX(-50%)' }}>{yards}y</div>,
                <div key={`label-right-${yards}`} style={{ ...labelStyle, left: `${rightPosition}%`, bottom: '5px', transform: 'translateX(-50%)' }}>{yards}y</div>
            );
        });

        return lines;
    };

    return (
        <div style={gridStyle} onClick={handleGridClick}>
            {shotResult === "Fairway" ? (
                <>
                    <div style={fairwayStyle} />
                    <div style={centerLineStyle} />
                    <div style={{ ...labelStyle, top: '10px', left: '10px' }}>Left</div>
                    <div style={{ ...labelStyle, top: '10px', right: '10px' }}>Right</div>
                    <div style={{ ...labelStyle, bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>Center</div>
                </>
            ) : (
                <>
                    <div style={roughStyle} />
                    <div style={{ ...labelStyle, top: '10px', left: '10px' }}>Left Rough</div>
                    <div style={{ ...labelStyle, top: '10px', right: '10px' }}>Right Rough</div>
                    <div style={{ ...labelStyle, bottom: '10px', left: '50%', transform: 'translateX(-50%)' }}>Fairway Edge</div>
                </>
            )}
            {renderYardageLines()}
            {markerPosition && <div style={markerStyle} />}
        </div>
    );
};

export default TeeShotAccuracyGrid;
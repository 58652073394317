import React, { useState, useEffect } from "react";
import { useAuth } from "../context/AuthContext";
import { db, storage } from "../services/firebase";
import { doc, getDoc, setDoc, collection, query, where, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import styled from '@emotion/styled';
import DashboardButton from '../components/DashboardButton';
import { Container, Title, Button, Input, ErrorMessage } from "../styles";
import { processClubDistances } from '../utils/clubDistanceProcessor';
import ClubDistances from '../components/ClubDistances';
import { update } from "lodash";

const ProfileContainer = styled(Container)`
  max-width: 600px;
`;

const ProfileCard = styled.div`
  background-color: ${props => props.theme.secondaryColor};
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ProfileForm = styled.form`
  display: grid;
  gap: 1rem;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

const ProfileInput = styled(Input)`
  background-color: ${props => props.theme.bgColor};
  border: 1px solid ${props => props.theme.primaryColor};
`;

const UpdateButton = styled(Button)`
  margin-top: 1rem;
`;

const LoadingText = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin-top: 2rem;
`;

const ProfileImageContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 1rem;
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;


const ClubSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: center;
`;

const ClubIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: ${props => props.selected ? props.theme.accentColor : props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  border: 2px solid ${props => props.selected ? props.theme.accentColor : props.theme.primaryColor};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.accentColor};
    border-color: ${props => props.theme.accentColor};
  }
`;

const availableClubs = [
  'Dr', '2w', '3w', '4w', '5w', '7w', '2h', '3h', '4h', '5h', '6h',
  '1i', '2i', '3i', '4i', '5i', '6i', '7i', '8i', '9i',
  'Pw', 'Gw', 'Sw', 'Lw', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60',
  'Pt'
];

const Prompt = styled.div`
  background-color: ${props => props.theme.accentColor};
  color: ${props => props.theme.textColor};
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: center;
  font-weight: bold;
`;

const UserProfile = () => {
  const { currentUser } = useAuth();
  const [name, setName] = useState("");
  const [handicap, setHandicap] = useState("");
  const [homeClub, setHomeClub] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [email, setEmail] = useState("");
  const [showClubPrompt, setShowClubPrompt] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [distancesUpdateTrigger, setDistancesUpdateTrigger] = useState(0);

  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setName(userData.name || "");
          setHandicap(userData.handicap || "");
          setHomeClub(userData.homeClub || "");
          setProfileImageUrl(userData.profileImageUrl || "");
          setSelectedClubs(userData.selectedClubs || []);
          setEmail(userData.email || currentUser.email || "");
          setIsNewUser(!userData.name); // If name is not set, consider it a new user

          // Set showClubPrompt based on whether the user has selected clubs
          setShowClubPrompt(userData.selectedClubs ? userData.selectedClubs.length === 0 : true);
        } else {
          setIsNewUser(true); // Document doesn't exist, so it's a new user
          setEmail(currentUser.email || "");
          setShowClubPrompt(true);
        }
      } catch (err) {
        console.error("Error fetching user profile:", err);
        setError("Failed to load user profile");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setProfileImage(e.target.files[0]);
    }
  };

  const toggleClub = (club) => {
    setSelectedClubs(prev =>
      prev.includes(club)
        ? prev.filter(c => c !== club)
        : [...prev, club]
    );
  };

  // const handleUpdateDistances = async () => {
  //   setIsUpdating(true);
  //   try {
  //     console.log("Starting club distance update for user:", currentUser.uid);

  //     // Fetch the user's rounds to get club distances
  //     const roundsRef = collection(db, "rounds");
  //     const q = query(roundsRef, where("userId", "==", currentUser.uid));
  //     const querySnapshot = await getDocs(q);

  //     let clubDistances = {};
  //     let roundCount = 0;

  //     querySnapshot.forEach((doc) => {
  //       roundCount++;
  //       const roundData = doc.data();
  //       console.log("Processing round:", doc.id);

  //       if (!roundData.shots) {
  //         console.log("No shots data found for round:", doc.id);
  //         return;
  //       }

  //       Object.values(roundData.shots).forEach((hole) => {
  //         if (!hole.shots) {
  //           console.log("No shots data found for hole in round:", doc.id);
  //           return;
  //         }

  //         hole.shots.forEach((shot) => {
  //           if (shot.club && shot.distance) {
  //             if (!clubDistances[shot.club]) {
  //               clubDistances[shot.club] = [];
  //             }
  //             clubDistances[shot.club].push({
  //               date: roundData.date,
  //               distance: parseInt(shot.distance)
  //             });
  //           }
  //         });
  //       });
  //     });

  //     console.log("Processed rounds:", roundCount);
  //     console.log("Club distances data:", clubDistances);

  //     if (Object.keys(clubDistances).length === 0) {
  //       console.log("No club distances data found");
  //       alert("No club distances data found. Please enter some rounds first.");
  //       return;
  //     }

  //     // Process the club distances
  //     await processClubDistances(currentUser.uid, clubDistances);

  //     console.log("Club distances updated successfully");
  //     alert("Club distances updated successfully!");

  //     // Trigger a re-render of the ClubDistances component
  //     setDistancesUpdateTrigger(prev => prev + 1);
  //   } catch (error) {
  //     console.error("Error updating club distances:", error);
  //     if (error.code) {
  //       console.error("Error code:", error.code);
  //     }
  //     if (error.message) {
  //       console.error("Error message:", error.message);
  //     }
  //     alert(`Failed to update club distances: ${error.message || 'Unknown error'}`);
  //   } finally {
  //     setIsUpdating(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");
    try {
      const userDocRef = doc(db, "users", currentUser.uid);

      let imageUrl = profileImageUrl;
      if (profileImage) {
        const imageRef = ref(storage, `profileImages/${currentUser.uid}`);
        await uploadBytes(imageRef, profileImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      await setDoc(
        userDocRef,
        {
          name,
          email,
          handicap,
          homeClub,
          profileImageUrl: imageUrl,
          selectedClubs,
        },
        { merge: true }
      );
      setSuccessMessage("Profile updated successfully!");
      setProfileImageUrl(imageUrl);
      setShowClubPrompt(selectedClubs.length === 0);
    } catch (err) {
      console.error("Error updating profile:", err);
      setError("Failed to update profile");
    }
  };

  if (loading) return <LoadingText>Loading...</LoadingText>;
  {
    isNewUser && (
      <p style={{ color: "blue", marginBottom: "1rem" }}>
        Welcome! Please complete your profile to get started.
      </p>
    )
  }
  return (
    <ProfileContainer>
      <DashboardButton />
      <Title>User Profile</Title>
      {isNewUser && (
        <Prompt>
          Welcome! Please complete your profile to get started.
        </Prompt>
      )}
      {showClubPrompt && (
        <Prompt>
          Select your clubs to streamline your round entry experience!
        </Prompt>
      )}
      <ProfileCard>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {successMessage && <p style={{ color: "green", marginBottom: "1rem" }}>{successMessage}</p>}
        <ProfileForm onSubmit={handleSubmit}>
          <ProfileImageContainer>
            {profileImageUrl && <ProfileImage src={profileImageUrl} alt="Profile" />}
          </ProfileImageContainer>
          <FormGroup>
            <Label htmlFor="profileImage">Profile Image:</Label>
            <Input type="file" id="profileImage" onChange={handleImageChange} accept="image/*" />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="name">Name:</Label>
            <ProfileInput
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="email">Email:</Label>
            <ProfileInput
              type="email"
              id="email"
              value={email}
              readOnly
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="handicap">Handicap:</Label>
            <ProfileInput
              type="number"
              id="handicap"
              value={handicap}
              onChange={(e) => setHandicap(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="homeClub">Home Club:</Label>
            <ProfileInput
              type="text"
              id="homeClub"
              value={homeClub}
              onChange={(e) => setHomeClub(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>My Golf Bag:</Label>
            <ClubSelection>
              {availableClubs.map((club) => (
                <ClubIcon
                  key={club}
                  selected={selectedClubs.includes(club)}
                  onClick={() => toggleClub(club)}
                >
                  {club}
                </ClubIcon>
              ))}
            </ClubSelection>
          </FormGroup>
          <UpdateButton type="submit">Update Profile</UpdateButton>
        </ProfileForm>
      </ProfileCard>

      {/* <UpdateButton onClick={handleUpdateDistances} disabled={isUpdating}>
        {isUpdating ? "Updating..." : "Update Club Distances"}
      </UpdateButton>

      <ClubDistances key={distancesUpdateTrigger} /> */}
    </ProfileContainer>
  );
};

export default UserProfile;
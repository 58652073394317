import React, { useState, useRef, useEffect } from 'react';

const GreenAccuracyGrid = ({ onDirectionSelect }) => {
    const [markerPosition, setMarkerPosition] = useState(null);
    const greenRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateSize = () => {
            if (greenRef.current) {
                const { width, height } = greenRef.current.getBoundingClientRect();
                setContainerSize({ width, height });
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const handleGreenClick = (e) => {
        if (!greenRef.current) return;

        const rect = greenRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        // Calculate position relative to the center
        const centerX = rect.width / 2;
        const centerY = rect.height / 2;
        const relativeX = (x - centerX) / centerX;
        const relativeY = (centerY - y) / centerY;

        // Calculate distance from center
        const distance = Math.sqrt(relativeX * relativeX + relativeY * relativeY);

        // Only set marker if click is inside the circle
        if (distance <= 1) {
            setMarkerPosition({ x: x / rect.width, y: y / rect.height });

            // Get golf-specific direction
            const direction = getGolfDirection(relativeX, relativeY);

            const data = {
                direction: direction,
                x: relativeX,
                y: relativeY
            };

            onDirectionSelect(data);
        }
    };

    const getGolfDirection = (x, y) => {
        const threshold = 0.1; // Threshold for considering a shot "Pin High"

        let verticalDirection = '';
        let horizontalDirection = '';

        if (y > threshold) {
            verticalDirection = 'Long';
        } else if (y < -threshold) {
            verticalDirection = 'Short';
        } else {
            verticalDirection = 'Pin High';
        }

        if (x > threshold) {
            horizontalDirection = 'Right';
        } else if (x < -threshold) {
            horizontalDirection = 'Left';
        }

        if (verticalDirection === 'Pin High') {
            return horizontalDirection ? `Pin High ${horizontalDirection}` : 'Pin High';
        } else {
            return horizontalDirection ? `${verticalDirection} ${horizontalDirection}` : verticalDirection;
        }
    };

    const greenStyle = {
        width: '100%',
        paddingBottom: '100%', // Makes it a perfect circle
        borderRadius: '50%',
        backgroundColor: '#27ae30', // Light green
        position: 'relative',
        overflow: 'hidden',
    };

    const holeStyle = {
        width: '4%',
        height: '4%',
        borderRadius: '50%',
        backgroundColor: 'white',
        border: '1px solid black',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };

    const gridLineStyle = {
        position: 'absolute',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    };

    const markerStyle = markerPosition ? {
        position: 'absolute',
        width: '3%',
        height: '3%',
        backgroundColor: 'red',
        borderRadius: '50%',
        top: `${markerPosition.y * 100}%`,
        left: `${markerPosition.x * 100}%`,
        transform: 'translate(-50%, -50%)',
    } : null;

    const renderGridLines = () => {
        const lines = [];
        // Render concentric circles
        [25, 50, 75].forEach((percentage) => {
            lines.push(
                <div
                    key={`circle-${percentage}`}
                    style={{
                        ...gridLineStyle,
                        width: `${percentage * 2}%`,
                        height: `${percentage * 2}%`,
                        borderRadius: '50%',
                        border: '1px solid rgba(255, 255, 255, 0.3)',
                        top: `${50 - percentage}%`,
                        left: `${50 - percentage}%`,
                    }}
                />
            );
        });
        // Render cross lines
        lines.push(
            <div key="vertical" style={{ ...gridLineStyle, width: '1px', height: '100%', left: '50%' }} />,
            <div key="horizontal" style={{ ...gridLineStyle, width: '100%', height: '1px', top: '50%' }} />
        );
        return lines;
    };

    return (
        <div style={{ width: '100%', maxWidth: '300px', margin: '0 auto' }}>
            <div ref={greenRef} style={greenStyle} onClick={handleGreenClick}>
                {renderGridLines()}
                <div style={holeStyle} />
                {markerPosition && <div style={markerStyle} />}
            </div>

        </div>
    );
};

export default GreenAccuracyGrid;
// firebase.js
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCRV0g3bUUK7Hsmz0bJcXKa1SgQUQFVkng",
  authDomain: "golf-performance-analysis.firebaseapp.com",
  projectId: "golf-performance-analysis",
  storageBucket: "golf-performance-analysis.appspot.com",
  messagingSenderId: "683507527784",
  appId: "1:683507527784:web:efdc7281a06b76ed9f4bdd",
  measurementId: "G-2N8FHJ2FM4",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export const addAdminRole = async (email) => {
  const addAdminRoleFunc = httpsCallable(functions, 'addAdminRole');
  try {
    const result = await addAdminRoleFunc({ email });
    return result.data;
  } catch (error) {
    console.error("Error adding admin role:", error);
    throw error;
  }
};

export { app as firebaseApp };  // Export the app instance as firebaseApp
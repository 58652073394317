import React from "react";
import { Link } from "react-router-dom";
import styled from '@emotion/styled';
import { Container, Title, Button, List, ListItem } from "../styles";

const HomeContainer = styled(Container)`
  max-width: 600px;
`;

const WelcomeText = styled.p`
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
`;

const NavList = styled(List)`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const NavItem = styled(ListItem)`
  margin-bottom: 0;
`;

const NavLink = styled(Link)`
  display: block;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.textColor};
  padding: 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.accentColor};
  }
`;

const Home = () => {
  return (
    <HomeContainer>
      <Title>GolfIQ</Title>
      <WelcomeText>Welcome to your smarter performance tracker!</WelcomeText>
      <nav>
        <NavList>
          <NavItem>
            <NavLink to="/login">Login</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/signup">Sign Up</NavLink>
          </NavItem>
        </NavList>
      </nav>
    </HomeContainer>
  );
};

export default Home;
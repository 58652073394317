import React, { useState, useEffect } from "react";
import { firebaseApp } from "../services/firebase";
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";
import { db, auth } from "../services/firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import EditableScorecard from "../components/EditableScorecard";
import styled from "@emotion/styled";

import {
  Container,
  Title,
  SubTitle,
  Button,
  Input,
  ErrorMessage,
  Text,
  List,
  ListItem,
  theme,
} from "../styles";

const ScoreCardUploader = () => {
  const [image, setImage] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [model, setModel] = useState(null);
  const [user, setUser] = useState(null);
  const [courseName, setCourseName] = useState("");
  const [localCourseName, setLocalCourseName] = useState("");
  const [existingCourse, setExistingCourse] = useState(null);
  const [extractedData, setExtractedData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const PreviewImage = styled.img`
    max-width: 100%;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 8px;
  `;

  const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  `;

  const Th = styled.th`
    background-color: ${theme.primaryColor};
    color: ${theme.textColor};
    padding: 0.5rem;
    text-align: left;
  `;

  const Td = styled.td`
    background-color: ${theme.secondaryColor};
    color: ${theme.textColor};
    padding: 0.5rem;
    border-bottom: 1px solid ${theme.primaryColor};
  `;

  useEffect(() => {
    const vertexAI = getVertexAI(firebaseApp);
    const generativeModel = getGenerativeModel(vertexAI, {
      model: "gemini-pro-vision",
    });
    setModel(generativeModel);

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleCourseNameChange = (event) => {
    setCourseName(event.target.value);
  };

  const checkExistingCourse = async (name) => {
    const courseQuery = query(
      collection(db, "courses"),
      where("name", "==", name.trim())
    );
    const courseSnapshot = await getDocs(courseQuery);
    return courseSnapshot.empty ? null : courseSnapshot.docs[0].data();
  };

  const processImage = async () => {
    if (!image || !model || !user || !courseName.trim()) return;

    setProcessing(true);
    setError(null);

    try {
      // Check for existing course
      const existingCourse = await checkExistingCourse(courseName);
      if (existingCourse) {
        setError("This course already exists in the database.");
        setProcessing(false);
        return;
      }

      let base64Image = await fileToBase64(image);

      // Multi-pass processing
      const courseInfo = await runAIPass(base64Image, prompts.courseInfo);
      const yardages = await runAIPass(base64Image, prompts.yardages);
      const pars = await runAIPass(base64Image, prompts.pars);
      const strokeIndices = await runAIPass(base64Image, prompts.strokeIndices);

      // Combine the results
      const combinedData = combineResults(
        courseInfo,
        yardages,
        pars,
        strokeIndices
      );

      setExtractedData(combinedData);
      setIsEditing(true);
    } catch (error) {
      console.error("Error processing image:", error);
      setError(`Failed to process image: ${error.message}`);
    } finally {
      setProcessing(false);
    }
  };

  const runAIPass = async (base64Image, prompt) => {
    const result = await model.generateContent([
      {
        inlineData: {
          mimeType: "image/jpeg",
          data: base64Image,
        },
      },
      prompt,
    ]);

    if (
      result.response &&
      result.response.candidates &&
      result.response.candidates.length > 0
    ) {
      let responseText = result.response.candidates[0].content.parts[0].text;
      responseText = responseText
        .replace(/```json\n?/, "")
        .replace(/\n?```/, "")
        .trim();
      return JSON.parse(responseText);
    } else {
      throw new Error("No valid response from the model");
    }
  };

  const prompts = {
    courseInfo: `
          Analyze this golf scorecard image and extract the following information:
          1. Course name
          2. For each tee box color (typically White, Yellow, Blue, and Red):
             - Color
             - Name (if different from color)
             - Slope rating (if available)
             - Course rating (if available)
      
          Format the response as a JSON object:
          {
            "name": "Course Name",
            "tees": [
              {
                "color": "Color",
                "name": "Name or Color if no separate name",
                "slope": number or null if not found,
                "rating": number or null if not found
              },
              ...
            ]
          }
        `,

    yardages: `
          Analyze this golf scorecard image and extract the yardages for each hole and tee.
          Focus only on the yardage information.
      
          Format the response as a JSON object:
          {
            "holes": [
              {
                "number": 1,
                "yardages": { "White": yardage, "Yellow": yardage, "Blue": yardage, "Red": yardage }
              },
              ...
            ]
          }
        `,

    pars: `
          Analyze this golf scorecard image and extract the par for each hole and tee.
          Focus only on the par information. Pay close attention to any differences in par between tees.
      
          Format the response as a JSON object:
          {
            "holes": [
              {
                "number": 1,
                "pars": { "White": par, "Yellow": par, "Blue": par, "Red": par }
              },
              ...
            ]
          }
        `,

    strokeIndices: `
          Analyze this golf scorecard image and extract the stroke indices (S.I.) for men and women.
          Focus only on the stroke index information.
      
          Format the response as a JSON object:
          {
            "holes": [
              {
                "number": 1,
                "strokeIndexMen": number,
                "strokeIndexWomen": number
              },
              ...
            ]
          }
        `,
  };

  const combineResults = (courseInfo, yardages, pars, strokeIndices) => {
    const combinedHoles = yardages.holes.map((hole, index) => ({
      ...hole,
      ...pars.holes[index],
      ...strokeIndices.holes[index],
    }));

    return {
      name: courseInfo.name || localCourseName,
      tees: courseInfo.tees.map((tee) => ({
        ...tee,
        totalPar: combinedHoles.reduce(
          (sum, hole) => sum + hole.pars[tee.color],
          0
        ),
      })),
      holes: combinedHoles,
    };
  };

  const handleSaveScorecard = async (editedData) => {
    try {
      await saveCourseToFirestore(editedData);
      setUploadSuccess(true);
      resetUploader();
    } catch (error) {
      console.error("Error saving scorecard:", error);
      setError(`Failed to save scorecard: ${error.message}`);
    }
  };

  const resetUploader = () => {
    setImage(null);
    setCourseName("");
    setExtractedData(null);
    setIsEditing(false);
    setError(null);
    setProcessing(false);
    setUploadSuccess(false);
    // If you're using a file input ref, you might want to reset it as well
    // if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleDiscardChanges = () => {
    if (window.confirm("Are you sure you want to discard all changes?")) {
      resetUploader();
    }
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const saveCourseToFirestore = async (courseData) => {
    if (!user) {
      throw new Error("User must be authenticated to save course data");
    }
    try {
      const docRef = await addDoc(collection(db, "courses"), {
        ...courseData,
        createdBy: user.uid,
        createdAt: new Date(),
      });
      console.log("Course saved with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  };

  const renderResult = () => {
    if (!extractedData) return null;

    return (
      <div>
        <SubTitle>{extractedData.name}</SubTitle>
        <SubTitle>Tees:</SubTitle>
        <List>
          {extractedData.tees.map((tee, index) => (
            <ListItem key={index}>
              {tee.color}: Total Par {tee.totalPar}, Slope {tee.slope}, Rating{" "}
              {tee.rating}
            </ListItem>
          ))}
        </List>
        <SubTitle>Holes:</SubTitle>
        <Table>
          <thead>
            <tr>
              <Th>Hole</Th>
              <Th>S.I. (Men)</Th>
              <Th>S.I. (Women)</Th>
              {extractedData.tees.map((tee) => (
                <React.Fragment key={tee.color}>
                  <Th>{tee.color} Yards</Th>
                  <Th>{tee.color} Par</Th>
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {extractedData.holes.map((hole) => (
              <tr key={hole.number}>
                <Td>{hole.number}</Td>
                <Td>{hole.strokeIndexMen}</Td>
                <Td>{hole.strokeIndexWomen}</Td>
                {extractedData.tees.map((tee) => (
                  <React.Fragment key={tee.color}>
                    <Td>{hole.yardages[tee.color]}</Td>
                    <Td>{hole.pars[tee.color]}</Td>
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Container>
      <Title>Scorecard Uploader</Title>
      {!user && (
        <ErrorMessage>Please log in to upload scorecards.</ErrorMessage>
      )}
      {uploadSuccess ? (
        <Text>
          Scorecard uploaded successfully! You can upload another one.
        </Text>
      ) : (
        <>
          <Input
            type="text"
            value={courseName}
            onChange={handleCourseNameChange}
            placeholder="Enter course name"
            disabled={!user}
          />
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            disabled={!user}
          />
          {image && (
            <PreviewImage
              src={URL.createObjectURL(image)}
              alt="Scorecard preview"
            />
          )}
          <Button
            onClick={processImage}
            disabled={
              !image || !courseName.trim() || processing || !model || !user
            }
          >
            Process Scorecard
          </Button>
          {processing && <Text>Processing...</Text>}
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {isEditing && extractedData ? (
            <>
              <EditableScorecard
                courseData={extractedData}
                onSave={handleSaveScorecard}
              />
              <Button onClick={handleDiscardChanges}>Discard Changes</Button>
            </>
          ) : (
            renderResult()
          )}
        </>
      )}
    </Container>
  );
};

export default ScoreCardUploader;

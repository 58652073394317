// src/components/AdminPanel.js
import React, { useState } from 'react';
import { addAdminRole } from '../services/firebase';

const AdminPanel = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleAddAdmin = async (e) => {
        e.preventDefault();
        try {
            const result = await addAdminRole(email);
            setMessage(result.message);
            setEmail('');
        } catch (error) {
            setMessage(`Error adding admin: ${error.message}`);
        }
    };

    return (
        <div>
            <h2>Add New Admin</h2>
            <form onSubmit={handleAddAdmin}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter email"
                    required
                />
                <button type="submit">Add Admin</button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default AdminPanel;
//Current Script

import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useAuth } from "../context/AuthContext";
import { db } from "../services/firebase";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAllCourses } from "../services/courseService";
import styled from "@emotion/styled";
import {
  Container,
  Title,
  Button,
  Input,
  ErrorMessage,
  breakpoints,
} from "../styles";
import DashboardButton from "../components/DashboardButton";
import TeeShotAccuracyGrid from "../components/TeeShotAccuracyGrid";
import GreenAccuracyGrid from "../components/GreenAccuracyGrid";
import { processClubDistances } from '../utils/clubDistanceProcessor';
import { motion, AnimatePresence } from "framer-motion";
import { ArrowLeft, ArrowRight, Check, Plus, Minus } from "lucide-react";

import { ChevronDown, ChevronUp, Flag } from "lucide-react";

const RoundSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: ${(props) => props.theme.bgColor || "#1a1a2e"};
  border-top: 1px solid ${(props) => props.theme.primaryColor || "#0f3460"};
`;

const CourseName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: ${(props) => props.theme.textColor || "#ffffff"};
  padding: 0.75rem;
  background-color: ${(props) => props.theme.accentColor || "#16213e"};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const RoundSummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
`;

const RoundInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.75rem;
  background-color: ${(props) => props.theme.secondaryColor || "#16213e"};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const RoundInfoLabel = styled.span`
  font-size: 0.75rem;
  color: ${(props) => props.theme.textColor || "#ffffff"}aa;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const RoundInfoValue = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  color: ${(props) => props.theme.textColor || "#ffffff"};
`;

const CollapsibleSection = styled(motion.div)`
  background-color: ${(props) => props.theme.bgColor || "#1a1a2e"};
  border-radius: 8px;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CollapsibleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background-color: ${(props) => props.theme.secondaryColor || "#16213e"};
  color: ${(props) => props.theme.textColor || "#ffffff"};
  font-weight: bold;
`;



const RoundEntryContainer = styled(Container)`
  max-width: 600px;
  width: 100%;
  margin: 2rem auto;
  background-color: ${(props) => props.theme.secondaryColor};
  color: ${(props) => props.theme.textColor};
`;


const StepContainer = styled(motion.div)`
  margin-bottom: 1rem;
`;

const resultColors = {
  Fairway: "#90EE90", // Light green
  Rough: "#228B22", // Forest green
  Green: "#98FB98", // Pale green
  Bunker: "#FFFACD", // Light yellow
  Water: "#1E90FF", // Dodger blue
  OB: "#FF4500", // Orange red
  "In the Hole": "#FFD700", // Gold
  Long: "#FFA07A", // Light salmon
  Short: "#20B2AA", // Light sea green
  Left: "#DDA0DD", // Plum
  Right: "#F0E68C", // Khaki
};

const WhiteCard = styled.div`
  background-color: white;
  color: ${(props) => props.theme.bgColor};
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;



const HoleEntry = styled(WhiteCard)`
  h3 {
    color: ${(props) => props.theme.primaryColor};
    margin-bottom: 1rem;
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 0.8rem 1.5rem;
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.textColor};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
  }

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    padding: 0.6rem;
    width: 40px;
    height: 40px;
    border-radius: 20px;

    span {
      display: none;
    }
  }
`;

const HoleIndicator = styled.span`
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ShotEntry = styled.div`
  background-color: ${(props) => props.theme.bgColor};
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 4px;
  color: ${(props) => props.theme.textColor};
`;

const ShotItem = styled.li`
  background-color: ${(props) => props.theme.secondaryColor || "#16213e"};
  color: ${(props) => props.theme.textColor || "#ffffff"};
  padding: 0.75rem 1rem;
  margin: 0.5rem;
  border-radius: 6px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.primaryColor || "#0f3460"};
  }
`;

const ShotSummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ShotList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 1rem 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${(props) => props.theme.bgColor || "#1a1a2e"};
  border-radius: 8px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);

  /* Hide default scrollbar */
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  /* Custom scrollbar styles */
  &:hover {
    &::-webkit-scrollbar {
      display: block;
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.primaryColor};
      border-radius: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.bgColor};
      border-radius: 4px;
    }
    scrollbar-width: thin;
    scrollbar-color: ${(props) =>
    `${props.theme.primaryColor} ${props.theme.bgColor}`};
  }
`;

const ShotDetails = styled(motion.div)`
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid ${(props) => props.theme.primaryColor || "#0f3460"};
  overflow: hidden;
`;

const ShotDetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const ShotDetailLabel = styled.span`
  font-weight: bold;
`;

const ShotDetailValue = styled.span`
  text-align: right;
`;

const ShotActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 0.75rem;
`;

const ShotButton = styled(Button)`
  padding: 0.4rem 0.8rem;
  font-size: 0.8rem;
`;

const ExpandIcon = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.accentColor};
`;

const StatsDisplay = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  background-color: ${(props) => props.theme.bgColor};
  border-radius: 4px;
  color: ${(props) => props.theme.textColor};
`;

const EditModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditForm = styled(WhiteCard)`
  max-width: 400px;
`;

const ShotCategory = styled.div`
  display: flex-wrap;
  justify-content: center;
  margin-bottom: 1rem;
`;

const ShotCategoryButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) =>
    props.active ? props.theme.accentColor : props.theme.primaryColor};
  color: ${(props) =>
    props.active ? props.theme.textColor : props.theme.textColor};
  border: 1px solid ${(props) => props.theme.bgColor};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
    color: ${(props) => props.theme.textColor};
  }
`;

const ClubSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: center;
`;

const ClubIcon = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor};
  color: ${(props) => props.theme.textColor};
  border: 2px solid
    ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
    border-color: ${(props) => props.theme.accentColor};
  }
`;

const ResultSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: space-evenly;
`;

const ResultIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) => props.theme.bgColor};
  color: ${(props) => props.theme.textColor};
  border: 3px solid
    ${(props) => (props.selected ? props.color : props.theme.primaryColor)};
  transition: all 0.3s ease;

  &:hover {
    border-color: ${(props) => props.color};
  }
`;

const LargeInput = styled(Input)`
  width: 100%;
  height: 80px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  border: 2px solid ${(props) => props.theme.primaryColor};
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  color: ${(props) => props.theme.primaryColor};
  transition: all 0.3s ease;

  &:focus {
    border-color: ${(props) => props.theme.accentColor};
    box-shadow: 0 0 0 2px ${(props) => props.theme.accentColor}33;
  }
`;

const ShotTypeSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
  justify-content: space-evenly;
`;

const ShotTypeIcon = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor}CC;
  color: ${(props) => props.theme.textColor};
  border: 2px solid
    ${(props) =>
    props.selected ? props.theme.accentColor : props.theme.primaryColor}CC;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
    border-color: ${(props) => props.theme.accentColor};
  }
`;

const StatsContainer = styled.div`
  background-color: ${(props) => props.theme.bgColor};
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StatItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
`;

const StatLabel = styled.span`
  font-weight: bold;
  color: ${(props) => props.theme.textColor};
`;

const StatValue = styled.span`
  color: ${(props) => props.theme.accentColor};
`;

const DEFAULT_CLUBS = [
  "Dr",
  "2w",
  "3w",
  "4w",
  "5w",
  "7w",
  "2h",
  "3h",
  "4h",
  "5h",
  "6h",
  "1i",
  "2i",
  "3i",
  "4i",
  "5i",
  "6i",
  "7i",
  "8i",
  "9i",
  "Pw",
  "Gw",
  "Sw",
  "Lw",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "Pt",
];

const PenaltySelection = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const PenaltyButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  border: 2px solid ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: ${(props) =>
    props.selected ? props.theme.accentColor : "transparent"};
  color: ${(props) => props.theme.textColor};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.accentColor};
  }
`;



const calculateAccuracy = (shot) => {
  let baseAccuracy = shot.accuracyScore || 0; // 0-100 scale from TeeShotAccuracyGrid

  // Penalty adjustments
  if (shot.result === "Water" || shot.result === "OB") {
    baseAccuracy = Math.max(0, baseAccuracy - 50); // Severe penalty for lost balls
  } else if (shot.penaltyStrokes > 0) {
    baseAccuracy = Math.max(0, baseAccuracy - (10 * shot.penaltyStrokes)); // 10 point reduction per penalty stroke
  }

  // Bonus for great shots
  if (shot.result === "Green" || shot.result === "In the Hole") {
    baseAccuracy = Math.min(100, baseAccuracy + 10);
  }

  return baseAccuracy;
};

const RoundEntry = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [userClubs, setUserClubs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [date, setDate] = useState("");
  const [courseId, setCourseId] = useState("");
  const [selectedTee, setSelectedTee] = useState("");
  const [currentHole, setCurrentHole] = useState(1);
  const [shots, setShots] = useState({});
  const [currentShot, setCurrentShot] = useState({
    category: "",
    club: "",
    distance: "",
    result: "",
    type: "",
    isPenalty: false,
    proximityToHole: "",
    accuracyScore: null,
  });
  const [editingShot, setEditingShot] = useState(null);
  const [editingShotIndex, setEditingShotIndex] = useState(null);
  const [error, setError] = useState(null);
  const [teeShotAccuracy, setTeeShotAccuracy] = useState(null);
  const [isRoundInfoExpanded, setIsRoundInfoExpanded] = useState(true);
  const [isRoundDataComplete, setIsRoundDataComplete] = useState(false);
  const [courseHandicap, setCourseHandicap] = useState('N/A');
  const [shortestTee, setShortestTee] = useState(null);
  const [availableClubs, setAvailableClubs] = useState(DEFAULT_CLUBS);
  const [showAllClubs, setShowAllClubs] = useState(false);
  const [hasActiveRound, setHasActiveRound] = useState(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [isRoundLoaded, setIsRoundLoaded] = useState(false);
  const [expandedShot, setExpandedShot] = useState(null);
  const [playerHandicap, setPlayerHandicap] = useState(0);
  const scoreCalcularorRef = useRef();
  const [isPenaltySelected, setIsPenaltySelected] = useState(false);
  const [shotHistory, setShotHistory] = useState([]);

  const generateCustomDocId = (name, date, courseName, totalScore) => {
    // Use the first two characters of the name, or 'AN' if name is not available
    const namePrefix = name ? name.slice(0, 12).toUpperCase() : 'AN';
    const dateRef = date.replace(/-/g, '').slice(2); // Format: YYMMDD
    const coursePrefix = courseName.slice(0, 3).toUpperCase();
    return `${namePrefix}${dateRef}${coursePrefix}${totalScore}`;
  };

  const getStrokeIndex = (hole) => {
    if (selectedTee === shortestTee && hole.strokeIndexWomen !== undefined) {
      return hole.strokeIndexWomen;
    }
    return hole.strokeIndexMen || "N/A";
  };

  const getShotsOnHole = (strokeIndex, courseHandicap) => {
    if (strokeIndex === "N/A") return 0;
    let shots = 0;
    if (courseHandicap > 18) {
      shots = 1 + Math.floor((courseHandicap - strokeIndex + 18) / 18);
      if (strokeIndex <= (courseHandicap - 18)) {
        shots += 1;
      }
    } else {
      shots = strokeIndex <= courseHandicap ? 1 : 0;
    }
    return shots;
  };


  const calculateTotalPar = useCallback((course, tee) => {
    if (!course || !tee) return 0;
    return course.holes.reduce((total, hole) => total + (hole.pars[tee] || 0), 0);
  }, []);

  const calculateTotalYardage = (course, tee) => {
    return course.holes.reduce((total, hole) => total + (hole.yardages[tee] || 0), 0);
  };

  const resetShotEntry = () => {
    setCurrentShot({
      category: "",
      club: "",
      distance: "",
      result: "",
      type: "",
      penaltyStrokes: 0,
      proximityToHole: "",
      accuracyScore: null,
      greenDirection: null,
      greenAngle: null,
      greenPositionX: null,
      greenPositionY: null,
    });
    setCurrentStep(0);
    setIsPenaltySelected(false);
    setTeeShotAccuracy(null);
  };


  const toggleRoundInfo = () => {
    setIsRoundInfoExpanded(!isRoundInfoExpanded);
  };

  const toggleShotExpansion = (index) => {
    setExpandedShot(expandedShot === index ? null : index);
  };

  // Add this useMemo hook to compute selectedCourse
  const selectedCourse = useMemo(() => {
    return courses.find((course) => course.id === courseId);
  }, [courses, courseId]);

  const handleCourseSelect = (newCourseId) => {
    console.log("Selected course ID:", newCourseId);
    setCourseId(newCourseId);
    setSelectedTee("");
    setCurrentHole(1);

    const newSelectedCourse = courses.find(
      (course) => course.id === newCourseId
    );
    if (newSelectedCourse) {
      updateShortestTee(newSelectedCourse);
    }
  };

  useEffect(() => {
    if (date && courseId && selectedTee) {
      setIsRoundDataComplete(true);
      setIsRoundInfoExpanded(false);
    } else {
      setIsRoundDataComplete(false);
    }
  }, [date, courseId, selectedTee]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userDocRef);
          const fetchedHandicap = userDoc.data().handicap || 0;
          setPlayerHandicap(fetchedHandicap);
          console.log("Fetched Player Handicap:", fetchedHandicap);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.selectedClubs && userData.selectedClubs.length > 0) {
              setUserClubs(userData.selectedClubs);
              setAvailableClubs(userData.selectedClubs);
            } else {
              setUserClubs([]);
              setAvailableClubs(DEFAULT_CLUBS);
            }
          }
        } catch (err) {
          console.error("Error fetching user profile:", err);
          setError("Failed to load user profile");
          setAvailableClubs(DEFAULT_CLUBS);
        }
      }
    };

    const fetchCourses = async () => {
      try {
        const fetchedCourses = await getAllCourses();
        setCourses(fetchedCourses);
        console.log("Fetched courses:", fetchedCourses);

        // Load saved round data after courses are fetched
        const savedRoundData = localStorage.getItem("activeRound");
        console.log("Raw saved round data:", savedRoundData);
        if (savedRoundData) {
          const parsedData = JSON.parse(savedRoundData);
          console.log("Parsed saved round data:", parsedData);

          setDate(parsedData.date || "");
          setCourseId(parsedData.courseId || "");
          setSelectedTee(parsedData.selectedTee || "");
          setCurrentHole(parsedData.currentHole || 1);
          setShots(parsedData.shots || {});
          setCurrentShot(
            parsedData.currentShot || {
              category: "",
              club: "",
              distance: "",
              result: "",
              type: "",
              isPenalty: false,
              proximityToHole: "",
              accuracyScore: null,
            }
          );
          setCurrentStep(parsedData.currentStep || 0);
          setHasActiveRound(true);
          setIsRoundLoaded(true);

          // Find the selected course and set the tee
          const selectedCourse = fetchedCourses.find(
            (course) => course.id === parsedData.courseId
          );
          console.log("Selected course:", selectedCourse);
          if (selectedCourse) {
            updateShortestTee(selectedCourse, parsedData.selectedTee);
          }

          console.log("Loaded round data:", {
            date: parsedData.date,
            courseId: parsedData.courseId,
            selectedTee: parsedData.selectedTee,
            currentHole: parsedData.currentHole,
            shots: parsedData.shots,
            isRoundLoaded: true,
          });
        } else {
          console.log("No saved round data found.");
        }
      } catch (err) {
        console.error("Error fetching courses:", err);
        setError("Failed to fetch courses");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
    fetchCourses();
  }, [currentUser]);

  useEffect(() => {
    console.log("State updated:", {
      date,
      courseId,
      selectedTee,
      currentHole,
      shots,
      isRoundLoaded,
    });
  }, [date, courseId, selectedTee, currentHole, shots, isRoundLoaded]);

  const updateShortestTee = (course, selectedTee) => {
    const teeLengths = course.tees.map((tee) => ({
      color: tee.color,
      totalLength: course.holes.reduce(
        (sum, hole) => sum + (hole.yardages[tee.color] || 0),
        0
      ),
    }));

    const shortest = teeLengths.reduce((shortest, current) =>
      current.totalLength < shortest.totalLength ? current : shortest
    );

    setShortestTee(shortest.color);
  };

  useEffect(() => {
    if (isRoundLoaded) {
      // Only save if a round is actually loaded
      const roundData = {
        date,
        courseId,
        selectedTee,
        currentHole,
        shots,
        currentShot,
        currentStep,
        isRoundLoaded,
      };
      console.log("Saving round data to localStorage:", roundData);
      localStorage.setItem("activeRound", JSON.stringify(roundData));
    }
  }, [
    date,
    courseId,
    selectedTee,
    currentHole,
    shots,
    currentShot,
    currentStep,
    isRoundLoaded,
  ]);

  useEffect(() => {
    const calculateCourseHandicap = () => {
      console.log('Calculating course handicap...');
      console.log('Selected Course:', selectedCourse);
      console.log('Selected Tee:', selectedTee);
      console.log('Player Handicap:', playerHandicap);

      if (!selectedCourse || !selectedTee) {
        console.log('Missing required data, setting course handicap to N/A');
        setCourseHandicap('N/A');
        return;
      }

      const selectedTeeData = selectedCourse.tees.find(tee => tee.color === selectedTee);
      console.log('Selected Tee Data:', selectedTeeData);

      if (!selectedTeeData) {
        console.log('Selected tee data not found, setting course handicap to N/A');
        setCourseHandicap('N/A');
        return;
      }

      const courseSlope = selectedTeeData.slope || 113;
      const courseRating = selectedTeeData.rating || 72;
      const coursePar = calculateTotalPar(selectedCourse, selectedTee);

      console.log('Course Slope:', courseSlope);
      console.log('Course Rating:', courseRating);
      console.log('Course Par:', coursePar);

      const calculatedHandicap = Math.round(
        (playerHandicap * (courseSlope / 113)) + (courseRating - coursePar)
      );

      console.log('Calculated Course Handicap:', calculatedHandicap);

      setCourseHandicap(calculatedHandicap);
    };

    calculateCourseHandicap();
  }, [selectedCourse, selectedTee, playerHandicap, calculateTotalPar]);


  const loadActiveRound = () => {
    const savedRoundData = localStorage.getItem("activeRound");
    if (savedRoundData) {
      const parsedData = JSON.parse(savedRoundData);
      setDate(parsedData.date || "");
      setCourseId(parsedData.courseId || "");
      setSelectedTee(parsedData.selectedTee || "");
      setCurrentHole(parsedData.currentHole || 1);
      setShots(parsedData.shots || {});
      setCurrentShot(
        parsedData.currentShot || {
          category: "",
          club: "",
          distance: "",
          result: "",
          type: "",
          isPenalty: false,
          proximityToHole: "",
          accuracyScore: null,
        }
      );
      setCurrentStep(parsedData.currentStep || 0);
      setHasActiveRound(false);
      setIsRoundLoaded(true);
    }
  };

  const abandonActiveRound = () => {
    localStorage.removeItem("activeRound");
    setHasActiveRound(false);
    setIsRoundLoaded(false);
    // Reset all state to initial values
    setDate("");
    setCourseId("");
    setSelectedTee("");
    setCurrentHole(1);
    setShots({});
    setCurrentShot({
      category: "",
      club: "",
      distance: "",
      result: "",
      type: "",
      isPenalty: false,
      proximityToHole: "",
      accuracyScore: null,
    });
    setCurrentStep(0);
  };

  const startNewRound = () => {
    if (hasActiveRound) {
      setShowConflictModal(true);
    } else {
      resetRoundData();
      setIsRoundLoaded(true); // Set this to true when starting a new round
      console.log("Starting new round, isRoundLoaded set to true");
    }
  };

  const resetRoundData = () => {
    setDate("");
    setCourseId("");
    setSelectedTee("");
    setCurrentHole(1);
    setShots({});
    setCurrentShot({
      category: "",
      club: "",
      distance: "",
      result: "",
      type: "",
      isPenalty: false,
      proximityToHole: "",
      accuracyScore: null,
    });
    setCurrentStep(0);
    setHasActiveRound(false);

    // Clear the active round from local storage
    localStorage.removeItem("activeRound");
    console.log("Round data reset, localStorage cleared");
  };


  const toggleClubSelection = () => {
    setShowAllClubs(!showAllClubs);
    setAvailableClubs(showAllClubs ? userClubs : DEFAULT_CLUBS);
  };

  const handleShotCategoryChange = (category) => {
    handleShotChange("category", category);
    if (category === "Putt") {
      handleShotChange("club", "Putter");
      handleShotChange("type", "Putt");
    } else if (category !== "Tee-shot") {
      setTeeShotAccuracy(null);
      handleShotChange("accuracyScore", null);
    }
  };

  const handleShotChange = (field, value) => {
    const updateShot = (prev) => {
      const updatedShot = { ...prev, [field]: value };
      if (field === "result") {
        if (value === "Water" || value === "OB") {
          updatedShot.penaltyStrokes = 1;
        } else {
          updatedShot.penaltyStrokes = 0;
        }
        setIsPenaltySelected(false); // Reset penalty selection when result changes
      }

      // Recalculate accuracy if relevant fields change
      if (["result", "penaltyStrokes", "accuracyScore"].includes(field)) {
        updatedShot.calculatedAccuracy = calculateAccuracy(updatedShot);
      }

      return updatedShot;
    };

    if (editingShot) {
      setEditingShot(updateShot);
    } else {
      setCurrentShot(updateShot);
    }

    // Move to the next step if the current one is complete
    if (currentStep < 6) {
      let nextStep = currentStep + 1;

      // Special handling for result selection
      if (field === "result") {
        if (
          currentShot.category === "Tee-shot" &&
          (value === "Fairway" || value === "Rough")
        ) {
          nextStep = 5; // Show tee shot accuracy grid
        } else if (value === "Green") {
          nextStep = 5; // Show proximity input and green direction grid
        } else if (currentShot.category === "Putt" && value !== "In the Hole") {
          nextStep = 5; // Show proximity input for missed putts
        } else {
          nextStep = 6; // Move to penalty and add shot for other results
        }
      }

      // Special handling for shot category
      if (field === "category") {
        if (value === "Putt") {
          nextStep = 3; // Skip club selection for putts
        } else {
          nextStep = 1; // Go to club selection for non-putts
        }
      }

      // Move to penalty and add shot step after setting accuracy, green direction, or putt proximity
      if (
        field === "accuracyScore" ||
        field === "greenDirection" ||
        (currentShot.category === "Putt" && field === "proximityToHole")
      ) {
        nextStep = 6;
      }

      setCurrentStep(nextStep);
    }
  };

  const handleGreenDirection = (data) => {
    handleShotChange("greenDirection", {
      direction: data.direction,
      angle: data.angle,
    });
    handleShotChange("greenPositionX", data.x);
    handleShotChange("greenPositionY", data.y);
  };

  const handlePenaltyChange = (strokes) => {
    const updateShot = (prev) => ({
      ...prev,
      penaltyStrokes: strokes,
    });

    if (editingShot) {
      setEditingShot(updateShot);
    } else {
      setCurrentShot(updateShot);
    }
  };

  const addShot = () => {
    if (
      currentShot.category &&
      currentShot.club &&
      (currentShot.distance || currentShot.category === "Putt")
    ) {
      setShots((prev) => {
        const newShot = {
          ...currentShot,
          shotNumber: (prev[currentHole] || []).length + 1,
          calculatedAccuracy: calculateAccuracy(currentShot)
        };

        const updatedHoleShots = [
          ...(prev[currentHole] || []),
          newShot
        ];

        const newShots = {
          ...prev,
          [currentHole]: updatedHoleShots,
        };

        // Update local storage
        const roundData = JSON.parse(
          localStorage.getItem("activeRound") || "{}"
        );
        localStorage.setItem(
          "activeRound",
          JSON.stringify({
            ...roundData,
            shots: newShots,
            isRoundLoaded: true,
          })
        );

        return newShots;
      });

      setShotHistory((prev) => [...prev, currentShot]);
      if (currentShot.result === "Green") {
        setCurrentShot({
          category: "Putt",
          club: "Putter",
          type: "Putt",
          distance: "",
          result: "",
          penaltyStrokes: 0,
          proximityToHole: "",
        });
        setCurrentStep(4); // Move to result selection for putt
      } else if (
        currentShot.category === "Putt" &&
        currentShot.result !== "In the Hole"
      ) {
        setCurrentShot((prev) => ({
          ...prev,
          category: "Putt",
          club: "Putter",
          type: "Putt",
          distance: "",
          result: "",
          penaltyStrokes: 0,
          proximityToHole: "",
        }));
        setCurrentStep(4); // Move to result selection for next putt
      } else {
        resetShotEntry();
      }
    } else {
      // Optionally, you can add an error message here to inform the user
      // that they need to fill in all required fields before adding a shot
      console.log("Please fill in all required fields before adding a shot.");
    }
  };

  const editShot = (index) => {
    setEditingShot(shots[currentHole][index]);
    setEditingShotIndex(index);
    if (
      shots[currentHole][index].category === "Tee-shot" &&
      shots[currentHole][index].accuracyScore
    ) {
      setTeeShotAccuracy({
        x: (shots[currentHole][index].accuracyScore % 5) * 20,
        y: Math.floor(shots[currentHole][index].accuracyScore / 5) * 20,
      });
    }
  };

  const saveEditedShot = () => {
    const updatedShot = {
      ...editingShot,
      calculatedAccuracy: calculateAccuracy(editingShot)
    };

    setShots((prev) => ({
      ...prev,
      [currentHole]: prev[currentHole].map((shot, index) =>
        index === editingShotIndex ? updatedShot : shot
      ),
    }));

    setEditingShot(null);
    setEditingShotIndex(null);
    setTeeShotAccuracy(null);
  };

  const cancelEdit = () => {
    setEditingShot(null);
    setEditingShotIndex(null);
    setTeeShotAccuracy(null);
  };

  const removeShot = (index) => {
    setShots((prev) => {
      const updatedHoleShots = prev[currentHole].filter((_, i) => i !== index);
      const newShots = {
        ...prev,
        [currentHole]: updatedHoleShots.map((shot, i) => ({
          ...shot,
          shotNumber: i + 1,
        })),
      };

      // Update local storage
      const roundData = JSON.parse(localStorage.getItem("activeRound") || "{}");
      localStorage.setItem(
        "activeRound",
        JSON.stringify({
          ...roundData,
          shots: newShots,
          isRoundLoaded: true,
        })
      );

      return newShots;
    });

    // Set up for the next logical shot based on the last remaining shot
    setShots((prev) => {
      const remainingShots = prev[currentHole] || [];
      if (remainingShots.length > 0) {
        const lastShot = remainingShots[remainingShots.length - 1];

        // If the last shot was to the green, set up for a putt
        if (lastShot.result === "Green") {
          setCurrentShot({
            category: "Putt",
            club: "Putter",
            type: "Putt",
            distance: "",
            result: "",
            penaltyStrokes: 0,
            proximityToHole: "",
          });
          setCurrentStep(4); // Move to result selection for putt
        }
        // If the last shot was a putt but not in the hole, set up for another putt
        else if (
          lastShot.category === "Putt" &&
          lastShot.result !== "In the Hole"
        ) {
          setCurrentShot({
            category: "Putt",
            club: "Putter",
            type: "Putt",
            distance: "",
            result: "",
            penaltyStrokes: 0,
            proximityToHole: "",
          });
          setCurrentStep(4); // Move to result selection for putt
        }
        // For all other cases, reset to the beginning of shot entry
        else {
          resetShotEntry();
        }
      } else {
        // If no shots remain, reset to the beginning of shot entry
        resetShotEntry();
      }
      return prev;
    });
  };

  const calculateHoleStats = () => {
    const holeShots = shots[currentHole] || [];
    const score = holeShots.reduce((total, shot) => {
      return total + 1 + (shot.penaltyStrokes || 0);
    }, 0);
    const putts = holeShots.filter((shot) => shot.type === "Putt").length;

    const holeInOne = holeShots.length === 1 && holeShots[0].result === "In the Hole";

    const fairwayHit =
      holeInOne ||
      holeShots.some(
        (shot) =>
          shot.category === "Tee-shot" &&
          (shot.result === "Fairway" || shot.result === "Green")
      );

    const hole = selectedCourse.holes[currentHole - 1];
    const par = hole.pars[selectedTee];
    const greenInRegulation =
      holeInOne ||
      (holeShots.some(
        (shot) => shot.result === "Green" || shot.result === "In the Hole"
      ) &&
        holeShots.filter(
          (shot) => shot.result === "Green" || shot.result === "In the Hole"
        )[0].shotNumber <= par - 2);

    const strokeIndex = getStrokeIndex(hole);
    const shotsOnHole = getShotsOnHole(strokeIndex, courseHandicap);
    const netScore = Math.max(0, score - shotsOnHole);

    return { score, netScore, putts, fairwayHit, greenInRegulation, shotsOnHole };
  };

  const prevHole = () => {
    if (currentHole > 1) setCurrentHole(currentHole - 1);
  };

  const resetInputs = () => {
    setCurrentShot({
      category: "Tee-shot",
      club: "",
      distance: "",
      result: "",
      type: "",
      isPenalty: false,
      proximityToHole: "",
      accuracyScore: null,
    });
    setTeeShotAccuracy(null);
    setCurrentStep(0);
  };

  const nextHole = () => {
    if (currentHole < 18) {
      resetInputs();
      setCurrentHole(currentHole + 1);
    }
  };

  const handleSubmit = async () => {
    if (!currentUser) {
      setError("You must be logged in to save a round.");
      return;
    }

    if (Object.keys(shots).length === 0) {
      setError("No shots recorded for this round. Please enter shot data before submitting.");
      return;
    }

    if (!date || !courseId || !selectedTee) {
      setError("Please select a date, course, and tee.");
      return;
    }

    try {
      // Calculate gross score
      const grossScore = Object.values(shots).reduce((total, holeShots) => {
        return total + holeShots.length;
      }, 0);

      // Calculate net score
      const netScore = grossScore - courseHandicap;

      // Calculate score differential
      const selectedTeeData = selectedCourse.tees.find(tee => tee.color === selectedTee);
      const slope = selectedTeeData.slope;
      const rating = selectedTeeData.rating;
      const scoreDifferential = parseFloat(((113 / slope) * (grossScore - rating)).toFixed(1));

      const cleanShots = (shotData) => {
        return Object.fromEntries(
          Object.entries(shotData).map(([holeNumber, holeShots]) => [
            holeNumber,
            {
              par: selectedCourse.holes[Number(holeNumber) - 1].pars[selectedTee],
              shots: holeShots.map((shot) => ({
                ...shot,
                distance: shot.distance || null,
                proximityToHole: shot.proximityToHole || null,
                greenAngle: shot.greenAngle || null,
                greenPositionX: shot.greenPositionX || null,
                greenPositionY: shot.greenPositionY || null,
                greenDirection: shot.greenDirection
                  ? {
                    direction: shot.greenDirection.direction || null,
                    angle: shot.greenDirection.angle || null,
                  }
                  : null,
                accuracyScore: shot.accuracyScore || null,
              })),
            },
          ])
        );
      };

      // Fetch the user's name from the database
      const userDocRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);
      const userName = userDoc.data()?.name || 'Anonymous';

      const roundData = {
        userId: currentUser.uid,
        date,
        courseId,
        courseName: selectedCourse.name,
        tee: selectedTee,
        shots: cleanShots(shots),
        courseHandicap,
        playerHandicap,
        grossScore,
        netScore,
        scoreDifferential,
        slope,
        rating,
        timestamp: serverTimestamp(),
      };

      console.log("Round data to be saved:", JSON.stringify(roundData, null, 2));

      // Generate custom document ID
      const customDocId = generateCustomDocId(
        userName,
        date,
        selectedCourse.name,
        grossScore
      );

      // Use setDoc instead of addDoc to specify the document ID
      const docRef = doc(db, "rounds", customDocId);
      await setDoc(docRef, roundData);

      // Process club distances
      const clubDistances = processClubDistancesForRound(roundData.shots);
      await processClubDistances(currentUser.uid, customDocId, clubDistances);

      console.log("Round saved with ID:", customDocId);
      alert("Round data saved successfully!");

      // Clear the active round from local storage
      localStorage.removeItem("activeRound");

      navigate("/round-summary", {
        state: { roundData },
        replace: true,
      });
    } catch (err) {
      console.error("Error saving round:", err);
      setError("Failed to save round data: " + err.message);
    }
  };

  // Helper function to process club distances for the round
  const processClubDistancesForRound = (shots) => {
    let clubDistances = {};
    Object.values(shots).forEach(hole => {
      hole.shots.forEach(shot => {
        if (shot.club && shot.distance) {
          if (!clubDistances[shot.club]) {
            clubDistances[shot.club] = [];
          }
          clubDistances[shot.club].push({
            date: new Date(),
            distance: parseInt(shot.distance)
          });
        }
      });
    });
    return clubDistances;
  };

  const renderShotEntry = (shot, isEditing) => {
    const currentShotData = isEditing ? editingShot : currentShot;

    const shotTypes =
      currentShotData.category === "Putt"
        ? ["Putt"]
        : ["Full Swing", "Flighted", "Pitch", "Chip", "Bunker Shot"];

    const resultOptions =
      currentShotData.category === "Putt"
        ? ["Long", "Short", "Left", "Right", "In the Hole"]
        : ["Fairway", "Rough", "Green", "Bunker", "Water", "OB", "In the Hole"];

    return (
      <>
        <AnimatePresence>
          {/* Step 0: Shot Category */}
          <StepContainer
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            key="step-0"
          >
            <ShotCategory>
              {["Tee-shot", "Approach", "Short-game", "Recovery", "Putt"].map(
                (category) => (
                  <ShotCategoryButton
                    key={category}
                    onClick={() => handleShotChange("category", category)}
                    active={currentShot.category === category}
                  >
                    {category}
                  </ShotCategoryButton>
                )
              )}
            </ShotCategory>
          </StepContainer>

          {/* Step 1: Club Selection */}
          {currentStep >= 1 && (
            <StepContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              key="step-1"
            >
              <InputGroup>
                <label>Club:</label>
                {currentShotData.category === "Putt" ? (
                  <ClubSelection>
                    <ClubIcon selected={true}>Pt</ClubIcon>
                  </ClubSelection>
                ) : (
                  <>
                    <ClubSelection>
                      {availableClubs.map((club) => (
                        <ClubIcon
                          key={club}
                          selected={currentShotData.club === club}
                          onClick={() => handleShotChange("club", club)}
                        >
                          {club}
                        </ClubIcon>
                      ))}
                    </ClubSelection>
                    <Button
                      onClick={toggleClubSelection}
                      style={{ marginTop: "1rem" }}
                    >
                      {showAllClubs ? "Show My Clubs" : "Show All Clubs"}
                    </Button>
                  </>
                )}
              </InputGroup>
            </StepContainer>
          )}

          {/* Step 2: Shot Type */}
          {currentStep >= 2 && (
            <StepContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              key="step-2"
            >
              <InputGroup>
                <label>Shot Type:</label>
                <ShotTypeSelection>
                  {shotTypes.map((type) => (
                    <ShotTypeIcon
                      key={type}
                      selected={currentShotData.type === type}
                      onClick={() => handleShotChange("type", type)}
                    >
                      {type}
                    </ShotTypeIcon>
                  ))}
                </ShotTypeSelection>
              </InputGroup>
            </StepContainer>
          )}

          {/* Step 3: Distance */}
          {currentStep >= 3 && currentShotData.category !== "Putt" && (
            <StepContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              key="step-3"
            >
              <InputGroup>
                <label htmlFor="distance">Distance (yards)</label>
                <LargeInput
                  id="distance"
                  type="number"
                  value={currentShotData.distance}
                  onChange={(e) => handleShotChange("distance", e.target.value)}
                />
              </InputGroup>
            </StepContainer>
          )}

          {/* Step 4: Result and Penalty */}
          {currentStep >= 4 && (
            <StepContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              key="step-4"
            >
              <InputGroup>
                <label>Result:</label>
                <ResultSelection>
                  {resultOptions.map((result) => (
                    <ResultIcon
                      key={result}
                      color={resultColors[result]}
                      selected={currentShotData.result === result}
                      onClick={() => handleShotChange("result", result)}
                    >
                      {result}
                    </ResultIcon>
                  ))}
                  <ResultIcon
                    color={resultColors["Penalty"]}
                    selected={isPenaltySelected}
                    onClick={() => setIsPenaltySelected(!isPenaltySelected)}
                  >
                    <Flag size={24} />
                  </ResultIcon>
                </ResultSelection>
              </InputGroup>
              {isPenaltySelected && (
                <PenaltySelection>
                  <PenaltyButton
                    selected={currentShotData.penaltyStrokes === 1}
                    onClick={() => handlePenaltyChange(1)}
                  >
                    <Plus size={16} /> 1 Stroke
                  </PenaltyButton>
                  <PenaltyButton
                    selected={currentShotData.penaltyStrokes === 2}
                    onClick={() => handlePenaltyChange(2)}
                  >
                    <Plus size={16} /> 2 Strokes
                  </PenaltyButton>
                </PenaltySelection>
              )}
            </StepContainer>
          )}

          {/* Step 5: Tee Shot Accuracy (only for tee shots that landed on fairway or rough) */}
          {currentStep >= 5 &&
            currentShotData.category === "Tee-shot" &&
            (currentShotData.result === "Fairway" ||
              currentShotData.result === "Rough") && (
              <StepContainer
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                key="step-5"
              >
                <InputGroup>
                  <label>Tee Shot Accuracy ({currentShotData.result}):</label>
                  <TeeShotAccuracyGrid
                    onAccuracySelect={(score) =>
                      handleShotChange("accuracyScore", score)
                    }
                    shotResult={currentShotData.result}
                  />
                  {currentShotData.accuracyScore !== null && (
                    <p>Accuracy Score: {currentShotData.accuracyScore}</p>
                  )}
                </InputGroup>
              </StepContainer>
            )}

          {/* Step 5 (alternative): Proximity to Hole Input and Green Accuracy Grid (for shots that land on the green) */}
          {currentStep >= 5 &&
            currentShotData.category !== "Putt" &&
            currentShotData.result === "Green" && (
              <StepContainer
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                key="step-6"
              >
                <InputGroup>
                  <label htmlFor="proximityToHole">
                    Proximity to Hole (feet):
                  </label>
                  <LargeInput
                    id="proximityToHole"
                    type="number"
                    value={currentShotData.proximityToHole || ""}
                    onChange={(e) =>
                      handleShotChange("proximityToHole", e.target.value)
                    }
                  />
                </InputGroup>
                <InputGroup>
                  <label>Shot Location Relative to Pin:</label>
                  <GreenAccuracyGrid
                    onDirectionSelect={(data) => handleGreenDirection(data)}
                  />
                  {currentShotData.greenDirection && (
                    <p>Direction: {currentShotData.greenDirection.direction}</p>
                  )}
                </InputGroup>
              </StepContainer>
            )}

          {/* Step 5 (alternative): Proximity to Hole Input for missed putts */}
          {currentStep >= 5 &&
            currentShotData.category === "Putt" &&
            currentShotData.result !== "In the Hole" && (
              <StepContainer
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                key="step-7"
              >
                <InputGroup>
                  <label htmlFor="proximityToHole">
                    Proximity to Hole (feet):
                  </label>
                  <LargeInput
                    id="proximityToHole"
                    type="number"
                    value={currentShotData.proximityToHole || ""}
                    onChange={(e) =>
                      handleShotChange("proximityToHole", e.target.value)
                    }
                  />
                </InputGroup>
              </StepContainer>
            )}

          {/* Final Step: Add Shot or Set up next putt */}
          {currentStep === 6 && (
            <StepContainer
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              key="step-final"
            >
              {currentShotData.category === "Putt" &&
                currentShotData.result !== "In the Hole" ? (
                <InputGroup>
                  <label>Next Shot:</label>
                  <p>The next shot will be set up as a putt automatically.</p>
                  <Button
                    onClick={() => {
                      addShot();
                      // Set up the next shot as a putt
                      setCurrentShot((prevShot) => ({
                        ...prevShot,
                        category: "Putt",
                        club: "Putter",
                        type: "Putt",
                        distance: "",
                        result: "",
                        penaltyStrokes: 0,
                        proximityToHole: "",
                      }));
                      setCurrentStep(4); // Move to the result step for the next putt
                    }}
                  >
                    Add Putt and Continue
                  </Button>
                </InputGroup>
              ) : (
                <>
                  {isEditing ? (
                    <>
                      <Button onClick={saveEditedShot}>Save Changes</Button>
                      <Button onClick={cancelEdit}>Cancel</Button>
                    </>
                  ) : (
                    <Button onClick={addShot}>Add Shot</Button>
                  )}
                </>
              )}
            </StepContainer>
          )}
        </AnimatePresence>
      </>
    );
  };

  const renderHoleEntry = () => {
    if (!selectedCourse || !selectedTee) return null;

    const hole = selectedCourse.holes[currentHole - 1];
    const holeStats = calculateHoleStats();

    const strokeIndex = getStrokeIndex(hole);
    const shotsOnHole = getShotsOnHole(strokeIndex, courseHandicap);
    const shotIndicator = shotsOnHole > 0 ? `(${shotsOnHole} ${shotsOnHole === 1 ? 'Shot' : 'Shots'})` : '';


    return (
      <HoleEntry>
        <h3>Hole {hole.number}</h3>
        <p>
          Par: {hole.pars[selectedTee] || "N/A"}, Length:{" "}
          {hole.yardages[selectedTee]
            ? `${hole.yardages[selectedTee]} yards`
            : "N/A"}
          , Stroke Index: {strokeIndex} {shotIndicator}
        </p>
        <ShotEntry>
          <h4>Shot Details</h4>
          {renderShotEntry(currentShot, false)}
        </ShotEntry>
        <ShotList>
          {shots[currentHole]?.map((shot, index) => (
            <ShotItem key={index} onClick={() => toggleShotExpansion(index)}>
              <ShotSummary>
                <span>
                  Shot {shot.shotNumber} - {shot.type} ({shot.club})
                  {shot.penaltyStrokes > 0 &&
                    ` (+${shot.penaltyStrokes} penalty)`}
                </span>
                <ExpandIcon
                  initial={false}
                  animate={{ rotate: expandedShot === index ? 180 : 0 }}
                >
                  {expandedShot === index ? (
                    <ChevronUp size={24} strokeWidth={2.5} />
                  ) : (
                    <ChevronDown size={24} strokeWidth={2.5} />
                  )}
                </ExpandIcon>
              </ShotSummary>
              <AnimatePresence>
                {expandedShot === index && (
                  <ShotDetails
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <ShotDetailItem>
                      <ShotDetailLabel>Category:</ShotDetailLabel>
                      <ShotDetailValue>{shot.category}</ShotDetailValue>
                    </ShotDetailItem>
                    <ShotDetailItem>
                      <ShotDetailLabel>Distance:</ShotDetailLabel>
                      <ShotDetailValue>
                        {shot.category === "Putt" || shot.result === "Green"
                          ? `${shot.proximityToHole} ft to hole`
                          : `${shot.distance} yards`}
                      </ShotDetailValue>
                    </ShotDetailItem>
                    <ShotDetailItem>
                      <ShotDetailLabel>Result:</ShotDetailLabel>
                      <ShotDetailValue>{shot.result}</ShotDetailValue>
                    </ShotDetailItem>
                    {shot.penaltyStrokes > 0 && (
                      <ShotDetailItem>
                        <ShotDetailLabel>Penalty:</ShotDetailLabel>
                        <ShotDetailValue>
                          +{shot.penaltyStrokes} stroke(s)
                        </ShotDetailValue>
                      </ShotDetailItem>
                    )}
                    {shot.category === "Tee-shot" &&
                      shot.accuracyScore !== null && (
                        <ShotDetailItem>
                          <ShotDetailLabel>Accuracy:</ShotDetailLabel>
                          <ShotDetailValue>
                            {shot.accuracyScore}/100
                          </ShotDetailValue>
                        </ShotDetailItem>
                      )}
                    <ShotActions>
                      <ShotButton
                        onClick={(e) => {
                          e.stopPropagation();
                          editShot(index);
                        }}
                      >
                        Edit
                      </ShotButton>
                      <ShotButton
                        onClick={(e) => {
                          e.stopPropagation();
                          removeShot(index);
                        }}
                      >
                        Remove
                      </ShotButton>
                    </ShotActions>
                  </ShotDetails>
                )}
              </AnimatePresence>
            </ShotItem>
          ))}
        </ShotList>

        <StatsContainer>
          <h4 style={{ color: "white" }}>Hole Statistics</h4>
          <StatItem>
            <StatLabel>Score:</StatLabel>
            <StatValue>{holeStats.score}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Net Score:</StatLabel>
            <StatValue>{holeStats.netScore}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Putts:</StatLabel>
            <StatValue>{holeStats.putts}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Fairway Hit:</StatLabel>
            <StatValue>{holeStats.fairwayHit ? 'Yes' : 'No'}</StatValue>
          </StatItem>
          <StatItem>
            <StatLabel>Green in Regulation:</StatLabel>
            <StatValue>{holeStats.greenInRegulation ? 'Yes' : 'No'}</StatValue>
          </StatItem>
        </StatsContainer>

      </HoleEntry>

    );
  };


  return (
    <RoundEntryContainer>
      <DashboardButton />
      <Title>Enter Round Data</Title>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      {isRoundLoaded ? (
        <>
          <Button onClick={abandonActiveRound} style={{ marginBottom: "1rem" }}>
            Start New Round
          </Button>
          <CollapsibleSection>
            <CollapsibleHeader onClick={() => setIsRoundInfoExpanded(!isRoundInfoExpanded)}>
              <h3>Round Information</h3>
              {isRoundInfoExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
            </CollapsibleHeader>
            <AnimatePresence>
              {isRoundInfoExpanded && (
                <CollapsibleContent
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: "auto", opacity: 1 }}
                  exit={{ height: 0, opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <StyledForm>
                    <InputGroup>
                      <InputLabel htmlFor="date">Date</InputLabel>
                      <StyledInput
                        type="date"
                        id="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                      />
                    </InputGroup>
                    <InputGroup>
                      <InputLabel htmlFor="course">Course</InputLabel>
                      <StyledSelect
                        id="course"
                        value={courseId}
                        onChange={(e) => handleCourseSelect(e.target.value)}
                        required
                      >
                        <option value="">Select a course</option>
                        {courses.map((course) => (
                          <option key={course.id} value={course.id}>
                            {course.name}
                          </option>
                        ))}
                      </StyledSelect>
                    </InputGroup>
                    {selectedCourse && (
                      <InputGroup>
                        <InputLabel htmlFor="tee">Tee</InputLabel>
                        <StyledSelect
                          id="tee"
                          value={selectedTee}
                          onChange={(e) => setSelectedTee(e.target.value)}
                          required
                        >
                          <option value="">Select a tee</option>
                          {selectedCourse.tees.map((tee) => (
                            <option key={tee.color} value={tee.color}>
                              {tee.name} ({tee.color})
                            </option>
                          ))}
                        </StyledSelect>
                      </InputGroup>
                    )}
                  </StyledForm>
                </CollapsibleContent>
              )}
            </AnimatePresence>
            {!isRoundInfoExpanded && isRoundDataComplete && (
              <RoundSummaryContainer>
                <CourseName>{selectedCourse?.name}</CourseName>
                <RoundSummaryGrid>
                  <RoundInfoItem>
                    <RoundInfoLabel>Date</RoundInfoLabel>
                    <RoundInfoValue>{date}</RoundInfoValue>
                  </RoundInfoItem>
                  <RoundInfoItem>
                    <RoundInfoLabel>Tee</RoundInfoLabel>
                    <RoundInfoValue>{selectedTee}</RoundInfoValue>
                  </RoundInfoItem>
                  <RoundInfoItem>
                    <RoundInfoLabel>Par</RoundInfoLabel>
                    <RoundInfoValue>{calculateTotalPar(selectedCourse, selectedTee)}</RoundInfoValue>
                  </RoundInfoItem>
                  <RoundInfoItem>
                    <RoundInfoLabel>Yardage</RoundInfoLabel>
                    <RoundInfoValue>{calculateTotalYardage(selectedCourse, selectedTee)} yards</RoundInfoValue>
                  </RoundInfoItem>
                  <RoundInfoItem>
                    <RoundInfoLabel>Slope Rating</RoundInfoLabel>
                    <RoundInfoValue>
                      {selectedCourse?.tees.find(tee => tee.color === selectedTee)?.slope || 'N/A'}
                    </RoundInfoValue>
                  </RoundInfoItem>
                  <RoundInfoItem>
                    <RoundInfoLabel>Course Handicap</RoundInfoLabel>
                    <RoundInfoValue>
                      {courseHandicap}
                    </RoundInfoValue>
                  </RoundInfoItem>
                </RoundSummaryGrid>
              </RoundSummaryContainer>
            )}
          </CollapsibleSection>

          {renderHoleEntry()}
          <NavigationButtons>
            <StyledButton onClick={prevHole} disabled={currentHole === 1}>
              <ArrowLeft size={20} />
              <span>Previous</span>
            </StyledButton>
            <HoleIndicator>Hole {currentHole} of 18</HoleIndicator>
            {currentHole < 18 ? (
              <StyledButton onClick={nextHole}>
                <span>Next</span>
                <ArrowRight size={20} />
              </StyledButton>
            ) : (
              <StyledButton onClick={handleSubmit}>
                <span>Finish</span>
                <Check size={20} />
              </StyledButton>
            )}
          </NavigationButtons>
        </>
      ) : (
        <Button onClick={startNewRound}>Start New Round</Button>
      )}

      {editingShot && (
        <EditModal>
          <EditForm>
            <h4>Edit Shot</h4>
            {renderShotEntry(editingShot, true)}
          </EditForm>
        </EditModal>
      )}
    </RoundEntryContainer>
  );
};

export default RoundEntry;

const CollapsibleContent = styled(motion.div)`
  padding: 1.5rem;
  background-color: ${(props) => props.theme.bgColor || "#1a1a2e"};
`;

const StyledForm = styled.form`
  display: grid;
  gap: 1.5rem;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputLabel = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: ${(props) => props.theme.textColor || "#ffffff"};
`;



const inputBaseStyles = props => ({
  padding: '0.75rem',
  border: `1px solid ${props.theme.primaryColor || "#0f3460"}`,
  borderRadius: '4px',
  backgroundColor: props.theme.secondaryColor || "#16213e",
  color: props.theme.textColor || "#ffffff",
  fontSize: '1rem',
  '&:focus': {
    outline: 'none',
    borderColor: props.theme.accentColor || "#e94560",
    boxShadow: `0 0 0 2px ${props.theme.accentColor + "40" || "#e9456040"}`
  }
});

// Styled components
const StyledInput = styled.input(inputBaseStyles);

const StyledSelect = styled.select(props => ({
  ...inputBaseStyles(props),
  appearance: 'none',
  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 0.75rem center',
  backgroundSize: '1em'
}));




import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import { useAuth } from "../context/AuthContext";
import useFetchAllUserData from "../hooks/useFetchAllUserData";
import AdminPanel from "../components/AdminPanel";
import { Container, Title, Button, Input } from "../styles";
import PerformanceMetrics from "../components/PerformanceMetrics";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../services/firebase";

const AdminDashboard = () => {
  const { currentUser } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const { users, loading, error } = useFetchAllUserData();
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().isAdmin) {
          setIsAdmin(true);
        }
      }
    };
    checkAdminStatus();
  }, [currentUser]);

  const overallStats = useMemo(() => calculateOverallStats(users), [users]);

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        (user.email && user.email.toLowerCase().includes(searchLower)) ||
        (user.name && user.name.toLowerCase().includes(searchLower))
      );
    });
  }, [users, searchTerm]);

  if (!isAdmin) {
    return (
      <ErrorMessage>Access Denied. Admin privileges required.</ErrorMessage>
    );
  }

  if (loading) return <LoadingMessage>Loading user data...</LoadingMessage>;
  if (error) return <ErrorMessage>Error: {error}</ErrorMessage>;

  return (
    <DashboardContainer>
      <Title>Admin Dashboard</Title>
      <AdminPanel />
      <OverallStatsSection>
        <h2>Overall Platform Statistics</h2>
        <StatGrid>
          <StatCard>
            <StatTitle>Total Users</StatTitle>
            <StatValue>{users.length}</StatValue>
          </StatCard>
          <StatCard>
            <StatTitle>Total Rounds Played</StatTitle>
            <StatValue>{overallStats.totalRounds}</StatValue>
          </StatCard>
          <StatCard>
            <StatTitle>Average Rounds per User</StatTitle>
            <StatValue>{overallStats.avgRoundsPerUser.toFixed(2)}</StatValue>
          </StatCard>
          <StatCard>
            <StatTitle>Average Score</StatTitle>
            <StatValue>{overallStats.avgScore.toFixed(2)}</StatValue>
          </StatCard>
        </StatGrid>
      </OverallStatsSection>
      <UserSearchSection>
        <h2>Search Users</h2>
        <SearchInput
          type="text"
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <UserList>
          {filteredUsers.map((user) => (
            <UserListItem key={user.id} onClick={() => setSelectedUser(user)}>
              {user.name || user.email || "Unknown User"}
            </UserListItem>
          ))}
        </UserList>
      </UserSearchSection>
      {selectedUser && (
        <UserDetailSection>
          <h2>
            Detailed Metrics for{" "}
            {selectedUser.name || selectedUser.email || "Unknown User"}
          </h2>
          <UserSummary>
            <p>Email: {selectedUser.email || "N/A"}</p>
            <p>
              Rounds Played:{" "}
              {selectedUser.rounds ? selectedUser.rounds.length : 0}
            </p>
            <p>
              Average Score:{" "}
              {selectedUser.rounds
                ? calculateUserAverageScore(selectedUser.rounds).toFixed(2)
                : "N/A"}
            </p>
          </UserSummary>
          {selectedUser.rounds && selectedUser.rounds.length > 0 ? (
            <PerformanceMetrics rounds={selectedUser.rounds} />
          ) : (
            <p>No rounds data available for this user.</p>
          )}
        </UserDetailSection>
      )}
    </DashboardContainer>
  );
};

const calculateOverallStats = (users) => {
  const totalRounds = users.reduce(
    (sum, user) => sum + (user.rounds ? user.rounds.length : 0),
    0
  );
  const avgRoundsPerUser = users.length > 0 ? totalRounds / users.length : 0;
  const avgScore =
    users.reduce((sum, user) => {
      const userAvg = user.rounds ? calculateUserAverageScore(user.rounds) : 0;
      return sum + userAvg;
    }, 0) / (users.length || 1);

  return { totalRounds, avgRoundsPerUser, avgScore };
};

const calculateUserAverageScore = (rounds) => {
  if (!rounds || rounds.length === 0) return 0;
  const totalScore = rounds.reduce((sum, round) => {
    return (
      sum +
      Object.values(round.shots).reduce(
        (holeSum, hole) => holeSum + hole.shots.length,
        0
      )
    );
  }, 0);
  return totalScore / rounds.length;
};

const DashboardContainer = styled(Container)`
  max-width: 1200px;
`;

const LoadingMessage = styled.div`
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: red;
  font-size: 18px;
  margin-top: 20px;
`;

const OverallStatsSection = styled.section`
  margin-bottom: 40px;
`;

const StatGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
`;

const StatCard = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
`;

const StatTitle = styled.h4`
  margin: 0;
  color: #333;
`;

const StatValue = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0 0;
  color: #007bff;
`;

const UserSearchSection = styled.section`
  margin-bottom: 40px;
`;

const SearchInput = styled(Input)`
  width: 100%;
  margin-bottom: 20px;
`;

const UserList = styled.ul`
  list-style-type: none;
  padding: 0;
  max-height: 300px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const UserListItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
`;

const UserDetailSection = styled.section`
  margin-top: 40px;
`;

const UserSummary = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export default AdminDashboard;

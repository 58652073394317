import React, { useState, useEffect } from "react";
import { ResponsivePie } from "@nivo/pie";
import styled from "@emotion/styled";

const theme = {
  bgColor: "#1a1a2e",
  textColor: "#ffffff",
  primaryColor: "#0f3460",
  secondaryColor: "#f4d37e",
  accentColor: "#e94560",
  greenColor: "#34D399",
};

const ChartWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChartContent = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 10px;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? 1 : 0.6)};
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

const LegendColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${(props) => props.color};
`;

const LegendLabel = styled.span`
  font-size: 14px;
  color: #333;
`;

const ImportanceOfScoringCircles = ({ importanceOfScoring }) => {
  const [activeSegment, setActiveSegment] = useState(null);

  const data = [
    {
      id: "Driving",
      label: "Driving",
      value: importanceOfScoring.driving,
      color: theme.primaryColor,
    },
    {
      id: "Approach",
      label: "Approach",
      value: importanceOfScoring.approach,
      color: theme.secondaryColor,
    },
    {
      id: "Short Game",
      label: "Short Game",
      value: importanceOfScoring.shortGame,
      color: theme.accentColor,
    },
    {
      id: "Putting",
      label: "Putting",
      value: importanceOfScoring.putting,
      color: theme.greenColor,
    },
  ];

  useEffect(() => {
    // Find the segment with the largest value
    const largestSegment = data.reduce((max, item) =>
      item.value > max.value ? item : max
      , data[0]);

    // Set it as the active segment
    setActiveSegment(largestSegment.id);
  }, [importanceOfScoring]);



  const handleSegmentClick = (node) => {
    setActiveSegment(node.id === activeSegment ? null : node.id);
  };

  const handleLegendClick = (id) => {
    setActiveSegment(id === activeSegment ? null : id);
  };

  return (
    <ChartWrapper>
      <ChartContent>
        <ResponsivePie
          data={data}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          innerRadius={0.6}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
          arcLinkLabelsSkipAngle={360}
          arcLabelsSkipAngle={360}
          colors={{ datum: "data.color" }}
          tooltip={() => null}
          onClick={handleSegmentClick}
          activeId={activeSegment}
        />
        {activeSegment && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: theme.primaryColor,
              fontSize: "24px",
              fontWeight: "bold",
              pointerEvents: "none",
            }}
          >
            {data.find((item) => item.id === activeSegment).value.toFixed(0)}%
          </div>
        )}
      </ChartContent>
      <Legend>
        {data.map((item) => (
          <LegendItem
            key={item.id}
            isActive={activeSegment === item.id}
            onClick={() => handleLegendClick(item.id)}
          >
            <LegendColor color={item.color} />
            <LegendLabel>{item.label}</LegendLabel>
          </LegendItem>
        ))}
      </Legend>
    </ChartWrapper>
  );
};

export default ImportanceOfScoringCircles;

import React from "react";
import ScoreCardUpload from "../components/ScorecardUpload"; // Adjust the import path as needed

const ScoreCardUploadPage = () => {
  return (
    <div className="scorecard-upload-page">
      <h1>Upload A Course Scorecard</h1>
      <p>Upload a photo of a scorecard to add that course to the database.</p>
      <ScoreCardUpload />
    </div>
  );
};

export default ScoreCardUploadPage;

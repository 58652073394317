import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { Bar, Pie, Scatter, Line } from "react-chartjs-2";
import { ChartSection, ChartTitle, ChartContainer, ChartWrapper, StatGrid, StatCard, StatTitle, StatValue } from "../styles";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const StatsContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const StatsHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-bottom: 20px;
`;





const DrivingStats = ({ rounds }) => {
    const processedData = useMemo(() => processRoundData(rounds), [rounds]);

    console.log("Rendering DrivingStats with processed data:", processedData);

    if (!rounds || rounds.length === 0) {
        return <div>No Driving data recorded, play your first round to gain insights</div>;
    }

    if (!processedData) {
        return <div>Error processing driving statistics.</div>;
    }

    const {
        clubPerformance,
        fairwaysHit,
        drivingDistance,
        drivingAccuracy,
        dispersionPattern,
        drivingTrend,
        par3Accuracy,
    } = processedData;

    // Club Performance Chart
    const clubPerformanceData = {
        labels: Object.keys(clubPerformance),
        datasets: [
            {
                label: "Average Distance (yards)",
                data: Object.values(clubPerformance).map((club) => club.avgDistance),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
            {
                label: "Fairway Hit Percentage",
                data: Object.values(clubPerformance).map(
                    (club) => club.fairwayHitPercentage
                ),
                backgroundColor: "rgba(255, 99, 132, 0.6)",
            },
        ],
    };

    const clubPerformanceOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Club Performance for Tee Shots",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Average Distance (yards) / Percentage",
                },
            },
        },
    };

    // Fairways Hit Pie Chart
    const fairwaysHitData = {
        labels: ["Fairway Hit", "Fairway Missed"],
        datasets: [
            {
                data: [fairwaysHit.hit, fairwaysHit.total - fairwaysHit.hit],
                backgroundColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)"],
            },
        ],
    };

    const fairwaysHitOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Fairways Hit Percentage",
            },
        },
    };

    // Driving Distance Histogram
    const drivingDistanceData = {
        labels: drivingDistance.bins.map((bin) => `${bin.start}-${bin.end}`),
        datasets: [
            {
                label: "Frequency",
                data: drivingDistance.bins.map((bin) => bin.count),
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const drivingDistanceOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Driving Distance Distribution",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Frequency",
                },
            },
            x: {
                title: {
                    display: true,
                    text: "Distance (yards)",
                },
            },
        },
    };

    // Driving Accuracy Scatter Plot
    const drivingAccuracyData = {
        datasets: [
            {
                label: "Tee Shots",
                data: drivingAccuracy.map((shot) => ({
                    x: shot.distance,
                    y: shot.accuracy,
                })),
                backgroundColor: "rgba(255, 99, 132, 0.6)",
            },
        ],
    };

    const drivingAccuracyOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Driving Accuracy vs. Distance",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Distance (yards)",
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Accuracy Score",
                },
                min: 0,
                max: 25,
            },
        },
    };

    // Dispersion Pattern Chart
    const dispersionPatternData = {
        datasets: [
            {
                label: "Tee Shots",
                data: dispersionPattern,
                backgroundColor: "rgba(75, 192, 192, 0.6)",
            },
        ],
    };

    const dispersionPatternOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Tee Shot Dispersion Pattern",
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: "Lateral Distance from Center (yards)",
                },
                min: -50,
                max: 50,
            },
            y: {
                title: {
                    display: true,
                    text: "Distance from Tee (yards)",
                },
                min: 0,
            },
        },
    };

    // Driving Trend Line Chart
    const drivingTrendData = {
        labels: drivingTrend.map((data) => data.date),
        datasets: [
            {
                label: "Average Driving Distance",
                data: drivingTrend.map((data) => data.avgDistance),
                borderColor: "rgba(75, 192, 192, 1)",
                fill: false,
            },
            {
                label: "Fairway Hit Percentage",
                data: drivingTrend.map((data) => data.fairwayHitPercentage),
                borderColor: "rgba(255, 99, 132, 1)",
                fill: false,
            },
        ],
    };

    const drivingTrendOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Driving Performance Trend",
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: "Distance (yards) / Percentage",
                },
            },
            x: {
                title: {
                    display: true,
                    text: "Date",
                },
            },
        },
    };

    // Par 3 Accuracy Pie Chart
    const par3AccuracyData = {
        labels: ["Greens Hit", "Greens Missed"],
        datasets: [
            {
                data: [par3Accuracy.hit, par3Accuracy.total - par3Accuracy.hit],
                backgroundColor: ["rgba(75, 192, 192, 0.6)", "rgba(255, 99, 132, 0.6)"],
            },
        ],
    };

    const par3AccuracyOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: true,
                text: "Par 3 Green Hit Percentage",
            },
        },
    };

    return (
        <StatsContainer>
            <StatsHeader>Driving Statistics</StatsHeader>

            <ChartSection>
                <ChartTitle>Club Performance for Tee Shots</ChartTitle>
                <ChartContainer>
                    <Bar data={clubPerformanceData} options={clubPerformanceOptions} />
                </ChartContainer>
            </ChartSection>

            <ChartSection>
                <ChartTitle>Fairways Hit Percentage</ChartTitle>
                <ChartContainer>
                    <Pie data={fairwaysHitData} options={fairwaysHitOptions} />
                </ChartContainer>
            </ChartSection>

            <ChartSection>
                <ChartTitle>Driving Distance Distribution</ChartTitle>
                <ChartContainer>
                    <Bar data={drivingDistanceData} options={drivingDistanceOptions} />
                </ChartContainer>
            </ChartSection>

            <ChartSection>
                <ChartTitle>Driving Accuracy vs. Distance</ChartTitle>
                <ChartContainer>
                    <Scatter
                        data={drivingAccuracyData}
                        options={drivingAccuracyOptions}
                    />
                </ChartContainer>
            </ChartSection>

            <ChartSection>
                <ChartTitle>Tee Shot Dispersion Pattern</ChartTitle>
                <ChartContainer>
                    <Scatter
                        data={dispersionPatternData}
                        options={dispersionPatternOptions}
                    />
                </ChartContainer>
            </ChartSection>

            <ChartSection>
                <ChartTitle>Driving Performance Trend</ChartTitle>
                <ChartContainer>
                    <Line data={drivingTrendData} options={drivingTrendOptions} />
                </ChartContainer>
            </ChartSection>

            <ChartSection>
                <ChartTitle>Par 3 Accuracy</ChartTitle>
                <ChartContainer>
                    <Pie data={par3AccuracyData} options={par3AccuracyOptions} />
                </ChartContainer>
            </ChartSection>

            <StatGrid>
                <StatCard>
                    <StatTitle>Average Driving Distance</StatTitle>
                    <StatValue>{drivingDistance.average.toFixed(1)} yards</StatValue>
                </StatCard>
                <StatCard>
                    <StatTitle>Fairway Hit Percentage</StatTitle>
                    <StatValue>
                        {((fairwaysHit.hit / fairwaysHit.total) * 100).toFixed(1)}%
                    </StatValue>
                </StatCard>
                <StatCard>
                    <StatTitle>Longest Drive</StatTitle>
                    <StatValue>{drivingDistance.longest} yards</StatValue>
                </StatCard>
                <StatCard>
                    <StatTitle>Average Driving Accuracy Score</StatTitle>
                    <StatValue>
                        {(
                            drivingAccuracy.reduce((sum, shot) => sum + shot.accuracy, 0) /
                            drivingAccuracy.length
                        ).toFixed(1)}
                    </StatValue>
                </StatCard>
                <StatCard>
                    <StatTitle>Par 3 Green Hit Percentage</StatTitle>
                    <StatValue>
                        {((par3Accuracy.hit / par3Accuracy.total) * 100).toFixed(1)}%
                    </StatValue>
                </StatCard>
            </StatGrid>
        </StatsContainer>
    );
};

const processRoundData = (rounds) => {
    console.log("Processing rounds in DrivingStats:", rounds);

    if (!rounds || !Array.isArray(rounds) || rounds.length === 0) {
        console.log("No valid rounds data in DrivingStats");
        return null;
    }

    const clubStats = {};
    const distances = [];
    const accuracyShots = [];
    const dispersionShots = [];
    const trendData = {};
    let fairwaysHit = 0;
    let fairwaysTotal = 0;
    let par3Hit = 0;
    let par3Total = 0;
    let longest = 0;

    rounds.forEach((round, index) => {
        console.log(`Processing Round ${index + 1}:`, round);
        if (round && round.shots) {
            const roundDate = new Date(round.date);
            const dateKey = roundDate.toISOString().split("T")[0];
            if (!trendData[dateKey]) {
                trendData[dateKey] = {
                    totalDistance: 0,
                    fairwaysHit: 0,
                    totalShots: 0,
                };
            }

            Object.entries(round.shots).forEach(([holeNumber, holeData]) => {
                const par = holeData.par;
                const holeShots = holeData.shots;

                const teeShot = holeShots.find((shot) => shot.category === "Tee-shot");
                if (teeShot) {
                    const distance = parseFloat(teeShot.distance) || 0;

                    // Club performance
                    if (!clubStats[teeShot.club]) {
                        clubStats[teeShot.club] = {
                            totalDistance: 0,
                            fairwayHits: 0,
                            totalShots: 0,
                            par3Shots: 0,
                            par3GreenHits: 0,
                        };
                    }
                    clubStats[teeShot.club].totalDistance += distance;
                    clubStats[teeShot.club].totalShots++;

                    // Driving distance (exclude par 3s)
                    if (distance > 0 && par !== 3) {
                        distances.push(distance);
                        if (distance > longest) longest = distance;
                    }

                    if (par === 3) {
                        clubStats[teeShot.club].par3Shots++;
                        par3Total++;
                        if (
                            teeShot.result === "Green" ||
                            teeShot.result === "In the Hole"
                        ) {
                            clubStats[teeShot.club].par3GreenHits++;
                            par3Hit++;
                        }
                    } else {
                        fairwaysTotal++;
                        if (
                            teeShot.result === "Fairway" ||
                            teeShot.result === "Green" ||
                            teeShot.result === "In the Hole"
                        ) {
                            clubStats[teeShot.club].fairwayHits++;
                            fairwaysHit++;
                        }

                        // Trend data (only for non-par 3 holes)
                        trendData[dateKey].totalDistance += distance;
                        trendData[dateKey].totalShots++;
                        if (
                            teeShot.result === "Fairway" ||
                            teeShot.result === "Green" ||
                            teeShot.result === "In the Hole"
                        ) {
                            trendData[dateKey].fairwaysHit++;
                        }
                    }

                    // Driving accuracy (exclude par 3s)
                    if (teeShot.accuracyScore !== null && par !== 3) {
                        accuracyShots.push({ distance, accuracy: teeShot.accuracyScore });
                    }

                    // We don't have lateral distance data, so we can't populate dispersionShots
                }
            });
        } else {
            console.log(`Skipping round ${index + 1} due to missing data`);
        }
    });

    // Process club performance
    Object.keys(clubStats).forEach((club) => {
        const stats = clubStats[club];
        stats.avgDistance =
            stats.totalShots > 0 ? stats.totalDistance / stats.totalShots : 0;
        stats.fairwayHitPercentage =
            stats.totalShots - stats.par3Shots > 0
                ? (stats.fairwayHits / (stats.totalShots - stats.par3Shots)) * 100
                : 0;
        stats.par3GreenHitPercentage =
            stats.par3Shots > 0 ? (stats.par3GreenHits / stats.par3Shots) * 100 : 0;
    });

    // Process driving distance
    const average =
        distances.length > 0
            ? distances.reduce((sum, distance) => sum + distance, 0) /
            distances.length
            : 0;
    const binSize = 20;
    const bins = Array.from({ length: Math.ceil(longest / binSize) }, (_, i) => ({
        start: i * binSize,
        end: (i + 1) * binSize,
        count: 0,
    }));
    distances.forEach((distance) => {
        const binIndex = Math.floor(distance / binSize);
        if (bins[binIndex]) bins[binIndex].count++;
    });

    // Process driving trend
    const drivingTrend = Object.entries(trendData)
        .map(([date, data]) => ({
            date,
            avgDistance:
                data.totalShots > 0 ? data.totalDistance / data.totalShots : 0,
            fairwayHitPercentage:
                data.totalShots > 0 ? (data.fairwaysHit / data.totalShots) * 100 : 0,
        }))
        .sort((a, b) => new Date(a.date) - new Date(b.date));

    const processedData = {
        clubPerformance: clubStats,
        fairwaysHit: { hit: fairwaysHit, total: fairwaysTotal },
        drivingDistance: { average, longest, bins },
        drivingAccuracy: accuracyShots,
        dispersionPattern: dispersionShots,
        drivingTrend,
        par3Accuracy: { hit: par3Hit, total: par3Total },
    };

    console.log("Final processed driving data:", processedData);

    return processedData;
};

export default DrivingStats;

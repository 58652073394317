import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { currentUser, userClaims, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  // If adminOnly is true, we need to wait for userClaims to be loaded
  if (adminOnly) {
    if (userClaims === undefined) {
      // Claims haven't loaded yet, show loading
      return <div>Verifying access...</div>;
    }
    if (!userClaims.admin) {
      // User is not an admin, redirect to dashboard or another appropriate page
      return <Navigate to="/dashboard" replace />;
    }
  }

  return children;
};

export default ProtectedRoute;